import React, { Component, Fragment } from 'react';

import { Typography, Checkbox, Paper, Button, IconButton, Tooltip } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';

import FieldCalculationTooltip, { fieldCalculationHelp } from '../calculations/FieldCalculationTooltip';

import { ManageTextField, ManageDateField, ManageDecimalField, ColorPicker } from 'react-frontend-utils'
import { ThemeColors } from '../Theme'

/**
 * FieldEditor renders the fixed editor to the side of the Fields while editing a Template. Must pass the following props:
 * 
 * fieldToEdit - the initial Field that should be edited.  When updating the field to edit while this component is still mounted, you must also call fieldPropertyEdit() using a ref to the component, unless you unmount this component first
 * fieldWasEdited - callback when the field has been modified
 * onClose - callback to close this component
 */
export class FieldEditor extends Component {

    _nameField = React.createRef();
    _labelField = React.createRef();
    _instructionsField = React.createRef();
    _hiddenLogicField = React.createRef();
    _maxCharsField = React.createRef();
    _numLinesField = React.createRef();
    _selectField = React.createRef();
    _minDateField = React.createRef();
    _maxDateField = React.createRef();
    _aspectRatioField = React.createRef();
    _decimalPlacesField = React.createRef();
    _minValueField = React.createRef();
    _maxValueField = React.createRef();
    _calcResultField = React.createRef();
    _numericPrecisionField = React.createRef();
    _minClonesField = React.createRef();
    _maxClonesField = React.createRef();

    constructor(props) {
        super(props);
        this.state = {fieldToEdit: props.fieldToEdit};    //The field whose properties are being edited
    }


           
    //Gets the Actively editing field for field change callback (since the Managed field calls aren't re-rendered necessarily, we can't pass in the edit
    //field directly, since it won't be updated on field change, but rather use a function to retrieve whichever one is currently being edited.
    _getCurrentEditField = () => {
        return this.state.fieldToEdit;
    }

    //When we update a property, callback to the parent to update the field rendered
    formUpdate() {
        this.props.fieldWasEdited();
    }
    

    //Edit the properties of a field - called when a new field is selected for editing
    fieldPropertyEdit = (field) => {
        console.log("Editing ", field);
        
        //Set the Managed Fields (since they don't automatically update from props when changed, we need to set explicitly with a ref)
        if (this._nameField.current)
            this._nameField.current.set(field.name);
        if (this._labelField.current)
            this._labelField.current.set(field.label);
        if (this._instructionsField.current)
            this._instructionsField.current.set(field.instructions);
        if (this._hiddenLogicField.current)
            this._hiddenLogicField.current.set(field.hiddenLogic);
        

        if (field.type === "TextField") {
            if (this._maxCharsField.current)
                this._maxCharsField.current.set(field.maxChars);
            if (this._numLinesField.current)
                this._numLinesField.current.set(field.numLines ? field.numLines : 1);
        }
        
        if (field.type === "SelectField" && this._selectField.current)
            this._selectField.current.set(arrayToMultilineString(field.selectableItems));
        
        if (field.type === "DateField") {
            if (this._minDateField.current)
                this._minDateField.current.set(field.minDateval === null ? "None" : field.minDateval);
            if (this._maxDateField.current)
                this._maxDateField.current.set(field.maxDateval === null ? "None" : field.maxDateval);
        }
        
        if (field.type === "ImageField" && this._aspectRatioField.current)
            this._aspectRatioField.current.set(field.aspectRatio);
        
        if (field.type === "NumericField") {
            if (this._minValueField.current)
                this._minValueField.current.set(field.minValue);
            if (this._maxValueField.current)
                this._maxValueField.current.set(field.maxValue);
            if (this._decimalPlacesField.current)
                this._decimalPlacesField.current.set(field.decimalPlaces);
        }
        
        if (field.type === "CloningFieldGroup") {
            if (this._minClonesField.current)
                this._minClonesField.current.set(field.minChildren);
            if (this._maxClonesField.current)
                this._maxClonesField.current.set(field.maxChildren);
        }
        
        if (field.type === "CalcResultField" && this._calcResultField.current) {
            this._calcResultField.current.set(field.calculationName);
            this._numericPrecisionField.current.set(field.numericPrecision);
        }
     
        
        this.setState({fieldToEdit: field});        
    }


    //Called to render a Checkbox to control a boolean property in the field. This is a controlled component, so the field value directly controls
    //the checkbox, however since it is not part of our state we must force update whenever it changes
    _booleanFieldPropertyRender = (label, isSelected, setProperty, tooltip = "") => {
    
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: -5}}>
                    <Tooltip title={tooltip}>
                        <Checkbox size='small' checked={isSelected} color='primary' 
                                onChange={(event) => { setProperty(event.target.checked);
                                                        this.formUpdate();}
                                        }/>                                  
                    </Tooltip>                       
                    <Typography variant='body2' align='left'>{label}</Typography> 
                </div>;
    }


    //Called to render a ManageTextField to control a text property in the field. This is an uncontrolled component, so the field value is set initially
    //then we use autoAccept and update the value in the field when changed. Since it is not part of our state we must force update whenever it changes.
    //Since this is uncontrolled, if a different field is selected for editing, the initialValue won't help us so we use the ref to set it directly in the 
    //_fieldPropertyEdit method
    _textFieldPropertyRender = (ref, label, text, setText, multiline = 0, tooltip) => {
        return <ManageTextField label={label}
                                json={label} 
                                ref={ref}
                                multiline={multiline}
                                autoAccept="true"
                                tooltip={tooltip}
                                style={{marginTop: 15}}
                                initialValue={text}
                                onFieldChange={(fieldName, userValue) => {if (!userValue) 
                                                                              setText(null);
                                                                          else
                                                                              setText(userValue);
                                                                          this.formUpdate();}}                                                                    
       />
                                            
    }
    
    //Called to render a ManageDecimalField to control a number property in the field. This is an uncontrolled component, so the field value is set initially
    //then we use autoAccept and update the value in the field when changed. Since it is not part of our state we must force update whenever it changes.
    //Since this is uncontrolled, if a different field is selected for editing, the initialValue won't help us so we use the ref to set it directly in the 
    //_fieldPropertyEdit method
    _numberFieldPropertyRender = (ref, label, val, setVal, minVal, maxVal, decimalPlaces = 0, hasNone = false, tooltip) => {
        return  <div style={{maxWidth: 200}}>
                    <ManageDecimalField label={label}
                                        json={label} 
                                        ref={ref}
                                        minValue={minVal}
                                        maxValue={maxVal}
                                        hasNegative={true}
                                        hasInfinity={hasNone}
                                        infinityText="None"
                                        tooltip={tooltip}
                                        decimalPlaces={decimalPlaces}
                                        autoAccept="true"
                                        style={{marginTop: 15}}
                                        initialValue={val}
                                        hideButtons={true}
                                        onFieldChange={(fieldName, userValue) => {if (!userValue) 
                                                                                  setVal(null);
                                                                              else
                                                                                  setVal(userValue);
                                                                              this.formUpdate();}} 
                   />
                </div>
                                
    }
    
    
    //Called to render a ManageDateField to control a date property in the field. This is an uncontrolled component, so the field value is set initially
    //then we use autoAccept and update the value in the field when changed. Since it is not part of our state we must force update whenever it changes.
    //Since this is uncontrolled, if a different field is selected for editing, the initialValue won't help us so we use the ref to set it directly in the 
    //_fieldPropertyEdit method
    _dateFieldPropertyRender = (ref, label, val, setVal, tooltip) => {
        return  <ManageDateField label={label}
                                    json={label}  
                                    ref={ref}
                                    style={{marginTop: 10}}
                                    initialValue={val === null ? "None" : val}
                                    hasNever={true}
                                    neverText="None"
                                    tooltip={tooltip}
                                    autoAccept={true}
                                    calendarColor={ThemeColors.calendarColor}
                                    onParseError={(label, errorText) => {
                                        this.showConfirmAlert("Error for Field \"" + label + "\"", errorText, 'red');                          
                                    }}
                                    onFieldChange={(fieldName, newValue) => {if (!newValue) 
                                                                              setVal(null);
                                                                            else
                                                                              setVal(newValue);
                                                                          this.formUpdate();}}                                                                    
       />
                                            
    }
    

    render() {

        const editField = this.state.fieldToEdit; 

        return(
            <Paper style={{position: 'fixed', width: 'calc(30% - 40px)', marginLeft: 5, marginTop: -30, marginBottom: 20, padding: 10}}>

                <div style={{display: 'flex', alignItems: 'left'}}>
                    <Typography style={{marginLeft: 5, color: ThemeColors.appBarBackground, textTransform: 'uppercase', fontSize: '11pt'}} variant="h6">{editField.type.replace("Field", "") + " Field"}</Typography> 
                    <IconButton onClick={this.props.onClose} style={{marginTop: -10, marginRight: -10, marginLeft: 'auto'}}>
                        <CancelIcon style={{fontSize: 18}}/>
                    </IconButton>
                </div>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography style={{marginLeft: 5, color: 'gray', fontSize: '11pt'}} variant="h6">{"Editing \"" + (editField && editField.name ? editField.name : "") + "\""}</Typography> 
                    {editField.isPPSyncField ?
                        <Typography variant='body2' style={{marginLeft: 10, fontSize: 10, paddingTop: -4, paddingBottom: -10, paddingRight: 4, paddingLeft: 2, 
                                color: 'white', fontStyle: 'italic', fontWeight: 600, 
                                backgroundColor: ThemeColors.appBarBackground, borderRadius: '4px'}}>PP</Typography>
                        : null 
                    }
                </div>

                <div>
                    {this._textFieldPropertyRender(this._nameField, "Field Name", editField.name, (text) => {this._getCurrentEditField().name = text;})}
                    {!hasInstructions(editField.type) ? null : this._textFieldPropertyRender(this._instructionsField, "Instructions", editField.instructions, (text) => {this._getCurrentEditField().instructions = text;})}
                    {!hasLabel(editField.type) ? null : this._textFieldPropertyRender(this._labelField, "Field Label", editField.label, (text) => {this._getCurrentEditField().label = text;})}
                    <div style={{marginTop: 5}}/>
                    {editField.type === "FieldGroup" || editField.type === "PagedFieldGroup" ||editField.type === "CloningFieldGroup" ? null :
                        <Fragment>
                            {readOnly(editField.type, false) ? null : this._booleanFieldPropertyRender("Required for Patron", editField.required, (selected) => {this._getCurrentEditField().required = selected;}, "When selected, patron must enter data in this field (unless the field is hidden)")}
                            {readOnly(editField.type, true) ? null : this._booleanFieldPropertyRender("Modifiable by Processor", editField.modifiable, (selected) => {this._getCurrentEditField().modifiable = selected;}, "When selected, a user who claims the Application can edit the data")}
                            {!webhookAllowed(editField.type) ? null : this._booleanFieldPropertyRender("Included in Webhooks", editField.isWebhookField, (selected) => {this._getCurrentEditField().isWebhookField = selected;}, "When selected, and a webhook is set, the field data is sent as JSON in the webhook as {fieldName: fieldData}")}
                            {!mergeAllowed(editField.type) ? null : this._booleanFieldPropertyRender("Added to Document Merge", editField.merged, (selected) => {this._getCurrentEditField().merged = selected;}, "When selected, and document merging is enabled, the field data is included in the document (ordered the same as in the template)")}
                        </Fragment>
                    }     

                    {this._booleanFieldPropertyRender("Initially Hidden", editField.initiallyHidden, (initiallyHidden) => {this._getCurrentEditField().initiallyHidden = initiallyHidden;}, "When selected, the field is hidden from the patron and can only become visible if the Hide/Unhide Calculation evalutes to true")}
                    
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {this._textFieldPropertyRender(this._hiddenLogicField, "Hide/Unhide Calculation", editField.hiddenLogic, (val) => {this._getCurrentEditField().hiddenLogic = val;}, 2, "Calculation must evaluate to a non-zero value (true) or zero. When true, the field is hidden or un-hidden depending on whether the field is initially hidden")}
                        <FieldCalculationTooltip title={fieldCalculationHelp()} interactive={true}>
                            <Typography variant="body2" style={{marginLeft: 8, fontSize: 14, marginTop: 15, fontWeight: 'bold', color: 'blue'}}>ⓘ</Typography>
                        </FieldCalculationTooltip>
                    </div>

                    {editField.type === "PagedFieldGroup" ? 
                        <Fragment>
                            {this._booleanFieldPropertyRender("Prevent Backwards Navigation", editField.pageBackDisabled, (selected) => {this._getCurrentEditField().pageBackDisabled = selected;}, "When enabled, the user can only page forward")}
                            {this._booleanFieldPropertyRender("Allow Random Page Access", editField.allowRandomPageAccess, (selected) => {this._getCurrentEditField().allowRandomPageAccess = selected;}, "When enabled, the user can jump directly to a page")}
                        </Fragment>
                        : null
                    }

                    {editField.type === "CloningFieldGroup" ? 
                        <Fragment>
                            {this._numberFieldPropertyRender(this._minClonesField, "Minimum clones", editField.minChildren, (val) => {this._getCurrentEditField().minChildren = val;}, 0, null, 0, false)}
                            {this._numberFieldPropertyRender(this._maxClonesField, "Maximum clones", editField.maxChildren, (val) => {this._getCurrentEditField().maxChildren = val;}, 0, null, 0, true)}
                        </Fragment>
                        : null
                    }           

                    {editField.type === "TextField" ? 
                        <Fragment>
                            {this._numberFieldPropertyRender(this._maxCharsField, "Maximum characters", editField.maxChars, (val) => {this._getCurrentEditField().maxChars = val;}, 1)}
                            {this._numberFieldPropertyRender(this._numLinesField, "Number of lines", editField.numLines, (val) => {this._getCurrentEditField().numLines = val;}, 1)}
                        </Fragment>
                        : null
                    }
                    {editField.type === "AddressField" ? 
                        <Fragment>
                            {this._numberFieldPropertyRender(this._maxCharsField, "Maximum characters", editField.maxChars, (val) => {this._getCurrentEditField().maxChars = val;}, 1)}
                            {this._booleanFieldPropertyRender("Split Address Line", editField.splitAddressLine, (selected) => {this._getCurrentEditField().splitAddressLine = selected;})}
                            {this._booleanFieldPropertyRender("Include Country", editField.includeCountry, (selected) => {this._getCurrentEditField().includeCountry = selected;})}            
                        </Fragment>
                        : null
                    }
                    {editField.type === "NumericField" ? 
                        <Fragment>
                            {this._numberFieldPropertyRender(this._minValueField, "Min value", editField.minValue, (val) => {this._getCurrentEditField().minValue = val;}, null, null, 2, true)}
                            {this._numberFieldPropertyRender(this._maxValueField, "Max value", editField.maxValue, (val) => {this._getCurrentEditField().maxValue = val;}, null, null, 2, true)}
                            {this._numberFieldPropertyRender(this._decimalPlacesField, "Decimal Places", editField.decimalPlaces, (val) => {this._getCurrentEditField().decimalPlaces = val;})}
                            {this._booleanFieldPropertyRender("Hide Buttons", editField.hideButtons, (selected) => {this._getCurrentEditField().hideButtons = selected;})}            
                            {this._booleanFieldPropertyRender("Add Currency Label", editField.isCurrencyValue, (selected) => {this._getCurrentEditField().isCurrencyValue = selected;})}            
                        </Fragment>
                        : null
                    }


                    {editField.type === "SelectField" ? 
                        <Fragment>
                            {this._booleanFieldPropertyRender("Display as List", editField.displayAsList, (selected) => {this._getCurrentEditField().displayAsList = selected;})}            
                            {this._textFieldPropertyRender(this._selectField, "Select Choices", arrayToMultilineString(editField.selectableItems), (multilineString) => {this._getCurrentEditField().selectableItems = multilineStringToArray(multilineString);}, 6)}
                            <Button variant='outlined' style={{display: 'flex', justifyContent: 'center', margin: 'auto', marginTop: 10}} size='small' onClick={() => {this._getCurrentEditField().patronData = null; this.formUpdate();}}>Reset Default Selection</Button>
                        </Fragment>
                        : null
                    }

                    {editField.type === "DateField" ? 
                        <Fragment>
                            {this._dateFieldPropertyRender(this._minDateField, "Minimum Date", editField.minDate, (val) => {this._getCurrentEditField().minDate = val;})}
                            {this._dateFieldPropertyRender(this._maxDateField, "Maximum Date", editField.maxDate, (val) => {this._getCurrentEditField().maxDate = val;})}
                            {this._booleanFieldPropertyRender("Must be in Past", editField.mustBeInPast, (selected) => {this._getCurrentEditField().mustBeInPast = selected;})}
                            {this._booleanFieldPropertyRender("Must be in Future", editField.mustBeInFuture, (selected) => {this._getCurrentEditField().mustBeInFuture = selected;})}

                        </Fragment>
                        : null
                    }
                    {editField.type === "ImageField" ? 
                        this._numberFieldPropertyRender(this._aspectRatioField, "Aspect Ratio", editField.aspectRatio, (val) => {this._getCurrentEditField().aspectRatio = val;}, 0.2, 5.0, 2)
                        : null
                    }
                    {editField.type === "CalcResultField" ? 
                        <Fragment>
                            {this._textFieldPropertyRender(this._calcResultField, "Calculation Result Name", editField.calculationName, (text) => {this._getCurrentEditField().calculationName = text;}, 0, "The Calculation result to display, choose a named Calculation from the Calculations tab")}
                            {this._numberFieldPropertyRender(this._numericPrecisionField, "Decimal Places", editField.numericPrecision, (val) => {this._getCurrentEditField().numericPrecision = val;}, 0, 10, 0, false, "If the calculation results in a Number, this specifies the decimal precision. Ignored for String results.")}
                        </Fragment>
                        : null
                    }
                    {editField.type === "RichTextHtmlField" ?
                        <div style={{display: 'flex', marginTop: 15, marginLeft: 5, gap: 10, alignItems: 'center'}}>
                            <Typography variant='body2' align='left'>Background Color</Typography>                        
                            <ColorPicker initialColor={editField.backgroundColor ? editField.backgroundColor : 'white'} 
                                        disableAlpha={true}
                                        onChange={(color)=> {
                                                    this._getCurrentEditField().backgroundColor = color.hex;
                                                    this.formUpdate();
                                                    }}
                            />
                        </div>
                        : null
                    }

                </div>                                
            </Paper>
        );

        
    }



}



//Array of strings to a single string of array elements seperated by newline
function arrayToMultilineString(array) {
    
    let text = "";
    for (let item of array)
        text += item + "\n";
    
    return text;
}


function multilineStringToArray(multilineString) {
    
    if (!multilineString)
        return [];

    const array = multilineString.split('\n');
    return array.filter((string) => string.length > 0 );
}


/**
 * Some fields are read only or read only for the processor - by type. Thus we must supress the "required" and "modifiable" checkboxes for these types.
 * @param {String} fieldType type of the Field
 * @param {Boolean} forProcessor true if asking for the processor 
 */
function readOnly(fieldType, forProcessor) {

    switch (fieldType) {
        case "SignatureField":
            return forProcessor;    //read only if a processor not a patron

        case "CalcResultField":
        case "RichTextHtmlField":
            return true;            //always read only

        default:
            return false;  //all others are never read only
    }
}



/**
 * Some fields are not webhook enabled - by type. Thus we must supress the "webhook" checkboxes for these types.
 * @param {String} fieldType type of the Field
 */
 function webhookAllowed(fieldType) {

    switch (fieldType) {
        case "DocumentField":
        case "FieldGroup":
        case "PagedFieldGroup":
        case "CloningFieldGroup":
        case "RichTextHtmlField":
                return false;    

        default:
            return true;  //all others allowed
    }
}



/**
 * Some fields are not merge enabled - by type. Thus we must supress the "merged" checkboxes for these types.
 * @param {String} fieldType type of the Field
 */
 function mergeAllowed(fieldType) {

    switch (fieldType) {
        case "DocumentField":
        case "FieldGroup":
        case "PagedFieldGroup":
        case "CloningFieldGroup":
            return false;    

        default:
            return true;  //all others allowed
    }
}



/**
 * Some fields have no label
 * @param {String} fieldType type of the Field
 */
 function hasLabel(fieldType) {

    switch (fieldType) {
        case "RichTextHtmlField":
                return false;    

        default:
            return true;  //all others have labels
    }
}


/**
 * Some fields have no instructions
 * @param {String} fieldType type of the Field
 */
 function hasInstructions(fieldType) {

    switch (fieldType) {
        case "RichTextHtmlField":
                return false;    

        default:
            return true;  //all others have labels
    }
}