
import { Application } from './Application'

export class ApplicationCounts {
    
    stateCounts;            //converted from backend to an array of objects {state: a State enum object, and count: a count of that state}
    
    total;                  //this is a sum of the state counts, or if the state counts are null, just all application counts
    agsBacklog;             //for AGS use, these are the number of SUBMITTED for full service communities
    escalated;              //for AGS use, the number escalated

    //Construct from the backend Json Response
    constructor(json) {
        if (json) {
            if (json.stateCounts)
                this.stateCounts = json.stateCounts.map(stateCount => { return {state: Application.stateFromString(stateCount.state), count: stateCount.count}; });
           
            this.total = json.total;
            this.agsBacklog = json.agsBacklog;
            this.escalated = json.escalated;
        }  
    } 
    
}