import React, { Fragment } from 'react';

import { Container } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import AGTheme from '../Theme'

export default function Terms(props) {

    //    const isMobile = window.innerWidth < 916; 

    const source = "/terms.html";
      
    const iframeStyle = {
        flexGrow: 1, 
        border: 'none', 
        width: '100%',
        height: 600,
        marginTop: 10,
        padding: 0
    };
    

    return (
                 
        <ThemeProvider theme={AGTheme}>
            <Fragment>

                <Container maxWidth='lg'>
                    <iframe title="Terms" style={iframeStyle} src={source}></iframe> 
                </Container>
            </Fragment>
        </ThemeProvider>    

    );
       
}