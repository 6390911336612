import { DateUtils } from 'react-frontend-utils'


//Superclass for Applicant and Member - common fields
class Person {  
    
    lastName;
    firstName;
    imageBase64;
    birthDate;
    
    constructor(json) {
        if (json) {
            this.lastName = json.lastName;
            this.firstName = json.firstName;
            this.imageBase64 = json.imageBase64;
            
            if (json.birthDate) {
                this.birthDate = DateUtils.parseJsonDate(json.birthDate);
                this.birthDate.setHours(0, 0, 0, 0);  //zero time component, converts to local 
            }
        }
    }
    
    
    age() {
        return DateUtils.calculateAge(this.birthDate);        
    }
    
    
    hasNoAge() {
        return this.birthDate.getFullYear() <= 1900;
    }
    
    
    ageString() {
        
        if (!this.birthDate || this.hasNoAge(this.birthDate))
            return "--";
        else {
            return String(this.age());
        }   
    }
};

export class Applicant extends Person {
    
    index;          
    keepImage = true;       //Whether or not to use the Applicant's photo (overwrite matched Member, or use for new - default true)
    printPass = true;       //Whether or not to print the Applicant's pass
    printPassType;

    constructor(json, canPrint) {
        super(json);
        if (json) {
            this.index = json.index;
            this.printPass = json.printPass;
            this.printPassType = json.printPassType;
        }
        if (!canPrint)  // even if set in json, turn it off if we can't print
            this.printPass = false;
    }
    
}

export class Member extends Person {
    
    id;
    barcode;
    limited;

    matchedApplicant;            //an Applicant is matched with Member (the Applicant will overwrite most fields when sync is complete)
    matchRank;
      
    markedForDelete = false;     //Whether or not to delete the Member when syncing - default false
    
    lastTransaction;
    

    
    //Construct a Member from a MatchedPair that contains a Member, Applicant, and rank
    static fromPair(pairJson, canPrint) {
            
        if (pairJson) {
            
            const member = new Member(pairJson.member);
            
            member.id = pairJson.member.id; 
            member.barcode = pairJson.member.barcode;
            member.limited = pairJson.member.limited;

            member.matchRank = pairJson.matchRank;           
            member.matchedApplicant = new Applicant(pairJson.applicant, canPrint);
            
            member.lastTransaction = pairJson.member.lastTransaction;
            
            return member;
        }
    }
    
    //Construct a Member from just an orphaned member with no match
    static fromOrphan(json) {
        
        const member = new Member(json);
        
        if (json) {
            member.id = json.id;
            member.barcode = json.barcode;
            member.limited = json.limited;
            member.matchRank = null;           
            member.matchedApplicant = null;
            member.lastTransaction = json.lastTransaction;
        }
        return member;
    }
    
    
    
    
    
}
