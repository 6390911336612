import React from 'react';
import ag_logo from '../assets/logo.png'
import avatar from '../assets/avatar.png'


export const MEMBER_IMAGE_NATIVE_WIDTH = 412;
export const MEMBER_IMAGE_NATIVE_HEIGHT = 550;


const MEMBER_IMAGE_WIDTH = MEMBER_IMAGE_NATIVE_WIDTH/2;
const MEMBER_IMAGE_HEIGHT = MEMBER_IMAGE_NATIVE_HEIGHT/2;

export const MEMBER_IMAGE_ASPECT_RATIO = MEMBER_IMAGE_NATIVE_WIDTH/MEMBER_IMAGE_NATIVE_HEIGHT;


//LOGO for top menu bar
export const logo = (<img src={ag_logo} alt="AGLogo" height="48" style={{background: 'white', padding: 4, borderRadius: 8}}/>);


export const ImageFunctions = {

    memberImageSize: () => {
        return {width: MEMBER_IMAGE_WIDTH, height: MEMBER_IMAGE_HEIGHT};
    },


    /**
     * Returns JSX img tag with the image from the base64 encoded member image. If there is no image,
     * a generic avatar image is returned
     * @param member {Member} to render the image for
     * @param scale {Number} a value to scale down by (2 = 2 times smaller)  Default = 1.0
     * @param opacity, a value 0.0 to 1.0, where 1.0 is fully opaque.  Default = 1.0
     * @returns img tag with the local image
     */
    memberImageURL: (member, scale = 1.0, opacity = 1.0) => {

        const size = ImageFunctions.memberImageSize();

        if (member.imageBase64) {
            return (<img src={member.imageBase64}
                        alt={"member"}
                        width={size.width / scale} 
                        height={size.height / scale} 
                        style={{opacity: opacity, borderRadius: '4px'}}
                    />);
        }
        else {
           return (<img src={avatar} alt="member avatar" 
                        width={size.width / scale} 
                        height={size.height / scale}  
                        style={{opacity: opacity, borderRadius: '4px'}}
                    />);
        }

    }
};

