import React, { Component, Fragment } from 'react';
import { Container, Popover, Button, TextField, Box, Typography, Checkbox } from '@material-ui/core'

/**
 *  The ApplicationMessagePopover presents a modal dialog for entering text information and a reason why an Application is placed on hold or declined.
 *  The caller must pass in props:
 *  
 *  a boolean called "isSupportRequest" which changes the text appropriately if the Application is actually a support request
 *  a boolean called "isOpen" which determines whether the dialog is open or not
 *  a string called "title" which is the title of the dialog (can dynamically change)
 *  a function called "okCallback" which takes two string of user-entered text (patron message and internal comment) called when the Ok button is pressed with the strings as arguments
 *  a function called "skipCallback" which is called when an email should not be sent, called with one argument: the internal comment
 *  a function called "cancelCallback" which is called when the Cancel button is pressed
 * 
 *  After one of the callback buttons is pressed, the entered text is cleared to allow reuse of the component
 * 
 */


export class ApplicationMessagePopover extends Component {

    styles = {
        button: {
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };

    _okCallback;
    _skipCallback;
    _cancelCallback;
    _maxChars = 1024;
    
    state = {
        patronMessage: "",
        internalComment: "",
        sendChecked: true
    }

    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._skipCallback = props.skipCallback;
        this._cancelCallback = props.cancelCallback;
    }
   
    _patronMessageFieldChanged = (event) => {
        if (this.state.patronMessage.length >= this._maxChars)
            return;
        
        this.setState({patronMessage: event.target.value});
    }
    
    _internalCommentFieldChanged = (event) => {
        if (this.state.internalComment.length >= this._maxChars)
            return;
        
        this.setState({internalComment: event.target.value});
    }
    
    
    _okButtonPressed = () => {
        
        if (this.state.sendChecked)
            this._okCallback(this.state.patronMessage, this.state.internalComment);
        else
            this._skipCallback(this.state.internalComment);

        this.setState({patronMessage: "", internalComment: "", sendChecked: true});  //clear for reuse
    }
    

    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this.setState({patronMessage: "", internalComment: "", sendChecked: true});  //clear for reuse
    }
    
    render() {
        
        let maxWidth = window.innerWidth * 0.66;  //two thirds the window, up to 650
        if (maxWidth > 650)
            maxWidth = 650;
        
        let open = false;
        if (this.props.isOpen === true)
            open = true;

        const type = this.props.isSupportRequest ? "Support Request" : "Application";
        const who = this.props.isSupportRequest ? "Patron" : "Applicant";
        
        const emailLabel = "A generic message will be sent to the " + who + " for the " + type + "'s change in status. You may append an additional message to the email.";

        const internalComment = "Add an internal comments on the " + type + "'s change in status below. These comments are not included in the email or visible by the " + who + ".";
        
        return (
            <Popover open={open} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}} >
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>{this.props.title}</Typography>   
                    
                    
                     <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 0, marginLeft: -5}}>
                    
                        <Checkbox checked={this.state.sendChecked} color='primary'
                                    onChange={(event) => { this.setState({sendChecked: event.target.checked})}}/>   
                        <Typography variant='body1' align='left'>{"Send Email to " + who}</Typography>   
                    </div>

                    {this.state.sendChecked ? 
                        <Fragment>
                            <Typography variant="body2" style={{fontStyle: 'italic', color: 'gray', marginBottom: 10}}>{emailLabel}</Typography>   

                            <TextField autoFocus multiline={true} rows={6} label="Additional Message" value={this.state.patronMessage} onChange={this._patronMessageFieldChanged} 
                                       variant="outlined" fullWidth={true} inputProps={{maxLength: this._maxChars}} InputLabelProps={{ shrink: true}} style={{marginBottom: 20}} />                    
                        </Fragment>
                        : null
                    }
                    
                    <Typography variant="body2" style={{fontStyle: 'italic', color: 'gray', marginBottom: 10}}>{internalComment}</Typography>   
                    <TextField autoFocus multiline={true} label="Internal Comment" rows={6} value={this.state.internalComment} onChange={this._internalCommentFieldChanged} 
                                variant="outlined" fullWidth={true} inputProps={{maxLength: this._maxChars}} InputLabelProps={{ shrink: true}} style={{marginBottom: 20}} />
                    
                    
                    
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}