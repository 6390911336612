import { DateUtils } from 'react-frontend-utils'


export class Document {
      
    id;
    boundToApplicationID;
    originalFilename;             
    documentSize;
    
    uploadDate;         //UTC

    constructor(json) {
        if (json) {
            
            this.id = json.id;
            this.originalFilename = json.originalFilename;
            this.documentSize = json.documentSize;
            this.boundToApplicationID = json.boundToApplicationID;

            if (json.uploadDate) {
                this.uploadDate = DateUtils.parseJsonDate(json.uploadDate, true);  //convert from UTC
            }
        }
    }

    isBound() {
        return this.boundToApplicationID != null;
    }

    static unknown() {
        const d = new Document();
        d.id = "?";
        d.originalFilename = "Missing Document";
        d.documentSize = 0;
        return d;
    }

}