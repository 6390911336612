import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { IconButton, Tooltip, Typography, Paper, Button, Collapse } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReplyIcon from '@material-ui/icons/Reply';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';

import { renderField } from '../components/FieldRenderer'
import { ApplicationMessagePopover } from '../components/ApplicationMessagePopover'
import CommentPopup from '../components/CommentPopup'

import { ThemeColors } from '../Theme'
import { OpenInNewTab } from '../App'

import { Global } from '../models/Global'
import { Application } from '../models/Application'
import { AppModification } from '../models/AppModification'
import { DateUtils, Currency, RichTextDisplay, PopupMenu, draftContentToHtml, Permissions } from 'react-frontend-utils'
import { DocumentUploaderDownloaderComponent } from "../components/DocumentUploaderDownloaderComponent";
import { RefundPopover } from "../components/RefundPopover";


class ApplicationPage extends DocumentUploaderDownloaderComponent {
  
   
    styles = {
        status: {
            margin: 'auto',
            width: 90,
            textTransform: 'uppercase',
            padding: 5, 
            marginRight: 10,
            marginTop: 2,
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
        testMode: {
            margin: 'auto',
            marginRight: 10, 
            marginTop: 5, 
            padding: 5,
            width: 140,
            textTransform: 'uppercase',
            borderRadius: 2, 
            textAlign: 'center', 
            fontWeight: 'bold', 
            color: 'white', 
            backgroundColor: 'red'
        },
        isCopy : {
            margin: 'auto',
            marginRight: 10, 
            marginTop: 5, 
            padding: 5,
            width: 90,
            textTransform: 'uppercase',
            borderRadius: 2, 
            textAlign: 'center', 
            fontWeight: 'bold', 
            color: 'black', 
            backgroundColor: 'orange'
        },
        claimed: {
            marginRight: 10, 
            marginTop: 5
        }
    };
   
    _id;   //Application id 
    _exitCallback;
    
    constructor(props) {
        super(props);
    
        this._id = props.id; 
        this._exitCallback = props.exitCallback;
                
        this.state.application = props.claimedApplication;  //The Application object.  Null if not found or Application Object when fetched.
        this.state.fetchInProgress = false;
        
        this.state.revealJournal = false;
        this.state.commentPopupOpen = false;
        this.state.releaseToState = null;
        this.state.showInstructions = false;
        this.state.otherCommentPopupOpen = false;        //for general comment or flag
        this.state.otherCommentType = null;              //One of "comment", "flagCommunity", "escalateAGS"
        this.state.revertCommentPopupOpen = false;
        this.state.refundPopupOpen = false;
        this.state.maxRefund = 0;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the full application (if we don't have it yet)
     */
    componentDidMount() {
        super.componentDidMount(); 
        
        if (!this.state.application)  //fetch if not set
            this._fetchApplication(this._id); 
        
        // Browser back button - go back to Application List
        window.addEventListener("popstate", this._popstateCallback);
        
        window.refresh = (id) => { //on refresh, called from another tab refetch the Application id (same id)
            console.log("Sync tab requests refresh");
            this._fetchApplication(id);
        };  
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._popstateCallback);
    }
    
    
    _popstateCallback = (e) => {
        this._exitCallback();
    }
    
    
    _fetchApplication = (id) => {

        this.state.fetchInProgress = true;

        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + id, {},
                             this._fetchApplicationCallback, this._fetchErrorCallback); 
        
    }
   
    
    //Callback for fetching Application - response is an Application
    _fetchApplicationCallback = (response) => {
        if (response) {            
            const app = new Application(response);
            this.setState({application: app, fetchInProgress: false});  //set to retrieved application
            
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
        this.setState({application: null, fetchInProgress: false}); //fetching application failed
    }
    
  
    //toggle revealing the journal
    _revealJournal = () => {
        this.setState({revealJournal: !this.state.revealJournal}); 
    }
  
  
    _fieldChanged = (field, newValue) => {
        const am = AppModification.modifyField(field.name, newValue);
         
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this.state.application.id + "/modify", {method: "POST", body: JSON.stringify(am)},
                             this._fetchApplicationCallback, this._changeErrorCallback); 
                             
    }
  
  
    _fieldInputError = (field, error) => {
        this.showConfirmAlert("Error in Field \"" + field + "\"", error, 'red');
    }
    
    
    //Attempt to claim the application, if successful, select it
    _claim = () => {
        
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this.state.application.id + "/claim", {method: "POST"},
                             this._fetchApplicationCallback, this._changeErrorCallback); 
        
    }
    
     //Attempt to release the application
    _release = (newState) => {
        
        //Offer a chance to comment
        if (newState === Application.State.DECLINED || newState === Application.State.ON_HOLD || newState === Application.State.PROCESSED) {
            this.setState({commentPopupOpen: true, releaseToState: newState});
        }
        else
            this._completeRelease(newState, null, null, false); //release, no comments
    }

    //Release previously processed
    _releasePreviouslyProcessed = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this.state.application.id + "/releasePreviouslyProcessed", {method: "POST"},
                             this._fetchApplicationCallback, this._changeErrorCallback); 
    }



    //Attempt to revert the application, after the comment is posted to community
    _revert = (id) => {
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + id + "/revert", {method: "POST"}, this._fetchApplicationCallback, this._changeErrorCallback); 
    
    }
    
    _completeRelease = (newState, patronMessage, internalComment, sendEmail) => {
        const am = AppModification.release(newState, patronMessage, internalComment, sendEmail);
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this.state.application.id + "/release", {method: "POST", body: JSON.stringify(am)},
                             this._fetchApplicationCallback, this._changeErrorCallback); 
                             
    }
    
    //Processor sets a patronMessage, internalComment, and wants to send the email to patron
    _commentOkCallback = (patronMessage, internalComment) => {
        this._completeRelease(this.state.releaseToState, patronMessage, internalComment, true);  
        this.setState({commentPopupOpen: false, releaseToState: null});
    }
    
    //Processor sets a internalComment, and suppresses the email send
    _commentSkipCallback = (internalComment) => {
        this._completeRelease(this.state.releaseToState, null, internalComment, false); 
        this.setState({commentPopupOpen: false, releaseToState: null});
    }

    _openInPoolPass = () => {
        const membershipID = this.state.application.syncedMembership ? this.state.application.syncedMembership : "";
        const url = "https://portal.pool-pass.com/membership?database=" + this.state.application.group + "&id=" + membershipID;
            
        const windowRight = window.screenLeft + window.outerWidth;    
                
        window.open(url, '_blank', 'toolbar=yes, scrollbars=yes, resizable=yes, ' +
                    'top=' + window.screenTop + ',left=' + windowRight);

    }
    
    
    _syncPoolPass = () => {

        if (this.state.application.syncedMembership) {

            const msg = "This " + (this.state.application.isSupportRequest ? "Support Request" : "Application") + " has already been synchronized with Membership \"" + this.state.application.syncedMembership + "\". Sync again?"
            this.showConfirmAlert("Already Synced", msg, 'black',
                                  "Cancel", this._confirmDoSync, "Sync Again", 'red');   
        }
        else
            this._confirmDoSync();
    }

    _confirmDoSync = () => {

        let url = "//" + window.location.hostname + ":" + window.location.port + "/agportal/sync?group=" + this.state.application.group + "&id=" + this.state.application.id;
        if (this.state.application.isSupportRequest)
            url += "&support=true";
        OpenInNewTab(url);           
    }

    _openOriginalApplication = () => {
        OpenInNewTab("//" + window.location.hostname + ":" + window.location.port + "/agportal?id=" + this.state.application.originalApplicationID);           
    }
    

    _changeErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

    _sendWebhook = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this.state.application.id + "/sendWebhook", {method: "POST"},
                             null, this._changeErrorCallback); 
    }
    
    _showInstructions = () => {
        this.setState({showInstructions: !this.state.showInstructions});
    }

    _downloadPdf = () => {
        this.secureFileDownload("/ap/applications/" + this.state.application.id + "/pdf", 
                                this.state.application.id + ".pdf", 
                                null, 
                                (error) => this.showConfirmAlert("Error", error, 'red'));
    }

    _downloadDoc = () => {
        this.secureFileDownload("/ap/applications/" + this.state.application.id + "/mergedDoc", 
                                "Document for " + this.state.application.id + ".pdf", 
                                null, 
                                (error) => this.showConfirmAlert("Error", error, 'red'));
    }
    
    _popoutInstructions = () => {
        this.setState({showInstructions: false});
            
        const windowRight = window.screenLeft + window.outerWidth;    
            
        const newWindow = window.open("", "Instructions", 'location=off,toolbar=no,scrollbars=yes,resizable=yes,' +
                                      'top=' + window.screenTop + ',left=' + windowRight +',width=600,height=400');

        const title = "Instructions for " + this.state.application.group;
        
        const instructions = draftContentToHtml(this.state.application.instructions);
        
        newWindow.document.open();  //clear
        newWindow.document.write("<html><title>" + title + "</title>" +
                                    "<body>" +
                                    "<h3>" + title + "</h3>" +
                                     instructions +
                                    "</body>" +
                                 "</html>");
                         
        newWindow.document.close();
        
    }
    
    _applicationDocumentUploader = (origDocumentID, file, callback) => {
        this.documentUploader(origDocumentID, file, callback, this._id);
    }
  
    _otherComment = (type) => {
        this.setState({otherCommentPopupOpen: true, otherCommentType: type});
    }

    _internalSupportRequest = () => {
        OpenInNewTab("//" + window.location.hostname + ":" + window.location.port + "/community?group=" + 
                     this.state.application.group + "&origID=" + this._id + "&email=" + Global.user.email);
    }

    _maxRefundAmount = () => {
        const ap = this.state.application;
        if (!ap || !ap.paidOn || ap.isPaper)  //no application or not yet paid, or paper applications
            return 0.0;
        
        const paymentAmount = Currency.round(ap.calculatedPaymentAmount);
        const refundedAmount = Currency.round(ap.refundedAmount);
        return paymentAmount - refundedAmount;
    }

    _refundPayment = () => {
      
        const maxRefundable = this._maxRefundAmount();
        this.setState({refundPopupOpen: true, maxRefund: maxRefundable});
    }

    _startRefund = (amount, comment) => {
        
        const amountToRefund = Currency.round(amount);
        this.setState({refundPopupOpen: false});

        const am = AppModification.refund(amountToRefund, comment);        
        const msg = Currency.symbolForISO(this.state.application.currency) + amountToRefund + " will be refunded to patron's card/bank.";
        
        this.showConfirmAlert("Confirm Refund Request", msg, 'black', "Cancel", () => this._submitRefund(am), "Refund", 'red');   
    }

    _submitRefund = (am) => {
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this.state.application.id + "/refund", {method: "POST", body: JSON.stringify(am)},
                             this._fetchApplicationCallback, this._changeErrorCallback); 
    }


    _forceMarkAsProcessed = () => {
        
        this.showConfirmAlert("Confirm Mark as Processed", "Mark as Processed without Sync? Use this only when syncing manually.", 'black', "Cancel", 
                            () => this._release(Application.State.PROCESSED), "Mark as Processed", 'red');   
    }


    //Action items are items for the gear popup menu
    _getActionItems = () => {
            
        const actionItems = [];

        // client only operations - restricted from employees unless they have developer permissions for testing
        const isClient = !Global.user.isSiteEmployee() || Global.user.hasPermissionTo(Permissions.DEVELOP);
        
        if (this.state.application) {
            
            actionItems.push({label: "Comment", selectCallback: () => {this._otherComment("comment");}, icon: null});
            actionItems.push({label: "Flag to Community", selectCallback: () => {this._otherComment("flagCommunity");}, icon: null});
           
            if (this.state.application.escalateable() && isClient)
                actionItems.push({label: "Escalate to AGS", selectCallback: () => {this._otherComment("escalateAGS");}, icon: null});
            
            if (this.state.application.stateEnum === Application.State.PROCESSED && isClient)
                actionItems.push({label: "Request Support from AGS", selectCallback: this._internalSupportRequest, icon: null});
            
            actionItems.push({label: "Resend Webhook", selectCallback: () => {this._sendWebhook()}, icon: null});

            if (Global.user.hasPermissionTo(Permissions.REFUND_APPLICATIONS) && this._maxRefundAmount() > 0) 
                actionItems.push({label: "Request Payment Refund", selectCallback: this._refundPayment, icon: null});

            if (Global.user.isSiteEmployee() && this.state.application.claimedByUser() && this.state.application.canSyncWithPP && !this.state.application.isSupportRequest && !this.state.application.syncedMembership)
                actionItems.push({label: "Force Mark as Processed", selectCallback: this._forceMarkAsProcessed, icon: null});

        }
        return actionItems;
    }


    render() {
        
        const ap = this.state.application;
        const revealJournalIcon = this.state.revealJournal ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;

        const type = ap && ap.isSupportRequest ? "Support Request" : "Application";
        const canChangeState = ap && ap.claimedByUser();
        const canSync = ap && ap.canSyncWithPP;
        const isApplication = ap && !ap.isSupportRequest;
        const canRelease = canChangeState && !ap.originallyReviewedBy;
        const canRevert = canChangeState && ap.agsEscalation && Global.user.isSiteEmployee(); 
        const canApproveWithoutSync = !canSync || (ap && ap.syncedMembership);
        const canReleasePreviouslyProcessed = canChangeState && ap.previousStateEnum === Application.State.PROCESSED;

        const buttonStyle = (state, disabled) => {
            
            let color =  ThemeColors.darkGray;
            if (disabled)
                color = 'lightGray';
            else if (state)
                color = state.backgroundColor;
            
            return {borderColor: color, color: color, maxWidth: 200, textAlign: 'center'};
        };
        
        let commentsTitle;
        if (this.state.releaseToState === Application.State.ON_HOLD)
            commentsTitle = "Reason for Placing " + type + " on Hold?";
        else if (this.state.releaseToState === Application.State.DECLINED)
            commentsTitle = "Reason for Declining this " + type + "?";
        else
            commentsTitle = "Additional information to Patron";

        //Paid amount for paper is the amount actually received, not calculated
        let paidAmount = 0.0;
        if (ap)
            paidAmount = ap.isPaper ? ap.paperPaymentAmount : ap.calculatedPaymentAmount;

        const paymentAmount = Currency.round(paidAmount);
        const refundedAmount = ap ? Currency.round(ap.refundedAmount) : 0.0;

        //Default, assume we haven't paid
        let paidTooltip = "Awaiting Payment";   
        let paidText = "PENDING";
        let paidColor = {background: 'yellow', text: 'black'};
        if (ap && ap.paidOn) { 
            if (paymentAmount > 0) {

                if (refundedAmount > 0) {
                    if (refundedAmount >= paymentAmount)
                        paidText = "REFUNDED";
                    else
                        paidText = "PARTIALLY REFUNDED";
                    paidColor = {background: 'cyan', text: 'black'};
                }                
                else { //fully paid
                    paidText = "PAID";
                    paidColor = {background: 'green', text: 'white'};
                }   
                paidText += (ap.testing ? " (TEST)" : "");
                
                paidTooltip = <div style={{fontSize: 12}}>
                                <div>{"Paid on: " + Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(ap.paidOn)}</div>
                                {ap.checkInfo ?  <div style={{marginTop: 5}} >{"Check Info: " + ap.checkInfo}</div> : null}
                                {ap.paymentIntent ? <div style={{marginTop: 5}} >{"Pay ID: " + ap.paymentIntent}</div> : null}
                                {refundedAmount > 0 ? <div style={{marginTop: 5}} >{"Refunded: " + Currency.symbolForISO(ap.currency) + refundedAmount}</div> : null}
                              </div>
            }
            else {
                paidText = "NO CHARGE"
                paidTooltip = "No charge";
                paidColor = {background: 'gray', text: 'white'};
            }
        }
        const renderParams = {forPatron: false, 
                              canEdit: ap && ap.claimedByUser(), 
                              onChange: this._fieldChanged,
                              documentUploader: this._applicationDocumentUploader,
                              documentDownloader: this.documentDownloader,
                              onError: this._fieldInputError};


        const redirectIcon =  <ReplyIcon style={{transform: 'scaleX(-1) rotate(20deg)'}}/>;

        return (
            <Fragment>
                {this.getConfirmAlertComponent()}
                
                <CommentPopup applicationID={ap ? ap.id : null} commentType={this.state.otherCommentType} commentPopupOpen={this.state.otherCommentPopupOpen}
                              onCancel={() => {this.setState({otherCommentPopupOpen: false});}} 
                              onCommentPostInitiated={() => {this.incrementBusy(); this.setState({otherCommentPopupOpen: false});}} 
                              onCommentPostedSuccess={() => {this.setState({otherCommentPopupOpen: false});  this.decrementBusy(); this._fetchApplication(ap.id);}}
                              onCommentPostError={(error) => {this.decrementBusy(); this.setState({otherCommentPopupOpen: false}); this.showConfirmAlert("Error", error, 'red')}}/>
          
                <CommentPopup applicationID={ap ? ap.id : null} commentType="revertToCommunity" commentPopupOpen={this.state.revertCommentPopupOpen}
                              onCancel={() => {this.setState({revertCommentPopupOpen: false});}} 
                              onCommentPostInitiated={() => {this.incrementBusy(); this.setState({revertCommentPopupOpen: false});}} 
                              onCommentPostedSuccess={() => {this.setState({revertCommentPopupOpen: false});  this.decrementBusy(); this._revert(ap.id);}}
                              onCommentPostError={(error) => {this.decrementBusy(); this.setState({revertCommentPopupOpen: false}); this.showConfirmAlert("Error", error, 'red')}}/>
          
                <RefundPopover isOpen={this.state.refundPopupOpen} maxVal={this.state.maxRefund} currency={ap ? ap.currency : ""}
                               okCallback={this._startRefund} cancelCallback={() => this.setState({refundPopupOpen: false})}/>                 
           

                <ApplicationMessagePopover isOpen={this.state.commentPopupOpen} title={commentsTitle} isSupportRequest={ap && ap.isSupportRequest}
                                           okCallback={this._commentOkCallback} skipCallback={this._commentSkipCallback} cancelCallback={() => {this.setState({commentPopupOpen: false});}} />
                
                
                <div style={{display: 'flex', gap: 20, alignItems: 'center', justifyContent: 'center', marginBottom: 20}}>
                
                         
                    {canRelease ?
                        <Tooltip title={"Release claim on " + type + " making it available to be claimed again"}>
                            <Button disabled={!canChangeState} fullWidth size='small' 
                                    style={buttonStyle(Application.State.SUBMITTED, !canChangeState)} 
                                    onClick={() => this._release(Application.State.SUBMITTED)} variant="outlined" component="label">
                                Release
                            </Button>
                        </Tooltip>
                        : null
                    }

                    {canReleasePreviouslyProcessed ?
                        <Tooltip title={"Release claim on " + type + " making it available to be claimed again"}>
                            <Button disabled={!canReleasePreviouslyProcessed} fullWidth size='small' 
                                    style={buttonStyle(Application.State.SUBMITTED, !canReleasePreviouslyProcessed)} 
                                    onClick={this._releasePreviouslyProcessed} variant="outlined" component="label">
                                Release
                            </Button>
                        </Tooltip>
                        : null
                    }

                    {canRevert ? 
                        <Tooltip title={"Release claim on " + type + ", reverting the AGS escalation"}>
                            <Button disabled={!canChangeState} fullWidth size='small' 
                                    style={buttonStyle(Application.State.SUBMITTED, !canChangeState)} 
                                    onClick={() => this.setState({revertCommentPopupOpen: true})} variant="outlined" component="label">
                                Revert
                            </Button>
                        </Tooltip>
                        : null
                    }
                    
                    <Tooltip title={"Release claim on " + type + ", placing it in the On Hold state"}>
                        <Button disabled={!canChangeState} fullWidth size='small' 
                                style={buttonStyle(Application.State.ON_HOLD, !canChangeState)} 
                                onClick={() => this._release(Application.State.ON_HOLD)} variant="outlined" component="label">
                            Hold
                        </Button>
                    </Tooltip>
                    
                    <Tooltip title={"Release claim on " + type + ", declining it"}>
                        <Button disabled={!canChangeState} fullWidth size='small' 
                                style={buttonStyle(Application.State.DECLINED, !canChangeState)}
                                onClick={() => this._release(Application.State.DECLINED)} variant="outlined" component="label">
                            Decline
                        </Button>
                    </Tooltip>

                    {ap && ap.isSupportRequest ?
                        <Tooltip title="Mark the Support Request as Resolved and Release the claim on it">
                            <Button disabled={!canChangeState} fullWidth size='small' 
                                    style={buttonStyle(Application.State.PROCESSED, !canChangeState)}
                                    onClick={() => this._release(Application.State.PROCESSED)} variant="outlined" component="label">
                                Resolve
                            </Button>
                        </Tooltip>
                        : null
                    }

                    {isApplication ?
                        <Tooltip title="Approve Application, mark as Processed">
                            <Button disabled={!canApproveWithoutSync || !canChangeState} fullWidth size='small' 
                                    style={buttonStyle(Application.State.PROCESSED, !canApproveWithoutSync || !canChangeState)} 
                                    onClick={() => this._release(Application.State.PROCESSED)} variant="outlined" component="label">
                                Approve
                            </Button>
                        </Tooltip> 
                        : null
                    }
       
                    <Tooltip title={"Claim " + type + " to start processing"}>
                        <Button disabled={!ap || !ap.claimable()} fullWidth size='small'  
                                style={buttonStyle(Application.State.CLAIMED, !ap || !ap.claimable())}
                                onClick={this._claim} variant="outlined" component="label">
                            Claim
                        </Button>
                    </Tooltip>                             
                                 
                    <Tooltip title={"Return back to Application List, keeping the " + type + "'s current state"}>
                        <Button fullWidth size='small'                                 
                                style={buttonStyle(null, false)}
                                onClick={this._exitCallback} variant="outlined" component="label">
                            Back
                        </Button>
                    </Tooltip> 
                
                </div>
                
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 20, alignItems: 'center', justifyContent: 'center', marginBottom: 30, paddingTop: 20, borderTop: '2px solid lightGray'}}>
                    
                    {canSync ?
                        <Fragment>
                            <Tooltip title={"Transfer data from " + type + " to PoolPass"}>
                                <Button disabled={!canChangeState} fullWidth size='small'  
                                        style={buttonStyle(Application.State.PROCESSED, !canChangeState)}
                                        onClick={this._syncPoolPass} variant="outlined" component="label" startIcon={<SyncAltIcon/>}>
                                    Sync with PoolPass
                                </Button>
                            </Tooltip>
                            <Tooltip title="Open PoolPass, and if processed, visit the Membership that was synchronized in PoolPass">
                                <Button fullWidth size='small'  
                                        style={buttonStyle(null)}
                                        onClick={this._openInPoolPass} variant="outlined" component="label" startIcon={redirectIcon}>
                                    Open in PoolPass
                                </Button>
                            </Tooltip>
                        </Fragment>

                        : null
                    }
                    
                    <Button disabled={!ap} fullWidth size='small'  
                            style={buttonStyle(null, !ap)}
                            onClick={this._showInstructions} variant="outlined" component="label" startIcon={<AssignmentIcon/>}>
                        {this.state.showInstructions ? "Hide Instructions" : "Show Instructions"}
                    </Button>

                    <Button fullWidth size='small'  
                            style={buttonStyle(null, !ap)}
                            onClick={this._downloadPdf} variant="outlined" component="label" startIcon={<GetAppIcon/>}>
                        Download PDF
                    </Button>

                    <Button disabled={!ap || !ap.hasMerge} 
                            fullWidth size='small'  
                            style={buttonStyle(null, !ap || !ap.hasMerge)}                  
                            onClick={this._downloadDoc} variant="outlined" component="label" startIcon={<GetAppIcon/>}>
                        Download Document
                    </Button>

                    <div style={{width: 20}}/>

                    {ap ? 
                        <PopupMenu menuIcon={(<SettingsIcon style={{fontSize: '24', color: ThemeColors.settingsOlive}}/>)}  
                                                menuItems={this._getActionItems()} 
                                                menuTooltipText={"Other Actions"}/>
                        : null
                    }

                        
                </div>
                
                
                {this.state.fetchInProgress ? null :
                    (ap === null ?
                        <Typography align='center' variant="h5">No Application</Typography> 
                        :
                        
                        <div>
                             <Collapse in={this.state.showInstructions}>                 
                                <Paper elevation={2} style={{padding: 10, marginBottom: 20}}> 
                                
                                    <Tooltip title="Pop-out to a new window">
                                        <IconButton onClick={this._popoutInstructions} style={{float: 'right'}}>
                                            {redirectIcon}
                                        </IconButton>
                                    </Tooltip>
                                
                                    <Typography variant="h5" style={{marginBottom: 5, marginLeft: 5}}>Application Processing Instructions</Typography> 
                                    <div style={{margin: 2, padding: 5, border: '3px solid ' + ThemeColors.appBarBackground, borderRadius: 5}}>
                                        <RichTextDisplay draftContent={ap.instructions}/>                                                                      
                                    </div>  
                                </Paper>
                            </Collapse>
                
                            <Paper elevation={3} style={{padding: 5, paddingRight: 10}}> 
                                <div style={{float: 'right'}}>
                                    <div style={{...this.styles.status, backgroundColor: ap.stateEnum.backgroundColor}}>
                                        {ap.stateEnum.label}
                                    </div>
                                    <div style={{marginTop: 10}}/>
                                    {ap.renderBadges('right')}
                                    <div style={{marginTop: 20}}/>
                                    {ap.claimedBy ? <Typography variant="body2" align='right' style={this.styles.claimed}>{"Claimed by: " + ap.claimerName}</Typography> : null}                              
                                    {ap.claimedAt ? <Typography variant="body2" align='right' style={this.styles.claimed}>{"Claimed at: " + DateUtils.timeFormat(ap.claimedAt)}</Typography> : null}                              
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 2, marginLeft: 10}}>
                                    <Typography variant="h5">{type + " ID:"}</Typography>  
                                    <Typography variant="h5" style={{fontFamily: 'monospace', marginLeft: 10}}>{ap.id}</Typography> 
                                </div>
                                {ap.originalApplicationID ? 
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography variant="body1" style={{marginLeft: 10}}>{"Original Application: "}</Typography>  
                                        <Button size='small'
                                                style={{fontFamily: 'monospace', textDecoration: 'underline', marginLeft: 10, paddingLeft: 2, paddingRight: 2}} 
                                                onClick={this._openOriginalApplication} component="label">
                                            {ap.originalApplicationID}
                                        </Button>
                                    </div>          
                                : null}
                                
                                <Typography variant="body1" style={{marginLeft: 10, marginTop: 20}}>{"Submitted: " + Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(ap.submitDate)}</Typography>  
                                <Typography variant="body1" style={{marginLeft: 10}}>{"Patron: " + ap.submitterEmail }</Typography> 
                                <Typography variant="body1" style={{marginLeft: 10}}>{"Patron Timezone: " + DateUtils.userTimeZone(ap.patronTzOffset)}</Typography>                             
                                <Typography variant="body1" style={{marginLeft: 10}}>{"Patron Location: " + ap.patronIPLocation}</Typography>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {ap.communityURL ? 
                                        <Fragment>
                                            <Typography variant="body1" style={{marginLeft: 10}}>Community:</Typography>
                                            <Button size='small'
                                                    style={{textDecoration: 'underline', marginLeft: 10, paddingLeft: 2, paddingRight: 2}} 
                                                    onClick={() => OpenInNewTab(ap.communityURL)} component="label">
                                                {ap.extendedCommunityName ? ap.extendedCommunityName : ap.group}
                                            </Button>
                                        </Fragment>
                                        :
                                        <Typography variant="body1" style={{marginLeft: 10}}>{"Community: " + (ap.extendedCommunityName ? ap.extendedCommunityName : ap.group)}</Typography>
                                    }
                                </div>          
                                                             

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography variant="body1" style={{marginLeft: 10, marginRight: 10}}>{"Payment: " + Currency.symbolForISO(ap.currency) +  paymentAmount}</Typography>  
                                    <Tooltip title={paidTooltip}>
                                        <div style={{border: '1px solid gray', backgroundColor: paidColor.background, color: paidColor.text, paddingLeft: 3, paddingRight: 3, borderRadius: 2}}>
                                            <Typography align='center' style={{fontSize: 10, fontWeight: 'bold'}}>{paidText}</Typography>  
                                        </div>    
                                    </Tooltip>
                                </div>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <IconButton edge="start" onClick={this._revealJournal}>
                                        {revealJournalIcon}                          
                                    </IconButton>      
                                    <Typography style={{marginLeft: -5}} variant="button">Journal</Typography>  
                                </div>  

                                <Collapse in={this.state.revealJournal}>
                                    {ap.renderJournal()}
                                </Collapse>

                                <div style={{borderBottom: '3px solid ' + ThemeColors.appBarBackground, margin: 10}}/> 

                                {renderField(ap.fieldTree, 0, renderParams)}


                            </Paper>
                        </div>
                    )
                }

               
            </Fragment>
        );
    }
  
}

export default withCookies(withRouter(ApplicationPage));
