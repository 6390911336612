import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import stripebadge from '../assets/stripebadge.png';

import { Typography, TextField, Paper, Button } from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { RestComponent, StripeManager } from 'react-frontend-utils'


/**
 * 
 * Checkout Component after Patron submits an Application that requires payment 
 * 
 * The caller must pass the props:
 * 
 * returnToApplication:    a function to be called to go back to modify the Application
 * purchaseComplete:       callback function when payment succeeds
 * paymentDescription:     String describing the payment (for instance the amount)
 * purchaseComplete:       a function to be called when purchasing is complete
 * clientSecret:           the clientSecret retrieved from the backend from Stripe, after Application is submitted
 * email:                  pre-populate the email for the credit card
 * submitDate:             the date the Application was submitted
 * 
 * The StripeManager component takes care of the purchase. 
 *
 */

const STALE_TIME = 24 * 3600 * 1000;  //24 hours to complete payment

export class PatronCheckout extends RestComponent {

    styles = {
        paperLabel: {
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        }
    }
        
    constructor(props) {
        super(props);
        this.state.name = "";                   //Name for the account
        this.state.email = props.email;         //Email for the account - pre populate from Application
        this.state.cardChargeFailure = false;
    }

    _returnToApplication = () => {
        this.setState({cardChargeFailure: false});
        this.props.returnToApplication();
    }


    //Called when patron finishes filling out card details and presses pay.  We have the opportunity to take some action here.  As long as the page isn't too stale,
    // we immediately call complete purchase
    _payNowPressed = (completePurchase, cancelPurchase) => {

        const now = (new Date()).getTime();
        if (now - this.props.submitDate.getTime() > STALE_TIME) {
            this.showConfirmAlert("Payment Failed", "Too much time has elapsed since you submitted your Application.  Please refresh your browser and start over", 'red');
            cancelPurchase(); 
        }
        else
            completePurchase(this.props.clientSecret);
    }
    
    _chargeSuccess = () => {
        this.props.purchaseComplete();  //purchase complete
    }

    _chargeFailure = (message) => {
        
        this.showConfirmAlert("Payment Failed", message + " You can try entering your payment information again.", 'red'); 
        this.setState({cardChargeFailure: true});
    }
    
    _fetchPublishableKey = (callback) => {
        this.secureJSONFetch("/payments/stripe/pubkey", {}, 
                             (response) => callback(response), 
                             (error) => this.showConfirmAlert("Failed to Load Payment System", error, 'red')
                             );
    }


    render() {
        
        return (
            <Fragment>

                {this.getConfirmAlertComponent()}

                <Paper style={{padding: 20}}>

                    <Typography variant="h6" align='center' style={{marginBottom: 40}}>{this.props.paymentDescription}</Typography>                           

                    <Button color="primary" style={{marginBottom: 20}} onClick={this._returnToApplication}>
                        <ArrowBackIosIcon fontSize='small'/> 
                        <Typography variant="body1">Cancel and Go Back to Application</Typography>   
                    </Button>  

                
                    <div style={{marginBottom: 25}}>                           
                        <div style={{paddingBottom: 20}}>

                            <div style={{margin: 25}}/>

                            <TextField size="small" label="Cardholder Name" variant="outlined" 
                                        onChange={(event) => {this.setState({name: event.target.value});}}
                                        value={this.state.name} 
                                        fullWidth={true} 
                                        InputLabelProps={{ shrink: true}} />
                        </div>
                    </div>                    
                    
                            
                    <StripeManager name={this.state.name} email={this.state.email}
                                    fetchPublishableKey={this._fetchPublishableKey}
                                    purchaseRequest={this._payNowPressed} 
                                    cardChargeSuccess={this._chargeSuccess}
                                    cardChargeFailure={this._chargeFailure}/>
                </Paper>

                <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>

                    <a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
                        {<img src={stripebadge} alt="stripe badge" width='400px'/>}
                    </a>

                </div>                

            </Fragment>
        );
    }

}

export default withCookies(PatronCheckout);
