import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import queryString from 'query-string';

import Home from './routes/Home';
import Patron from './routes/Patron';
import Terms from './routes/Terms';
import Sync from './routes/Sync';
import Export from './routes/Export';


function App() {
  return (
    <div className="App">
      <CookiesProvider>
        <Router>
            <Switch>

                <Route path='/' exact={true} render={ () => {
                    return <Redirect to="/agportal"/>;
                }}/>
              
                <Route path='/patron' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    
                     //Arrive to Patron portal, potentially with a group name, a testing key, support flag, and an original Application ID
                    return <Patron group={query.group} test={query.test} alternate={query.support} origApplicationID={query.origID} email={query.email} paper={query.paper}/>; 
                      
                }}/>

                <Route path='/community' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    
                     //Arrive to Community portal, potentially with a group name, a testing key, and an original Application ID
                     //This is the internal Community support form, we're going to re-use the Patron portal with a flag
                    return <Patron group={query.group} test={query.test} origApplicationID={query.origID} email={query.email} internal={true}/>; 
                      
                }}/>
                
                <Route path='/patron/terms' exact={true} component={Terms}/>     
                     
                <Route path='/agportal' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    
                    return <Home id={query.id}/>;  //arrive to Access Granted portal, potentially with an id
                      
                }}/>
                        
                <Route path='/agportal/sync' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    
                    return <Sync id={query.id} group={query.group} support={query.support}/>;  //Go to PP Sync Route with an application id, group, and support flag
                      
                }}/>

                <Route path='/agportal/export' exact={true} render={ (props) => {
                    let query = queryString.parse(props.location.search);
                    
                    return <Export id={query.id}/>;  //Go to Export Route with an export job id
                      
                }}/>


            </Switch>
        </Router>
      </CookiesProvider>
    </div>
  );
}

export default App;


//Use this to send to an external website (safely!) in a new tab
export function ExternalLink(props) {
    
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.target}</a>;
    
}


//Be careful here! XSS attack possible with url.  Use only on same site.  newWindow.opener is not cleared by this function
export function OpenInNewTab(url) {
    const newWindow = window.open(url);
    newWindow.applicationTab = window;
}
