import { RestComponent } from 'react-frontend-utils';
import { withCookies } from 'react-cookie';

import  { Document } from '../models/Document';

export class DocumentUploaderDownloaderComponent extends RestComponent {

    static MAX_UPLOAD_SIZE_MB = 10;

    onUploadError = (error, callback) => {
        this.showConfirmAlert("Error", error, 'red');
        callback(null);
    };

    /**
     * Called from documentUploader when new progress is received. In reality, the progress is only updated when an error occurs or upload is complete
     * @param {boolean} isDone
     * @param {String} status   (status string: percentComplete:message:anotherMessage) where message is the document size or an error
     * @param {Function} callback
     * @param {String} docId
     */
    onUpdatedProgress = (isDone, status, callback, docId) => {

        console.log(status);

        const split = status.split(";");
        const progressValue = split[0];
        const message = progressValue < 0 ? ("Error: " + split[1]) : split[1]; //error or document size in bytes

        if (progressValue < 0) //error
            this.showConfirmAlert("Error", message, 'red');

        if (progressValue === "100")
            callback(docId, parseInt(message)); //send doc id and document size in bytes

    };

    /**
     * Upload a document - max file size 10MB
     * @param {String} origDocumentID the original document ID to replace / delete (null if new)
     * @param {File} file the file to upload (null to delete the file)
     * @param {Function} callback function when complete, passes a the new/updated document ID (null for delete) and size in bytes (null for delete)
     * @param {String} applicationID when part of an application, this is the id - may be undefined for Templates/Patrons
     */
    documentUploader = (origDocumentID, file, callback, applicationID) => {


        //Upsert a file
        if (file) {
            console.log("Uploading " + file.name + (origDocumentID ? " (New)" : " (Replace)"));

            if (file.size > DocumentUploaderDownloaderComponent.MAX_UPLOAD_SIZE_MB * 1024 * 1024) {
                this.showConfirmAlert("File is too large", "File exceeds maximum size of " + DocumentUploaderDownloaderComponent.MAX_UPLOAD_SIZE_MB + "MB", 'red');
                setTimeout(() => callback(origDocumentID));
                return;
            }

            let queryString = "?filename=" + encodeURIComponent(file.name); //send original filename
            if (applicationID)
                queryString += "&applicationID=" + applicationID;

            const docID = origDocumentID ? origDocumentID : RestComponent.secureRandom(64); //replace the original document ID (or create a new one for the first time)


            //Form data has the file as a multipart 
            const formData = new FormData();
            formData.append('file', file);

            this.secureFileUpload("/documents/" + docID,
                file,
                (isDone, status) => this.onUpdatedProgress(isDone, status, callback, docID),
                (error) => this.onUploadError(error, callback),
                queryString);


        }
        else {

            if (!origDocumentID) {
                console.log("Cannot delete file without id");
                return;
            }

            console.log("Deleting " + origDocumentID);

            this.secureJSONFetch("/documents/" + origDocumentID,
                { method: "DELETE" },
                () => callback(null),
                (error) => this.onUploadError(error, callback));
        }


    };



    documentInfoReceived = (response, documentInfoCallback) => {

        if (response) {  //Response is a Document object 
            
            const document = new Document(response);

            if (documentInfoCallback) {  //callback defined, just send the document info
                documentInfoCallback(document);
                return;
            }
            
            //Not defined, so download the file instead
            const filename = document.originalFilename;
            this.secureFileDownload("/documents/" + document.id, 
                                    filename, 
                                    null, 
                                    (error) => this.showConfirmAlert("Error", error, 'red'));
            
        }
    }


    /**
     * Either fetch the Document info or Download the Document specified by the id
     * if documentInfoCallback is not null, the info is fetched and passed to the callback.  Otherwise, the document is downloaded
     * @param {String} documentID documentID
     * @param {Function} documentInfoCallback callback for file info, receives an object with document info
     */
    documentDownloader = (documentID, documentInfoCallback) => {

        this.secureJSONFetch("/documents/" + documentID + "/info", {},
                             (response) => this.documentInfoReceived(response, documentInfoCallback),
                             (error) => {   this.showConfirmAlert("Error", error, 'red')
                                            if (documentInfoCallback)
                                                 documentInfoCallback(Document.unknown());
                                        } 
                            );


    }


}


export default withCookies(DocumentUploaderDownloaderComponent);