import { DateUtils } from 'react-frontend-utils'

export class JournalEntry {

    date            //Converted to local
    author;        
    activity;       
    note;      
   

    constructor(json) {
        if (json) {
             if (json.date) {
                this.date = DateUtils.parseJsonDate(json.date, true);   //from UTC
            }
            this.author = json.author;
            this.activity = json.activity;
            this.note = json.note;
        }
    }
}