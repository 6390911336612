import { draftContentToHtml } from 'react-frontend-utils'

export class Template {
      
      
    static PATRONS_GROUP_NAME = "patronsgroup";  
    static PATRON_GROUP_NAME = "patrongroup";
    
    static APPLICATION_ADDRESS_FIELD_NAME = "address";   
   
    static PATRON_BIRTHDATE_FIELD_NAME = "patronBirthDate";
    static PATRON_IMAGE_FIELD_NAME = "patronImage";
    static PATRON_PRINT_PASS_SELECTION = "patronPrintPass";  
  
    static MEMBER_ASPECT_RATIO = 550/412;  
    
    
    static membershipTextFieldNames = [{label: "Address", name: Template.APPLICATION_ADDRESS_FIELD_NAME, required: true, type: "AddressField"},
                                       {label: "Primary Phone", name: "primaryPhone", required: false, type: "TextField"}, 
                                       {label: "Alternate Phone", name: "secondaryPhone", required: false, type: "TextField"}, 
                                       {label: "Email", name: "email", required: true, type: "EmailField"}, 
                                       {label: "Emergency Contact", name: "emergencyContact", required: false, type: "EmergencyContactField"}, 
                                       {label: "Notes", name: "notes", required: false, type: "TextField"}];

    
    static memberTextFieldNames = [{label: "First Name", name: "patronFirstName", required: true},
                                   {label: "Last Name", name: "patronLastName", required: true}, 
                                   {label: "Relation", name: "patronRelation", required: false}];
                                  
    
      
    name   
    type;               //Class type, for Jackson

    version;
    comment;
    
    fieldTree;
    fieldCalculations;
  
    constructor(json) {
        if (json) { 
            this.name = json.name;
            this.version = json.version;
            this.type = json.type;
            this.comment = json.comment;
            
            if (json.fieldTree) {  //Field tree only populated when individual Template is pulled for editing
                this.fieldTree = json.fieldTree;
                this.fieldTree.isBase = true;           //mark this as the base of the tree
            }           
            
            if (json.fieldCalculations)
                this.fieldCalculations = json.fieldCalculations;
            else
                this.fieldCalculations = [];
        }
    }
    
    
    //Prepares Template for saving as a new 
    copyToNew(newName) {
     
        this.name = newName;
        this.version = null;
    }
    

    //Call this method prior to posting the Template for upserting
    prepareForPost() {
        Template.processFieldsForUpload(this.fieldTree);
    }

    //Create a new template with an empty fieldTree, with the specified name
    static createNew(name) {
        
        const newTemplate = new Template();
        
        newTemplate.name = name;
        newTemplate.type = "Template";
        
        newTemplate.fieldTree = {type: "FieldGroup", name: "BaseFieldGroup", isBase: true, children: []};
        newTemplate.fieldCalculations = [];
        
        return newTemplate;
    }
    
    
    
    //Find a field in the Template field tree by name. The tree is assumed to be for the Template, so that the CloningFieldGroup will have a template, and no children.
    //If the field is found, it is returned, otherwise null is returned
    static findFieldByName(field, name) {
        
        if (field.name === name)
            return field;
        
        switch (field.type) {

            case "FieldGroup":
            case "PagedFieldGroup":
               for (let child of field.children) {   //search the children
                    const found = Template.findFieldByName(child, name);
                    if (found)
                        return found;
               }
               return null;  //not found in children
               
            case "CloningFieldGroup":

                if (field.template)
                    return Template.findFieldByName(field.template, name);  //search the template
                else
                    return null;
   
            default: //do nothing for other types
                return null;
        }
    }
    
    
    //Find the field in the Template field tree that is the parent of the specified field. The tree is assumed to be for the Template, so that the CloningFieldGroup will have a template, and no children.
    //If the parent is found, returns {parent, index} where the parent is the parent field and the index is the index into the children array of the field to find, which
    //in the case of the CloningFieldGroup will always be 0.  If not found, returns null.
    static parentOfField(baseField, field) {
                
        switch (baseField.type) {

            case "FieldGroup":
            case "PagedFieldGroup":

               let index = 0;
               for (let child of baseField.children) {   //search the direct children
                    if (child.name === field.name)
                        return {parent: baseField, index: index};
                    
                    index++;
               }
               for (let child of baseField.children) {   //recurse into the children
                    const result = Template.parentOfField(child, field);
                    if (result)
                        return result;
               }
               return null;  //not found in children
               
            case "CloningFieldGroup":
                if (baseField.template.name === field.name)
                    return {parent: baseField, index: 0};
                
                return Template.parentOfField(baseField.template, field);  //search the template
   
            default: //do nothing for other types
                return null;
        }
        
    }
    
    
    
    //Create a general, non-PoolPass sync'ed Application field of the specified type, with a unique name
    static createNewField(baseField, type) {
                
        //Create a new, unique name for a new field of the specified type. Will have the form such as TextField2    
        const baseName = type.charAt(0).toLowerCase() + type.slice(1);  //uncapitalize
        let index = 0;
        let name;
        do {
            index++;
            name = baseName + index;  
        } while (Template.findFieldByName(baseField, name) !== null);
                
        const newField = {type: type, 
                          name: name,
                          label: name + " Label",
                          required: false,
                          instructions: name + " instructions",
                          modifiable: false,                            //True if Processor can modify
                          merged: false,
                          patronData: null,
                          isPPSyncField: false};
        
        
        switch (type) {
            case "TextField":
            case "EmergencyContactField":
            case "EmailField":
                return {...newField, maxChars: 250, numLines: 1};

            case "AddressField":
                    return {...newField, maxChars: 250, splitAddressLine: false, includeCountry: false};

            case "NumericField":
                return {...newField, decimalPlaces: 0};
                
            case "DateField":
                return {...newField, minDate: null, maxDate: null, mustBeInPast: false, mustBeInFuture: false};
                    
            case "ImageField":
                return {...newField, aspectRatio: 1.0};
                   
            case "SelectField":
                return {...newField, selectableItems: ["Choice1", "Choice2"], displayAsList: false};
                                
            case "FieldGroup":
            case "PagedFieldGroup":
                return {...newField, children: []};
                
            case "CloningFieldGroup":
                return {...newField, template: null, children: [], minChildren: 0, maxChildren: null};

            case "RichTextHtmlField":
                return {...newField, maxChars: 32768, instructions: null, label: null};

            case "SignatureField":
            case "CalcResultField":                
            case "CheckboxField":
            case "DocumentField":
                return newField;

            default:
                console.error("Unknown Field Type");
                return null;
        }
        
        
    }
        
    
    static createPatronsGroup(baseField) {
        
        //Check to see what already exists
        let existingCloningGroup = Template.findFieldByName(baseField, Template.PATRONS_GROUP_NAME);
        if (existingCloningGroup && existingCloningGroup.type !== "CloningFieldGroup")
            throw new Error("There is already an existing field named \"" + Template.PATRONS_GROUP_NAME + "\" but it is not the correct type (must be CloningFieldGroup)");
      
        if (existingCloningGroup && !existingCloningGroup.isPPSyncField)
            throw new Error("There is already an existing field named \"" + Template.PATRONS_GROUP_NAME + "\" but it does not sync with PoolPass)");
                    
        if (existingCloningGroup && existingCloningGroup.template)
            throw new Error("The field \"" + Template.PATRONS_GROUP_NAME + "\" already has an existing template for cloning");
       
                    
                     
        //Create the template
        const patronGroup = {type: "FieldGroup", 
                             name: Template.PATRON_GROUP_NAME,
                             label: "Patron",
                             required: false,                           
                             instructions: "Enter the information for this patron",
                             modifiable: false,
                             patronData: null,
                             isPPSyncField: true,
                             children: []};
        
        
        //Nothing exists, create the CloningFieldGroup
        if (!existingCloningGroup)
            existingCloningGroup = {type: "CloningFieldGroup",
                                    name: Template.PATRONS_GROUP_NAME,
                                    instructions: "Keep Pressing + to add the number of patrons in your family",
                                    isPPSyncField: true,
                                    label: "Patrons",
                                    maxChildren: null,
                                    minChildren: null,
                                    children: [],
                                    modifiable: false,
                                    required: false
                                   };
                   
        //Assign the template
        existingCloningGroup.template = patronGroup;
        
        //Create the Member children

           
        for (let textFieldName of Template.memberTextFieldNames) {
            if (Template.findFieldByName(baseField, textFieldName.name))
                throw new Error("There is already an existing field named \"" + textFieldName.name + "\"");
        }
     
        let memberFields = [];
     
        for (let textFieldName of Template.memberTextFieldNames) {
            let newField = Template.createNewField(baseField, "TextField");
            newField.name = textFieldName.name;
            newField.label = textFieldName.label;
            newField.required = textFieldName.required;
            newField.instructions = "Enter the Member " + newField.label;
            newField.modifiable = true;
            newField.isPPSyncField = true;
            memberFields.push(newField);
        }
        
        
        if (Template.findFieldByName(baseField, Template.PATRON_BIRTHDATE_FIELD_NAME))
            throw new Error("There is already an existing field named \"" + Template.PATRON_BIRTHDATE_FIELD_NAME + "\"");
        
        let birthdateField = Template.createNewField(baseField, "DateField");
        birthdateField.name = Template.PATRON_BIRTHDATE_FIELD_NAME;
        birthdateField.label = "Member Birthdate";
        birthdateField.instructions = "Select the Member Birthdate";
        birthdateField.modifiable = true;
        birthdateField.isPPSyncField = true;
        birthdateField.mustBeInPast = true;
        memberFields.push(birthdateField);
        
        if (Template.findFieldByName(baseField, Template.PATRON_IMAGE_FIELD_NAME))
            throw new Error("There is already an existing field named \"" + Template.PATRON_IMAGE_FIELD_NAME + "\"");
        
        let imageField = Template.createNewField(baseField, "ImageField");
        imageField.name = Template.PATRON_IMAGE_FIELD_NAME;
        imageField.label = "Member Image";
        imageField.instructions = "Select the Member Image";
        imageField.modifiable = true;
        imageField.aspectRatio = Template.MEMBER_ASPECT_RATIO;
        imageField.isPPSyncField = true;
        memberFields.push(imageField);
        
        if (Template.findFieldByName(baseField, Template.PATRON_PRINT_PASS_SELECTION))
            throw new Error("There is already an existing field named \"" + Template.PATRON_PRINT_PASS_SELECTION + "\"");
        
        let printField = Template.createNewField(baseField, "SelectField");
        printField.name = Template.PATRON_PRINT_PASS_SELECTION;
        printField.label = "Pass Type";
        printField.instructions = "Does the Patron need an initial pass, replacement pass, or reactivated pass?";
        printField.modifiable = true;
        printField.patronData = "Initial";
        printField.selectableItems = ["Initial", "Replacement", "Reactivated"];
        printField.isPPSyncField = true;
        memberFields.push(printField);
        
 
        patronGroup.children = memberFields;
        
        
            
        return existingCloningGroup;
    }
    
    
    static createPPMembershipFields(baseField) {
        
     
        for (let textFieldName of Template.membershipTextFieldNames) {
            if (Template.findFieldByName(baseField, textFieldName.name))
                throw new Error("There is already an existing field named \"" + textFieldName.name + "\"");
        }
     
        let membershipFields = [];
     
        for (let textFieldName of Template.membershipTextFieldNames) {
            let newField = Template.createNewField(baseField, textFieldName.type);
            newField.name = textFieldName.name;
            newField.label = textFieldName.label;
            newField.instructions = "Enter the Membership " + newField.label;
            newField.modifiable = true;
            newField.required = textFieldName.required;
            newField.isPPSyncField = true;
            membershipFields.push(newField);
        }
        
        if (Template.findFieldByName(baseField, "type"))
            throw new Error("There is already an existing field named \"type\"");
        //Type Field is Select
        let typeField = Template.createNewField(baseField, "SelectField");
        typeField.name = "type";
        typeField.label = "Membership Type";
        typeField.instructions = "Select the Membership Type";
        typeField.modifiable = true;
        typeField.isPPSyncField = true;
        typeField.selectableItems = ["Single", "Family"];
        membershipFields.push(typeField);
        
        
        return membershipFields;
        
    }
    
   
    
    //This function must return an array of available fields, each item in the array having a label, icon, and a callback function.  The callback function should
    //(a) create one or more new fields and (b) call the provided insertFunction to insert the array of new fields into the Template 
    //baseField is assumed to be the root of the Template field tree
    static getAvailableFields(baseField, insertFunc) {
          
        return [{label: "Text Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "TextField")] )},   //array of one field
                {label: "Address Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "AddressField")] )},   //array of one field
                {label: "Email Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "EmailField")] )},   //array of one field
                {label: "Numeric Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "NumericField")] )},   //array of one field
                {label: "Date Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "DateField")] )},   //array of one field
                {label: "Select Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "SelectField")] )},   //array of one field
                {label: "Checkbox Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "CheckboxField")] )},   //array of one field
                {label: "Image Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "ImageField")] )},   //array of one field
                {label: "Document Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "DocumentField")] )},   //array of one field
                {label: "Signature Field", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "SignatureField")] )},   //array of one field
                {label: "Rich Text Label", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "RichTextHtmlField")] )},   //array of one field
                null,
                {label: "Group", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "FieldGroup")] )},        //array of one field
                {label: "Paged Group", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "PagedFieldGroup")] )},        //array of one field
                {label: "Cloning Group", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "CloningFieldGroup")] )},   //array of one field
                null,
                {label: "PP Membership Fields", icon: null, selectCallback: () => insertFunc(Template.createPPMembershipFields(baseField))},   //array of all Membership fields
                {label: "Patrons Group", icon: null, selectCallback: () => insertFunc( [Template.createPatronsGroup(baseField)] )},     //array of one field
                null,
                {label: "Calculation Result", icon: null, selectCallback: () => insertFunc( [Template.createNewField(baseField, "CalcResultField")] )}
               ];
    }
    
    
    
    
    //This function checks the field and all its children against every field under the baseField tree to see if any names are the same.  If it finds one,
    //it modifies the field's (or field's descendant's) name and returns true - indicating a duplicate was found. This function should be called continually
    //until it returns false - indiciating no duplicates were found
    static makeFieldNamesUnique(baseField, field) {
        
        const foundField = Template.findFieldByName(baseField, field.name);
        if (foundField !== null) {
            field.name = "CopyOf" + field.name;
            return true;
        }
        
        switch (field.type) {

            case "FieldGroup":
            case "PagedFieldGroup":
               for (let child of field.children) {   //search the children
                    if (Template.makeFieldNamesUnique(baseField, child))
                        return true;
               }
               return false;
               
            case "CloningFieldGroup":

                if (field.template && Template.makeFieldNamesUnique(baseField, field.template))  //search the template
                    return true;
                else
                    return false;
   
            default: //do nothing for other types
                return false;
        }
        
        
    }


    //This function is called to process all Template fields prior to saving, if a field has a need to modify itself prior to uploading, it does it here
    static processFieldsForUpload(field) {
    
        switch (field.type) {
            case "CloningFieldGroup":
            case "FieldGroup":
            case "PagedFieldGroup":

                if (field.children)
                for (let child of field.children) {
                    if (Template.processFieldsForUpload(child))
                        return true;
                }
                break;
    
            case "RichTextHtmlField":       //This field stores the editor data in draftjs format. If this field is merged, we need the html on the backend, so add it here
                if (field.merged && field.patronData) {
                    field.html = draftContentToHtml(field.patronData);
                }
                break;
     
            default: 
                return;
        }
    }
    
    
}