import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { Tooltip, Grid, Typography, Checkbox, Paper, TextField, Button, Collapse, FormControl, RadioGroup, FormControlLabel, Radio  } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeColors } from '../Theme'
import { Community } from '../models/Community'
import { Global } from '../models/Global'

import { RestComponent } from 'react-frontend-utils' 
import { OpenInNewTab } from '../App'

import { DateUtils, SummaryWidget, ManageTextField, ManageDateField, ListSelectPopover, ManageNumericField, ManageTimeField, ColorPicker, RichTextEditor } from 'react-frontend-utils'



export class CommunityPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 5,
            marginBottom: 30,
            color: 'gray',
            fontSize: '14pt',
            flexGrow: 1
        },
        paper: {
            padding: 10
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 100,
            float: 'right',
            textTransform: 'uppercase',
            padding: 5, 
            marginRight: 10,
            marginTop: 2,
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
            marginBottom: 20
        },
        containerLabel: {
            fontSize: 14, 
            color: ThemeColors.darkGray, 
            marginLeft: 5
        },
        sectionLabel: {
            fontSize: 16, 
            color: 'black', 
            marginLeft: 5
        },
        viewLabel: {
            fontSize: 12, 
            color: 'gray', 
            marginLeft: 5
        }
    };
    

    _communities = [];                              //List of Community objects
    _templates = [];                                //List of Template names                       

    constructor(props) {
        super(props);
        this.state.communityNames = [];                 //All available community names
        this.state.selectedCommunityName = "";        //The currently displayed community name (use empty string, Autocomplete barfs on null 
        this.state.selectedCommunityNameValue = "";       
        this.state.creatingNew = false;
        
        this.state.selectedCommunity = null;        //The currently selected Community Object  
        
        this.state.communityToModify = null;        //The Community object being modified for upsert
        
        this.state.showDatabaseSelect = false;      //Popover to select a database is open
        this.state.availableDatabases = [];         //List of available databases to use for new Community (databases the user can see, less the ones that already have Communities)

        this.state.templateName = "";
        this.state.altTemplateName = "";
        this.state.internalTemplate = "";
        
        this.state.aggregate = null;                    //Collection of counts for Community statuses
        
        this.state.preferredDateFormat = "US";

        this.state.showPPSyncFields = false;
        this.state.newMembershipExpirationDateType = "Calendar";         //"Calendar" = calendar date, "DeltaNow" = delta from process date in days
        this.state.existingMembershipExpirationDateType = "NoChange";    //"NoChange", "Calendar", "DeltaPrevious", "DeltaNow"

        this.state.existingMembershipGuestPassesType = "NoChange";    //"NoChange", "Increment", "Set"

        this.state.existingMemberEntryPassesType = "NoChange";          //"NoChange", "Increment", "Set"
    
        this.state.newMemberBarcodeMode = "None";                     //"None", Next_Numeric, Random_Numeric, Random_Alphanumeric;

        this.state.ePassesEnabled = null;           //Fetch after selecting community, to get true or false

        this.state.serviceLevel = "selfService";

    }
    
    
   
    componentDidMount() {
        super.componentDidMount();
        this._fetchAll();
    }
    
    //Fetch all Templates and Communities
    _fetchAll = () => {
        this.setState({creatingNew: false, selectedCommunity: null, ePassesEnabled: null, templateName: null, altTemplateName: null,
                       selectedCommunityName: "", communityToModify: null, selectedCommunityNameValue: ""}); 
        this._fetchCommunities(); 

        if (Global.isAGSAdmin())
            this._fetchTemplates();
    }
    
    
    _fetchCommunities = () => {
        
        this.incrementBusy();
        this.secureJSONFetch("/ap/groups", {},
                             this._fetchCommunitiesCallback, this._fetchErrorCallback); 
        
    }
    
    _fetchTemplates = () => {
        
        this.incrementBusy();
        this.secureJSONFetch("/ap/templates", {},
                             this._fetchTemplatesCallback, this._fetchErrorCallback); 
        
    }
   
    
    //Callback for fetching Communities - response is a list of Community objects
    _fetchCommunitiesCallback = (response) => {
        if (response) {            
            this._communities = response.map(community => new Community(community));
            
            const communityNameList = this._communities.map(community => community.name);
            const ag = Community.aggregate(this._communities);

            this.setState({communityNames: communityNameList, aggregate: ag});
        }            
        this.decrementBusy();
    }
    
    //Callback for fetching Templates - response is a list of Template objects
    _fetchTemplatesCallback = (response) => {
        if (response) {            
            this._templates = response.map(template => template.name);
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
    _preview = (test, support) => {
        if (this.state.communityToModify) {
            let url = "//" + window.location.hostname + ":" + window.location.port + "/patron?group=" + this.state.communityToModify.name;
            if (test)
                url += "&test=" + this.state.communityToModify.testingKey;
            if (support)
                url += "&support=true";

            OpenInNewTab(url);           
        }
    }

    _previewInternal = (test) => {
        if (this.state.communityToModify) {
            let url = "//" + window.location.hostname + ":" + window.location.port + "/community?group=" + this.state.communityToModify.name + "&support=true";
            if (test)
                url += "&test=" + this.state.communityToModify.testingKey;

            OpenInNewTab(url);           
        }
    }
  
  
    _delete = () => {
        if (this.state.communityToModify) {
            this.showConfirmAlert("Confirm", 'Really Delete Community "' + this.state.communityToModify.name + '"? This will not delete existing Applications, but no new Applications can be submitted for this community.',
                                  'black', "Cancel", this._deleteCommunity, "Delete", 'red');      
        }
    
    }
    
    _deleteCommunity = () => {
    
        if (this.state.communityToModify) {
            this.incrementBusy();
            this.secureJSONFetch("/ap/groups/" + this.state.communityToModify.name, {method: "DELETE"},
                             this._deleteCallback, this._fetchErrorCallback); 
            
            
        }
    }
    
    _deleteCallback = () => {
        this.showConfirmAlert("Success", "Community \"" + this.state.communityToModify.name + "\" deleted" , 'green'); 
        this.decrementBusy();
        this._fetchAll();
    }
  
  
    //Post the current community to the server for upserting
    _save = () => {
        if (this.state.communityToModify) {

            //Make a deep copy so we can prepare it for posting without changing our state
            const communityToPost = Community.copyForPosting(this.state.communityToModify, true);
        
            communityToPost.prepareForPost(this.state.preferredDateFormat, 
                                            this.state.newMembershipExpirationDateType, 
                                            this.state.existingMembershipExpirationDateType, 
                                            this.state.existingMembershipGuestPassesType, 
                                            this.state.existingMemberEntryPassesType,
                                            this.state.newMemberBarcodeMode,
                                            this.state.serviceLevel);
        
            console.log("Modifying Community: ", communityToPost);
        
            this.incrementBusy();
            this.secureJSONFetch("/ap/groups", {method: "POST", body: JSON.stringify(communityToPost)},
                                 this._saveCallback, this._fetchErrorCallback); 
            
            
        }
    }
    
    
    _saveCallback = () => {
        this.showConfirmAlert("Success", "Community \"" + this.state.communityToModify.name + "\" " + (this.state.creatingNew ? "created" : "updated") , 'green'); 
        this.decrementBusy();
        this._fetchAll();
    }
  
 
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date/Time Field \"" + label + "\"", error, 'red');
    }
    
    
    //Callback when a Community is selected from the available list, display the selected community
    _selectCommunity = (event, newValue) => { 

        const selectedCommunityName = newValue;

        const c = this._communities.find(c => c.name === selectedCommunityName);
        if (!c)
            return;
        
        
        //Make a copy of the selected community to be posted back to the server.  In the managed fields, we use the initial value as the value from the 
        //selectedCommunity, but changes are made to the communityToModify. Most fields are the same type, but the selectedCommunity we use Date objects
        //and the communityToModify we use JsonDateString for posting back to the server.
        const communityToModify = Community.copyForPosting(c);
        
  
        this.setState({selectedCommunityName: selectedCommunityName, 
                       selectedCommunityNameValue: selectedCommunityName, 
                       selectedCommunity: c, 
                       communityToModify: communityToModify, 
                       creatingNew: false,
                       preferredDateFormat: c.preferredDateFormat,
                       showPPSyncFields: c.hasPPSync,
                       newMembershipExpirationDateType: c.newMembershipExpirationDateType(),
                       existingMembershipExpirationDateType: c.existingMembershipExpirationDateType(),   
                       existingMembershipGuestPassesType: c.existingMembershipGuestPassesType(),
                       existingMemberEntryPassesType: c.existingMemberEntryPassesType(),
                       newMemberBarcodeMode: c.newMemberBarcodeMode(),
                       ePassesEnabled: null,
                       serviceLevel: c.serviceLevel()
                     }); 

        this.incrementBusy();
        this.secureJSONFetch("/ap/groups/" + selectedCommunityName + "/checkEPassesEnabled", {},
                            this._checkEPassesCallback, this._checkEPassesErrorCallback); 
                     
    }
    

    _checkEPassesCallback = (response) => {
        this.decrementBusy();
        console.log(response)
        this.setState({ePassesEnabled: true});
    }

    _checkEPassesErrorCallback = () => {
        this.decrementBusy();
        this.setState({ePassesEnabled: false});
    }
    
    //Callback when a database is selected from the popover for a new Community, create the new Community from the name and hide the popup
    _databaseSelected = (name) => {
        
        const newCommunity = Community.createNew(name);
        const communityToModify = Community.copyForPosting(newCommunity);

        console.log(communityToModify)

        this.setState({selectedCommunity: newCommunity, 
                      communityToModify: communityToModify,  
                      preferredDateFormat: newCommunity.preferredDateFormat, 
                      creatingNew: true, 
                      showDatabaseSelect: false,
                      showPPSyncFields: newCommunity.hasPPSync,
                      newMembershipExpirationDateType: newCommunity.newMembershipExpirationDateType(),
                      existingMembershipExpirationDateType: newCommunity.existingMembershipExpirationDateType(),   
                      existingMembershipGuestPassesType: newCommunity.existingMembershipGuestPassesType(),
                      existingMemberEntryPassesType: newCommunity.existingMemberEntryPassesType(),
                      newMemberBarcodeMode: newCommunity.newMemberBarcodeMode(),
                      serviceLevel: newCommunity.serviceLevel()
                    });    
    }
    
    
    //When New button pressed, find databases that could have a new Community created for, and either show an error if none available, or show popup for 
    //user to select a database
    _createNew = () => {
        
        //See if there are any available databases that aren't already configured.  
        const availableDatabases = Global.user.databases.filter(database => {
            
            //See if our list of communities already includes the database
            const found = this._communities.find(c => c.name === database);
            
            return !found;  //if found, exclude from filtered list, otherwise include
        });
                
        if (availableDatabases.length === 0) {
            this.showConfirmAlert("No available databases", "No databases exist that do not already have a configured Community", 'red');
            return;
        }   
        else
            this.setState({availableDatabases: availableDatabases, showDatabaseSelect: true});

    }

    
    //Select Database Popover cancelled
    _cancelNew = () => {
        this.setState({selectedCommunity: null, creatingNew: false, showDatabaseSelect: false});            
    }
    
    //A field in the Community was changed, update the selected Community object with the new data
    _communityFieldChanged = (fieldName, userValue) => {

        this.state.communityToModify[fieldName] = userValue;  
        
    }
    
    _tooltipForCount = (nameList, title) => {
        
        const spliceList = [...nameList];  //shallow copy
        const matrix = [];
        while (spliceList.length) 
            matrix.push(spliceList.splice(0,3));
        
        return  <div>
                    <Typography variant='caption' style={{borderBottom: '1px solid white'}}>{title}</Typography>
                    <table>
                        <tbody>
                        {matrix.map(row => <tr>
                                                <td style={{fontSize: 13, paddingRight: 10}}>{row[0]}</td>
                                                <td style={{fontSize: 13, paddingRight: 10}}>{row[1]}</td>
                                                <td style={{fontSize: 13}}>{row[2]}</td>
                                           </tr>
                                   )}
                        </tbody>
                    </table>
                </div>;
    }
    
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
        const buttonStyle = (activeColor, disabled) => {
            
            let color = disabled ? 'lightGray' : activeColor;
            return {borderColor: color, color: color, maxWidth: 100, textAlign: 'center'};
        };
       
        const showCommunity = this.state.selectedCommunityName || this.state.creatingNew ? true : false;
        const canCreateNew = this.state.creatingNew || this.state.selectedCommunity ? false : Global.isAGSAdmin();
        const canDelete = this.state.creatingNew || !this.state.selectedCommunity ? false : Global.isAGSAdmin();
        const canPreview = this.state.creatingNew || !this.state.selectedCommunity ? false : true;
                   
        const startDate = this.state.selectedCommunity && this.state.selectedCommunity.acceptStartDate ? DateUtils.dateFormat(this.state.selectedCommunity.acceptStartDate) : "Anytime";   
        const endDate = this.state.selectedCommunity && this.state.selectedCommunity.acceptEndDate ? DateUtils.dateFormat(this.state.selectedCommunity.acceptEndDate) : "Never";   

        
        //An object with a status string and background color and a tooltip
        const status = this.state.selectedCommunity ? this.state.selectedCommunity.status() : null;     
              
        let ePassStatus = "ePasses:";
        let ePassTextColor = 'black';
        if (this.state.ePassesEnabled === true) {
            ePassStatus = "ePasses: enabled";
            ePassTextColor = 'green';
        }
        else if (this.state.ePassesEnabled === false) {
            ePassStatus = "ePasses: disabled";
            ePassTextColor = 'red';
        }

        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                
                <Grid container direction="row" spacing={2} style={{padding: 10, marginBottom: 20}}>
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Active" 
                                value={this.state.aggregate ? this.state.aggregate.accepting.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.accepting.names : [], "Communities actively accepting Applications")}
                                tooltipWidth='100%'
                                borderColor={Community.Status.ACCEPTING.color}/>
                    </Grid>                  
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Waiting" 
                                value={this.state.aggregate ? this.state.aggregate.waiting.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.waiting.names : [], "Communities that have not started accepting Applications")}
                                tooltipWidth='100%'
                                borderColor={Community.Status.WAITING.color}/>
                    </Grid>         
                    <Grid item sm={3} xs={6}>
                        <SummaryWidget label="Finished" 
                                value={this.state.aggregate ? this.state.aggregate.finished.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.finished.names : [], "Communities no longer accepting Applications")}
                                tooltipWidth='100%'
                                borderColor={Community.Status.FINISHED.color}/>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                       <SummaryWidget label="Inactive" 
                                value={this.state.aggregate ? this.state.aggregate.inactive.count : "..."}
                                tooltip={this._tooltipForCount(this.state.aggregate ? this.state.aggregate.inactive.names : [], "Inactive communities with no assigned Template")}
                                tooltipWidth='100%'
                                borderColor={Community.Status.INACTIVE.color}/>
                    </Grid>
                </Grid>
                
                
                <ListSelectPopover isOpen={this.state.showDatabaseSelect} okCallback={this._databaseSelected} cancelCallback={this._cancelNew}
                                   title="Select Database" items={this.state.availableDatabases}/>
                
                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'left', alignItems: 'center'}}>
                    <Autocomplete
                        size='small'
                        disabled={this.state.selectedCommunity !== null}
                        style={{width: '35%'}}
                        value={this.state.selectedCommunityName}
                        onChange={this._selectCommunity}
                        inputValue={this.state.selectedCommunityNameValue}
                        onInputChange={(event, newValue) => { this.setState({selectedCommunityNameValue: newValue}); }}
                        options={this.state.communityNames}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Community" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />            
                
                    <Tooltip title="Save Changes and Refresh">
                        <Button disabled={!showCommunity} fullWidth size='small' style={buttonStyle('green', !showCommunity)} 
                                onClick={this._save} variant="outlined" component="label">
                            Save
                        </Button>
                    </Tooltip>                    
                    <Tooltip title="Discard Changes and Clear">
                        <Button disabled={!showCommunity} fullWidth size='small' style={buttonStyle('black', !showCommunity)} 
                                onClick={this._fetchAll} variant="outlined" component="label">
                            Discard
                        </Button>
                    </Tooltip>
                    <Tooltip title="Create a new Community">
                        <Button disabled={!canCreateNew} fullWidth size='small' style={buttonStyle(ThemeColors.addColor, !canCreateNew)}
                        onClick={this._createNew} variant="outlined" component="label">
                            New
                        </Button>
                    </Tooltip>
                    
                    <Tooltip title="Delete Community">
                        <Button disabled={!canDelete} fullWidth size='small' style={buttonStyle('red', !canDelete)}
                        onClick={this._delete} variant="outlined" component="label">
                            Delete
                        </Button>
                    </Tooltip>
                     
                    {this.state.isBusy ? this.getBusyComponent('right', {marginLeft: 20}, 30) : null}

                </div>
                
                <div style={{marginTop: 15}}/>
                
                {this.state.selectedCommunity ?

                    <Fragment>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div>
                                <Typography variant="body2" style={{...this.styles.viewLabel}}>Preview</Typography>            
                                <div style={{...this.styles.roundedContainer, display: 'flex', flexWrap: 'wrap', gap: 20, paddingLeft: 20, paddingRight: 20}}>  
                                    <Tooltip title="Open the Application form in live mode, in a new window">
                                        <Button disabled={!canPreview} size='small'
                                                onClick={() => this._preview(false, false)} variant="outlined" component="label">
                                            Live Application
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Open the Application form in test mode, in a new window">
                                        <Button disabled={!canPreview} size='small'
                                                onClick={() => this._preview(true, false)} variant="outlined" component="label">
                                            Test Application
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Open the Support form in live mode, in a new window">
                                        <Button disabled={!canPreview} size='small'
                                                onClick={() => this._preview(false, true)} variant="outlined" component="label">
                                            Live Support
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Open the Support form in test mode, in a new window">
                                        <Button disabled={!canPreview} size='small'
                                        onClick={() => this._preview(true, true)} variant="outlined" component="label">
                                            Test Support
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Open the Internal Support form in live mode, in a new window">
                                        <Button disabled={!canPreview} size='small'
                                                onClick={() => this._previewInternal(false)} variant="outlined" component="label">
                                                    Live Internal
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Open the Internal Support form in test mode, in a new window">
                                        <Button disabled={!canPreview} size='small'
                                                onClick={() => this._previewInternal(true)} variant="outlined" component="label">
                                                    Test Internal
                                        </Button>
                                    </Tooltip>                                   
                                </div>
                            </div>
                        </div>

                        <Paper style={this.styles.paper}>   
                            
                            <Tooltip title={status.tooltip}>
                                <div style={{...this.styles.status, backgroundColor: status.color}}>
                                        {status.label}
                                </div>
                            </Tooltip>

                            <Typography style={this.styles.paperLabel} variant="h6">{"Editing \"" + this.state.selectedCommunity.name + "\""}</Typography> 
                            
                            <Grid container spacing={2}>

                                <Grid item xs={12} lg={5}>
                                    
                                    <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Community Info</Typography>            
                                    <div style={this.styles.roundedContainer}>

                                        <ManageTextField label="Extended Name for Patron Application *"
                                            initialValue={this.state.selectedCommunity.extendedName}
                                            style={{marginTop: 20}}
                                            json="extendedName"  
                                            autoAccept={true}
                                            onFieldChange={this._communityFieldChanged}/>

                                        <div style={{display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'space-between', marginTop: 20, marginLeft: 10}}>
                                            <Typography variant="body1" >{"Community Currency: " + this.state.selectedCommunity.isoCurrency}</Typography>                  
                                            <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>Save and Refresh to update</Typography>                  
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'space-between', marginTop: 20, marginLeft: 10}}>
                                            <Typography variant="body1" >{"Account Number: " + this.state.selectedCommunity.accountNo}</Typography>                  
                                            <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>Save and Refresh to update</Typography>                  
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'space-between', marginTop: 20, marginLeft: 10}}>
                                            <Typography variant='body1' style={{color: ePassTextColor}}>{ePassStatus}</Typography>                        
                                            {this.state.ePassesEnabled == null ? <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>Save and Refresh to update</Typography> : null}                 
                                        </div>

                                        <ManageTextField label="Community URL"
                                                        initialValue={this.state.selectedCommunity.url}
                                                        style={{marginTop: 20}}
                                                        json="url"  
                                                        editable={Global.isAGSAdmin()}
                                                        autoAccept={true}
                                                        onFieldChange={this._communityFieldChanged}/>
                                    </div>


                                </Grid>

                                <Grid item xs={12} lg={7}>
                                    
                                    <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Settings</Typography>            
                                    <div style={this.styles.roundedContainer}>
                                        
                                        <Autocomplete
                                            size='small'
                                            style={{width: '100%', marginTop: 20}}
                                            value={this.state.communityToModify.assignedTemplate ? this.state.communityToModify.assignedTemplate : ""}
                                            onChange={(event, newValue) => { this.state.communityToModify.assignedTemplate = newValue; }}
                                            inputValue={this.state.templateName}
                                            onInputChange={(event, newValue) => { this.setState({templateName: newValue}) }}
                                            options={this._templates}
                                            blurOnSelect
                                            disabled={!Global.isAGSAdmin()}
                                            renderInput={(params) => <TextField {...params} label="Application Template" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                        /> 
                                        <Autocomplete
                                            size='small'
                                            style={{width: '100%', marginTop: 20}}
                                            value={this.state.communityToModify.alternateTemplate ? this.state.communityToModify.alternateTemplate : ""}
                                            onChange={(event, newValue) => { this.state.communityToModify.alternateTemplate = newValue; }}
                                            inputValue={this.state.altTemplateName}
                                            onInputChange={(event, newValue) => { this.setState({altTemplateName: newValue}) }}
                                            options={this._templates}
                                            blurOnSelect
                                            disabled={!Global.isAGSAdmin()}
                                            renderInput={(params) => <TextField {...params} label="Support Template" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                        /> 
                                        <Autocomplete
                                            size='small'
                                            style={{width: '100%', marginTop: 20}}
                                            value={this.state.communityToModify.internalTemplate ? this.state.communityToModify.internalTemplate : ""}
                                            onChange={(event, newValue) => { this.state.communityToModify.internalTemplate = newValue; }}
                                            inputValue={this.state.internalTemplateName}
                                            onInputChange={(event, newValue) => { this.setState({internalTemplateName: newValue}) }}
                                            options={this._templates}
                                            blurOnSelect
                                            disabled={!Global.isAGSAdmin()}
                                            renderInput={(params) => <TextField {...params} label="Internal/Escalate Template" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                        />    
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5}}>
                                            <Checkbox defaultChecked={this.state.selectedCommunity.supportReqAcceptedAlways} color='primary' disabled={!Global.isAGSAdmin()}
                                                      onChange={(event) => { this.state.communityToModify.supportReqAcceptedAlways = event.target.checked; }}/>                                  
                                            <Typography variant='body1' align='left'>Allow Support Request Submissions Anytime</Typography>                        
                                        </div> 
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5}}>
                                            <Checkbox defaultChecked={this.state.selectedCommunity.mergedDocumentEnabled} color='primary' disabled={!Global.isAGSAdmin()}
                                                      onChange={(event) => { this.state.communityToModify.mergedDocumentEnabled = event.target.checked; }}/>                                  
                                            <Typography variant='body1' align='left'>Enable Merged Documents</Typography>                        
                                        </div> 

                                        <div style={{marginLeft: 10, marginTop: 10, marginBottom: 15}}>
                                            <Typography variant="body2" style={{...this.styles.containerLabel, marginTop: 20}}>Service Level</Typography>   
                                            <FormControl component="fieldset" >
                                                <RadioGroup name="fullservice" value={this.state.serviceLevel} onChange={(event) => {this.setState({serviceLevel: event.target.value});}}>

                                                    <FormControlLabel key={1} disabled={!Global.isAGSAdmin()} value="selfService" control={<Radio color="primary" />} label={"Self Service"} />
                                                    <FormControlLabel key={2} disabled={!Global.isAGSAdmin()} value="fullService" control={<Radio color="primary" />} label={"Full Service"} />

                                                </RadioGroup>
                                            </FormControl>  
                                        </div>

                                        <ManageTextField label="Webhook"
                                            initialValue={this.state.selectedCommunity.webhook}
                                            style={{marginTop: 10}}
                                            json="webhook"  
                                            tooltip="A URL to send a POST request to when a new Application is submitted"
                                            autoAccept={true}
                                            editable={Global.isAGSAdmin()}
                                            onFieldChange={this._communityFieldChanged}/>

                                        <div style={{display: 'flex', gap: 40, justifyContent: 'left', alignItems: 'center', marginTop: 20}}>

                                            <ManageDateField autoAccept={true} hasNever={true} json="acceptStartDate"
                                                            initialValue={startDate}
                                                            neverText="Anytime"
                                                            minYear={DateUtils.currentYear()}
                                                            maxYear={DateUtils.currentYear()+50}
                                                            label="Start Accepting Applications" onFieldChange={this._communityFieldChanged} 
                                                            onParseError={this._dateParseError} 
                                                            calendarColor={ThemeColors.calendarColor}/>  
                                                            
                                                                                                    
                                            <ManageTimeField label="Start Time (UTC)"
                                                            initialValue={this.state.selectedCommunity.acceptStartTime}
                                                            json="acceptStartTime"  
                                                            autoAccept={true}
                                                            clockColor={ThemeColors.clockColor}
                                                            onParseError={this._dateParseError} 
                                                            onFieldChange={this._communityFieldChanged} />
                                                        
                                        </div>   
                                        <div style={{display: 'flex', gap: 40, justifyContent: 'left', alignItems: 'center', marginTop: 5}}>
                                                
                                                <ManageDateField autoAccept={true} hasNever={true} json="acceptEndDate" 
                                                                initialValue={endDate}
                                                                neverText="Never"
                                                                minYear={DateUtils.currentYear()}
                                                                maxYear={DateUtils.currentYear()+50}
                                                                label="Stop Accepting Applications" onFieldChange={this._communityFieldChanged} 
                                                                onParseError={this._dateParseError} 
                                                                calendarColor={ThemeColors.calendarColor}/>  
                                                                
                                                <ManageTimeField label="End Time (UTC)"
                                                                initialValue={this.state.selectedCommunity.acceptEndTime}
                                                                json="acceptEndTime"  
                                                                clockColor={ThemeColors.clockColor}
                                                                autoAccept={true}
                                                                onParseError={this._dateParseError} 
                                                                onFieldChange={this._communityFieldChanged} />
                                                                
                                        </div>

                                        <Typography variant="body2" style={{marginTop: 5, marginBottom: 5}}>Custom Text when Applications are not Accepted</Typography>            
                                        <RichTextEditor draftContent={this.state.selectedCommunity.notAcceptingCustomText}     
                                                        editing={true}  
                                                        onChange={ (draftJson) => {
                                                            this._communityFieldChanged("notAcceptingCustomText", draftJson);
                                                        }}/>
                                    </div>

                                </Grid>

                                <Grid item xs={12} lg={5}>
                                    
                                    <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Email and Support</Typography>            
                                    <div style={this.styles.roundedContainer}>
                                        { /**  -- HIDDEN, NOT USED
                                        <ManageTextField label="Support URL"
                                                        initialValue={this.state.selectedCommunity.supportUrl}
                                                        style={{marginTop: 20}}
                                                        json="supportUrl"  
                                                        tooltip="A link added to patron email notifications to open a support ticket. The URL is appended with the Application ID"
                                                        autoAccept={true}
                                                        onFieldChange={this._communityFieldChanged}/>
                                        **/}
                                                        
                                        <ManageTextField label="Admin Email *"
                                                        initialValue={this.state.selectedCommunity.adminEmail}
                                                        style={{marginTop: 20}}
                                                        json="adminEmail"  
                                                        tooltip="Applications flagged to Community are sent to this address"
                                                        autoAccept={true}
                                                        onFieldChange={this._communityFieldChanged}/>

                                        <ManageTextField label="Support Email *"
                                                        initialValue={this.state.selectedCommunity.supportEmail}
                                                        style={{marginTop: 20, marginBottom: 20}}
                                                        json="supportEmail"  
                                                        tooltip="Replies to patron notification emails are sent to this address"
                                                        autoAccept={true}
                                                        onFieldChange={this._communityFieldChanged}/>                                                 
           
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5}}>
                                            <Checkbox defaultChecked={this.state.selectedCommunity.applicationSupportTicketLinkDisabled} color='primary' 
                                                    onChange={(event) => { this.state.communityToModify.applicationSupportTicketLinkDisabled = event.target.checked; }}/>                                  
                                            <Typography variant='body1' align='left'>Disable AGS support link in Application related emails to Patrons</Typography>                        
                                        </div> 

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5}}>
                                            <Checkbox defaultChecked={this.state.selectedCommunity.supportSupportTicketLinkDisabled} color='primary' 
                                                    onChange={(event) => { this.state.communityToModify.supportSupportTicketLinkDisabled = event.target.checked; }}/>                                  
                                            <Typography variant='body1' align='left'>Disable AGS support link in Support related emails to Patrons</Typography>                        
                                        </div> 

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5}}>
                                            <Checkbox defaultChecked={this.state.selectedCommunity.adminReceivesApplicationCopy} color='primary' 
                                                    onChange={(event) => { this.state.communityToModify.adminReceivesApplicationCopy = event.target.checked; }}/>                                  
                                            <Typography variant='body1' align='left'>Send a copy of Submitted Applications to "Admin Email"</Typography>                        
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5}}>
                                            <Checkbox defaultChecked={this.state.selectedCommunity.adminReceivesSupportRequestCopy} color='primary' 
                                                    onChange={(event) => { this.state.communityToModify.adminReceivesSupportRequestCopy = event.target.checked; }}/>                                  
                                            <Typography variant='body1' align='left'>Send a copy of Submitted Support Requests to "Support Email"</Typography>                        
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginBottom: 5}}>
                                            <Checkbox defaultChecked={this.state.selectedCommunity.adminReceivesMergedDocument} color='primary' 
                                                    onChange={(event) => { this.state.communityToModify.adminReceivesMergedDocument = event.target.checked; }}/>                                  
                                            <Typography variant='body1' align='left'>Email Admin copy of Merged Documents</Typography>                        
                                        </div>

                                        <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 10}}>Override Default Email Notifications</Typography>            

                                        {Community.PatronStatusNotification.map((notification, index) => 
                                            <ManageTextField label={notification.label}
                                                        initialValue={this.state.selectedCommunity.emailNotifications[index]}
                                                        style={{marginLeft: 20, marginTop: 8, marginBottom: 8, marginRight: 20}}
                                                        json={notification.label}  
                                                        tooltip={'Overrides the default notification: "' + notification.defaultNotification.replace("CNAME", this.state.selectedCommunity.extendedName) + '"'}
                                                        autoAccept={true}
                                                        onFieldChange={(json, userval) => this.state.communityToModify.emailNotifications[index] = userval}/>  
                                        )}
                                                        
                                    
                                    </div>
                                    
                                </Grid>

                                <Grid item xs={12} lg={7}>
                                    
                                    <Typography variant="body2" style={{...this.styles.sectionLabel, marginTop: 20, marginBottom: 0}}>Preferences</Typography>            
                                    <div style={this.styles.roundedContainer}>
                                        <div style={{marginLeft: 10}}>
                                            <Typography variant="body2" style={{...this.styles.containerLabel, marginTop: 20, marginBottom: 0}}>Preferred Date Format</Typography>            
                                            <FormControl component="fieldset">
                                                <RadioGroup name="preferredDateFormat" value={this.state.preferredDateFormat} onChange={(event) => {this.setState({preferredDateFormat: event.target.value});}}>
                                                    <FormControlLabel key={1} value="US" control={<Radio color="primary" />} label={"US"} />
                                                    <FormControlLabel key={2} value="ISO8601" control={<Radio color="primary" />} label={"International (ISO8601)"} />
                                                </RadioGroup>
                                            </FormControl>  
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 10, marginTop: 20}}>
                                            <ColorPicker initialColor={this.state.selectedCommunity.primaryColor} 
                                                        disableAlpha={true}
                                                        onChange={(color)=> this.state.communityToModify.primaryColor = color.hex}/>
                                            <Typography variant='body1' style={{marginRight: 20}}>Primary Theme Color</Typography>      

                                            <ColorPicker initialColor={this.state.selectedCommunity.backgroundColor} 
                                                        disableAlpha={true}
                                                        onChange={(color)=> this.state.communityToModify.backgroundColor = color.hex}/>
                                            <Typography variant='body1'>Background Theme Color</Typography>                        

                                        </div>

                                    </div>
                                </Grid>

                            </Grid>

                            <div style={{marginTop: 30}}/>

                            <Typography variant="body2" style={this.styles.sectionLabel}>Processor Instructions</Typography>            
                            <div style={this.styles.roundedContainer}>
                                <RichTextEditor draftContent={this.state.selectedCommunity.instructions}     
                                                editing={true}  
                                                onChange={ (draftJson) => {
                                                    this._communityFieldChanged("instructions", draftJson);
                                                }}/>
                            </div>
            
                            <div style={{borderTop: '4px solid ' + ThemeColors.appBarBackground, marginTop: 40}}/>

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginBottom: 20}}>
                                <Checkbox defaultChecked={this.state.selectedCommunity.hasPPSync} color='primary' disabled={!Global.isAGSAdmin()}
                                          onChange={(event) => { this.state.communityToModify.hasPPSync = event.target.checked;
                                                                this.setState({showPPSyncFields: event.target.checked});
                                                                }}/>                                  
                                <Typography variant='body1' align='left'>Synchronizes with Membership Portal</Typography>                        
                            </div>    
                            
                            <Collapse in={this.state.showPPSyncFields}>
                            
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: 5, marginLeft: 0}}>
                                        <Tooltip title="If not selected, Applications can only sync with existing Memberships">
                                            <Checkbox defaultChecked={this.state.selectedCommunity.allowsNewMemberships} color='primary' 
                                                      onChange={(event) => { this.state.communityToModify.allowsNewMemberships = event.target.checked;}}/>   
                                        </Tooltip>
                                        <Typography variant='body1' align='left'>Allow Creating New Memberships</Typography>                        
                                    </div> 

                                {/*-----------------------------------------NEW MEMBERSHIP EXPIRATION DATE --------------------------------------------*/null}
            
                                <Typography variant="body2" style={this.styles.containerLabel}>New/Replaced Memberships Expiration Date</Typography>            
                                <div style={this.styles.roundedContainer}>
                                
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <FormControl component="fieldset" >
                                            <RadioGroup name="newexpirationdate" value={this.state.newMembershipExpirationDateType} onChange={(event) => {this.setState({newMembershipExpirationDateType: event.target.value});}}>

                                                <FormControlLabel key={1} value="Calendar" control={<Radio color="primary" />} label={"Calendar Date"} />
                                                <FormControlLabel key={2} value="EndMonthThisYear" control={<Radio color="primary" />} label={"End of the Month this Year"} />
                                                <FormControlLabel key={3} value="DeltaNow" control={<Radio color="primary" />} label={"Days from Sync Date"} />

                                            </RadioGroup>
                                        </FormControl>  

                                        <div style={{flexGrow: 1}}/>
                                        {this.state.newMembershipExpirationDateType === "Calendar" ? 
                                            //Calendar date - show the calendar picker
                                            <ManageDateField autoAccept={true} hasNever={true} json="newMembershipExpirationDateSet" style={{maxWidth: 400}} 
                                                    initialValue={this.state.selectedCommunity.newMembershipExpirationDateSet ? DateUtils.dateFormat(this.state.selectedCommunity.newMembershipExpirationDateSet) : "Never"}
                                                    neverText="Never"
                                                    minYear={DateUtils.currentYear()}
                                                    maxYear={DateUtils.currentYear()+50}
                                                    onFieldChange={this._communityFieldChanged} 
                                                    onParseError={this._dateParseError} 
                                                    calendarColor={ThemeColors.calendarColor}/> 
                                            :  null 
                                        }

                                        {this.state.newMembershipExpirationDateType === "DeltaNow" ?
                                            //Delta from now - show the numeric field                                            
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                                commitTimeout={1} json="newMembershipExpirationDateDeltaFromNow"
                                                                initialValue={this.state.selectedCommunity.newMembershipExpirationDateDeltaFromNow ? this.state.selectedCommunity.newMembershipExpirationDateDeltaFromNow : 0} 
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>      
                                            : null                                                    
                                        }
                                        {this.state.newMembershipExpirationDateType === "EndMonthThisYear" ?
                                           <Autocomplete
                                                size='small'
                                                value={this.state.selectedCommunity.newMembershipExpirationDateEndMonthThisYear}
                                                onChange={(event, newValue) => { this.state.communityToModify.newMembershipExpirationDateEndMonthThisYear = newValue; }}
                                                inputValue={this.state.newMembershipExpirationDateEndMonthThisYear}
                                                onInputChange={(event, newValue) => { this.setState({newMembershipExpirationDateEndMonthThisYear: newValue}); }}
                                                options={Array.from({length: 12}, (_, i) => i + 1)}
                                                getOptionLabel={(option) => {return DateUtils.monthNames[option-1];}}
                                                blurOnSelect
                                                renderInput={(params) => <TextField {...params} label="Month" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                            />   
                                            : null
                                        }

                                        <div style={{flexGrow: 1}}/>

                                    </div>

                                </div>
                                
                                {/*--------------------------------------EXISTING MEMBERSHIP EXPIRATION DATE --------------------------------------------*/null}
            
                                <Typography variant="body2" style={this.styles.containerLabel}>Existing Memberships Expiration Date</Typography>            
                                <div style={this.styles.roundedContainer}>
                                
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="existexpirationdate" value={this.state.existingMembershipExpirationDateType} onChange={(event) => {this.setState({existingMembershipExpirationDateType: event.target.value});}}>

                                                <FormControlLabel key={1} value="NoChange" control={<Radio color="primary" />} label={"No Change"} />
                                                <FormControlLabel key={2} value="Calendar" control={<Radio color="primary" />} label={"Calendar Date"} />
                                                <FormControlLabel key={3} value="EndMonthThisYear" control={<Radio color="primary" />} label={"End of the Month this Year"} />
                                                <FormControlLabel key={4} value="DeltaPrevious" control={<Radio color="primary" />} label={"Days from Previous Expiration"} />
                                                <FormControlLabel key={5} value="DeltaNow" control={<Radio color="primary" />} label={"Days from Sync Date"} />

                                            </RadioGroup>
                                        </FormControl>  

                                        <div style={{flexGrow: 1}}/>

                                        {this.state.existingMembershipExpirationDateType === "Calendar" ? 
                                            //Calendar date - show the calendar picker
                                            <ManageDateField autoAccept={true} hasNever={true} json="existingMembershipExpirationDateSet" style={{maxWidth: 400}} 
                                                    initialValue={this.state.selectedCommunity.existingMembershipExpirationDateSet ? DateUtils.dateFormat(this.state.selectedCommunity.existingMembershipExpirationDateSet) : "Never"}
                                                    neverText="Never"
                                                    minYear={DateUtils.currentYear()}
                                                    maxYear={DateUtils.currentYear()+50}
                                                    onFieldChange={this._communityFieldChanged} 
                                                    onParseError={this._dateParseError} 
                                                    calendarColor={ThemeColors.calendarColor}/> 
                                            : null
                                        }
                                        
                                        {this.state.existingMembershipExpirationDateType === "DeltaPrevious" ?
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                                commitTimeout={1} json="existingMembershipExpirationDateDeltaFromPrevious"
                                                                initialValue={this.state.selectedCommunity.existingMembershipExpirationDateDeltaFromPrevious ? this.state.selectedCommunity.existingMembershipExpirationDateDeltaFromPrevious : 0} 
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>
                                            : null
                                        }
                                        {this.state.existingMembershipExpirationDateType === "DeltaNow" ?
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                                commitTimeout={1} json="existingMembershipExpirationDateDeltaFromNow"
                                                                initialValue={this.state.selectedCommunity.existingMembershipExpirationDateDeltaFromNow ? this.state.selectedCommunity.existingMembershipExpirationDateDeltaFromNow : 0} 
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>
                                            : null
                                        }
                                        {this.state.existingMembershipExpirationDateType === "EndMonthThisYear" ?
                                           <Autocomplete
                                                size='small'
                                                value={this.state.selectedCommunity.existingMembershipExpirationDateEndMonthThisYear}
                                                onChange={(event, newValue) => { this.state.communityToModify.existingMembershipExpirationDateEndMonthThisYear = newValue; }}
                                                inputValue={this.state.existingMembershipExpirationDateEndMonthThisYear}
                                                onInputChange={(event, newValue) => { this.setState({existingMembershipExpirationDateEndMonthThisYear: newValue}); }}
                                                options={Array.from({length: 12}, (_, i) => i + 1)}
                                                getOptionLabel={(option) => {return DateUtils.monthNames[option-1];}}
                                                blurOnSelect
                                                renderInput={(params) => <TextField {...params} label="Month" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                            />   
                                            : null
                                        }
                                        <div style={{flexGrow: 1}}/>

                                        
                                    </div>
                                    
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginLeft: -10}}>
                                        <Checkbox defaultChecked={this.state.selectedCommunity.ignoreMembershipsThatNeverExpire} color='primary' 
                                            onChange={(event) => { this.state.communityToModify.ignoreMembershipsThatNeverExpire = event.target.checked;}}/>   
        
                                        <Typography variant='body1' align='left'>Ignore Existing Memberships that Never Expire</Typography>                        
                                    </div> 
                                    
                                
                                </div>
                                
                                {/*--------------------------------------NEW MEMBERSHIP GUEST PASSES --------------------------------------------*/null}

                                
                                <Typography variant="body2" style={this.styles.containerLabel}>New/Replaced Membership Initial Virtual Guest Passes</Typography>            
                                <div style={{...this.styles.roundedContainer, display: 'flex'}}>
                                
                                        <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                            commitTimeout={1} json="newMembershipGuestPassesSet"
                                                            initialValue={this.state.selectedCommunity.newMembershipGuestPassesSet ? this.state.selectedCommunity.newMembershipGuestPassesSet : 0} 
                                                            onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>  
                                </div>
                                
                                {/*--------------------------------------EXISTING MEMBERSHIP GUEST PASSES --------------------------------------------*/null}

                                
                                <Typography variant="body2" style={this.styles.containerLabel}>Existing Membership Virtual Guest Passes</Typography>            
                                <div style={this.styles.roundedContainer}>
                                
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <FormControl component="fieldset" >
                                            <RadioGroup name="newguestpasses" value={this.state.existingMembershipGuestPassesType} onChange={(event) => {this.setState({existingMembershipGuestPassesType: event.target.value});}}>

                                                <FormControlLabel key={1} value="NoChange" control={<Radio color="primary" />} label={"No Change"} />
                                                <FormControlLabel key={2} value="Set" control={<Radio color="primary" />} label={"Set New Value"} />
                                                <FormControlLabel key={3} value="Increment" control={<Radio color="primary" />} label={"Increment Existing"} />

                                            </RadioGroup>
                                        </FormControl>  

                                        <div style={{flexGrow: 1}}/>
                                        {this.state.existingMembershipGuestPassesType === "Set" ?
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                                commitTimeout={1} json="existingMembershipGuestPassesSet"
                                                                initialValue={this.state.selectedCommunity.existingMembershipGuestPassesSet ? this.state.selectedCommunity.existingMembershipGuestPassesSet : 0} 
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>
                                            : null
                                        }
                                        {this.state.existingMembershipGuestPassesType === "Increment" ?
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                                commitTimeout={1} json="existingMembershipGuestPassesIncrement"
                                                                initialValue={this.state.selectedCommunity.existingMembershipGuestPassesIncrement ? this.state.selectedCommunity.existingMembershipGuestPassesIncrement : 0} 
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>
                                            : null
                                        }
                                        <div style={{flexGrow: 1}}/>

                                    </div>

                                </div>
                                
                                
                                {/*--------------------------------------NEW MEMBERS ENTRY PASSES --------------------------------------------*/null}

                                
                                <Typography variant="body2" style={this.styles.containerLabel}>New Member Entry Passes</Typography>            
                                <div style={{...this.styles.roundedContainer, display: 'flex'}}>
                                
                                        <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={true} justify='left' 
                                                            commitTimeout={1} json="newMemberEntryPassesSet"
                                                            initialValue={this.state.selectedCommunity.newMemberEntryPassesSet ? this.state.selectedCommunity.newMemberEntryPassesSet : 0} 
                                                            onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>  
                                </div>
                                
                                
                                {/*--------------------------------------EXISTING MEMBERS ENTRY PASSES --------------------------------------------*/null}

                                
                                <Typography variant="body2" style={this.styles.containerLabel}>Existing Member Entry Passes</Typography>            
                                <div style={this.styles.roundedContainer}>
                                
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="newentrypasses" value={this.state.existingMemberEntryPassesType} onChange={(event) => {this.setState({existingMemberEntryPassesType: event.target.value});}}>

                                                <FormControlLabel key={1} value="NoChange" control={<Radio color="primary" />} label={"No Change"} />
                                                <FormControlLabel key={2} value="Set" control={<Radio color="primary" />} label={"Set New Value"} />
                                                <FormControlLabel key={3} value="Increment" control={<Radio color="primary" />} label={"Increment Existing"} />

                                            </RadioGroup>
                                        </FormControl>  

                                        <div style={{flexGrow: 1}}/>
                                        
                                        {this.state.existingMemberEntryPassesType === "Set" ?
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={true} justify='left' 
                                                                commitTimeout={1} json="existingMemberEntryPassesSet"
                                                                initialValue={this.state.selectedCommunity.existingMemberEntryPassesSet ? this.state.selectedCommunity.existingMemberEntryPassesSet : -1} 
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>
                                            : null
                                        }
                                        {this.state.existingMemberEntryPassesType === "Increment" ?
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                                commitTimeout={1} json="existingMemberEntryPassesIncrement"
                                                                initialValue={this.state.selectedCommunity.existingMemberEntryPassesIncrement ? this.state.selectedCommunity.existingMemberEntryPassesIncrement : 0} 
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>
                                            : null
                                        }

                                        <div style={{flexGrow: 1}}/>

                                        
                                    </div>
                                    
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginLeft: -10}}>
                                        <Checkbox defaultChecked={this.state.selectedCommunity.ignoreMembersWithUnlimitedEntryPasses} color='primary' 
                                            onChange={(event) => { this.state.communityToModify.ignoreMembersWithUnlimitedEntryPasses = event.target.checked;}}/>   
        
                                        <Typography variant='body1' align='left'>Ignore Members with Unlimited Entry Passes</Typography>                        
                                    </div> 
                                    
                                
                                </div>

                                {/*--------------------------------------NEW MEMBERS BARCODES --------------------------------------------*/null}
                            
                                <Typography variant="body2" style={this.styles.containerLabel}>New Members Barcode</Typography>            
                                <div style={this.styles.roundedContainer}>
                                
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <FormControl component="fieldset">
                                            <RadioGroup name="newmembersbarcode" value={this.state.newMemberBarcodeMode} onChange={(event) => {this.setState({newMemberBarcodeMode: event.target.value});}}>

                                                <FormControlLabel key={1} value="None" control={<Radio color="primary" />} label={"None"} />
                                                <FormControlLabel key={2} value="Next_Numeric" control={<Radio color="primary" />} label={"Incrementing Numeric"} />
                                                <FormControlLabel key={3} value="Random_Numeric" control={<Radio color="primary" />} label={"Random Numeric"} />
                                                <FormControlLabel key={4} value="Random_Alphanumeric" control={<Radio color="primary" />} label={"Random Alphanumeric"} />

                                            </RadioGroup>
                                        </FormControl>  

                                        <div style={{flexGrow: 1}}/>

                                        {this.state.newMemberBarcodeMode !== "None" ? 
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                                label="Minimum Digits"
                                                                commitTimeout={1} json="newMemberBarcodeDigits"
                                                                initialValue={this.state.selectedCommunity.newMemberBarcodeDigits} 
                                                                minValue={1} maxValue={100}
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>
                                            : null
                                        }
                                        
                                        {this.state.newMemberBarcodeMode === "Next_Numeric" ?
                                            <div style={{flexGrow: 1}}/>
                                            : null
                                        }

                                        
                                        {this.state.newMemberBarcodeMode === "Next_Numeric" ?
                                            <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                                label="Minimum Value"
                                                                commitTimeout={1} json="newMemberBarcodeMinValue"
                                                                initialValue={this.state.selectedCommunity.newMemberBarcodeMinValue} 
                                                                onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>
                                            : null
                                        }
                                        

                                        <div style={{flexGrow: 1}}/>

                                        
                                    </div>
                                    
                                </div>
            
                                {/*-------------------------------------- EXTRA MEMBER PASSES --------------------------------------------*/null}

                                
                                <Typography variant="body2" style={this.styles.containerLabel}>Additional Member Passes (Photo ID Guest)</Typography>            
                                <div style={{...this.styles.roundedContainer, display: 'flex'}}>
                                
                                        <ManageNumericField autoCommit={true} fullWidth={false} hasInfinity={false} justify='left' 
                                                            commitTimeout={1} json="newMembershipExtraGuestMemberPasses"
                                                            initialValue={this.state.selectedCommunity.newMembershipExtraGuestMemberPasses ? this.state.selectedCommunity.newMembershipExtraGuestMemberPasses : 0} 
                                                            onFieldChange={this._communityFieldChanged} changedBackgroundColor='white'/>  
                                </div>


                                {/*------------------------------------ PASS PRINTING SUPPRESSION --------------------------------------------*/null}
            
                                <Typography variant="body2" style={this.styles.containerLabel}>Passes</Typography>            
                                <div style={this.styles.roundedContainer}>

                                    <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>Passes are controlled by Pass Printing Portal, but these settings override to disable printing/sending</Typography>                  

                                
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginLeft: -10}}>
                                        <Checkbox defaultChecked={this.state.selectedCommunity.suppressPassPrinting} color='primary' 
                                            onChange={(event) => { this.state.communityToModify.suppressPassPrinting = event.target.checked;}}/>   
        
                                        <Typography variant='body1' align='left'>Suppress All Pass Printing</Typography>                        
                                    </div> 
                                    
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginLeft: -10}}>
                                        <Checkbox defaultChecked={this.state.selectedCommunity.suppressLimitedMemberPassPrinting} color='primary' 
                                                  onChange={(event) => { this.state.communityToModify.suppressLimitedMemberPassPrinting = event.target.checked;}}/>   
        
                                        <Typography variant='body1' align='left'>Suppress Pass Printing for New Limited Members</Typography>                        
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 5, marginLeft: -10}}>
                                        <Checkbox defaultChecked={this.state.selectedCommunity.suppressEPasses} color='primary' 
                                            onChange={(event) => { this.state.communityToModify.suppressEPasses = event.target.checked;}}/>   
        
                                        <Typography variant='body1' align='left'>Suppress Sending ePasses</Typography>                        
                                    </div> 

                                </div>
            
                            </Collapse>
                        

                        </Paper>
                    </Fragment>                
                    : null
                }
                    
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(CommunityPage));




