import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { IconButton, Tooltip, Typography, Grid, TextField } from '@material-ui/core'

import { ThemeColors } from '../Theme';



const InstructionsTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: ThemeColors.tooltipLightGray,
        color: 'blue',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(15),
        border: '2px solid gray'
    }
}))(Tooltip);


const tagStyles = {textAlign: 'center', 
                   marginLeft: 'auto', 
                   marginBottom: 3, 
                   fontSize: 10, 
                   paddingTop: -4, 
                   paddingBottom: -10, 
                   paddingRight: 4, 
                   paddingLeft: 2,
                   fontStyle: 'italic', 
                   fontWeight: 600,
                   borderRadius: '4px'
                 };



// Render the instructions for a field in different ways.  Props are:
//  instructions:  text to render, if null (no instructions), nothing is rendered
//  forPatron: true if this render is for patron use, false for processor use
//  isPatron: true if the caller is the patron, false if the caller is the processor
//  forTemplate: true if this renderer is for template use
//  isTemplate: true if this is the template
//
export function Instructions(props) {
    
    //no instructions
    if (!props.instructions)
        return null;
    
    //for patron
    if (props.forPatron || props.forTemplate) {
        if (props.isPatron || props.isTemplate)
            return <Typography variant="body2" style={{fontStyle: 'italic', backgroundColor: 'white', color: 'black', marginLeft: 10, marginBottom: 8}}>{props.instructions}</Typography>;
        else
            return null;
    }  
    
    //if patron or template, don't show the tooltip
    if (props.isPatron || props.isTemplate)
        return null;

    return (<InstructionsTooltip title={props.instructions} enterTouchDelay={0}>
                <IconButton edge="end" style={{marginTop: 0, marginRight: 3}}>
                    <InfoIcon style={{color: 'blue', fontSize: 18}} />
                </IconButton>
            </InstructionsTooltip>
            );
}

//Render a PP icon if this is a field that syncs with PP, and we are not the patron
export function PPIcon(props) {
    
    if (!props.isPPSyncField || props.isPatron)
        return null;
    
    return <Tooltip title="This field synchronizes with PoolPass">
                <Typography variant='body2' style={{...tagStyles, marginLeft: 10, color: 'white', backgroundColor: ThemeColors.appBarBackground}}>PP</Typography>
            </Tooltip>;
}

//Render a Logic icon if this is a field that has logic
export function LogicIcon(props) {
    
    return  <Tooltip title="This field has logic to control its properties">
                <Typography variant='body2' style={{...tagStyles, width: 25, color: 'white', backgroundColor: ThemeColors.logicPurple}}>Logic</Typography>
            </Tooltip>;
}

//Render a Hidden icon if this is a field that is hidden
export function HiddenIcon(props) {
    
    return  <Tooltip title="This field is initially hidden">
                <Typography variant='body2' style={{...tagStyles, width: 35, color: 'white', backgroundColor: ThemeColors.hiddenGray}}>Hidden</Typography>
            </Tooltip>;
}


//Render a Webhook icon if this is a field that is part of webhooks
export function WebhookIcon(props) {
    
    return  <Tooltip title="This field is is included in any webhooks sent after Application submission">
                <Typography variant='body2' style={{...tagStyles, width: 45, color: ThemeColors.mediumGray, backgroundColor: ThemeColors.webhookCyan}}>Webhook</Typography>
            </Tooltip>;
}



//Render a Merged icon if this is a field that is merged
export function MergedIcon(props) {
     
    return  <Tooltip title="This field is is included in any webhooks sent after Application submission">
                <Typography variant='body2' style={{...tagStyles, width: 35, color: ThemeColors.mediumGray, backgroundColor: ThemeColors.mergedOrange}}>Merged</Typography>
            </Tooltip>;
}


//Render a Modifiable icon if this is a field that is hidden
export function ModifiableIcon(props) {
    
    return  <Tooltip title="This field can be modified by processors after submission">
                <Typography variant='body2' style={{...tagStyles, width: 52, color: 'white', backgroundColor: ThemeColors.modifiableGreen}}>Modifiable</Typography>
            </Tooltip>;
}


export function RoundButton(props) {

    const style = {width: 22, height: 22, borderRadius: '50%', fontSize: 12, 
                   display: 'flex', justifyContent: 'center', alignItems: 'center', 
                   backgroundColor: props.isSelected ? 'gray' : ThemeColors.tooltipLightGray,
                   color: props.isSelected ? 'white' : 'gray',
                   borderBottom: props.isSelected ? 'none' : '1px solid gray'};

    return <div style={style} onClick={props.onClick}>
                {props.label}
            </div>
}


//Generic container for any field, that renders instructions and the PP icon as needed, then renders the children components inside
export function GenericFieldContainer(props) {

    //if we've already tried to submit, then highlight any required field without data
    const missingData = props.submitTried && props.field.required && (!props.field.patronData || props.field.patronData.trim() === "" || props.field.hasMissingData);  
    
    const border = (props.invalid || missingData) ? {border: '1px solid red', padding: 3, paddingTop: 8} : {border: 'none', padding: 0};
    
    return (
        <div style={{...props.style, marginLeft: 8, marginTop: 10, marginBottom: 10, ...border}}>

            <Instructions instructions={props.field.instructions} forTemplate={true} isTemplate={props.params.forTemplate}  forPatron={true} isPatron={props.params.forPatron}/>

            <div style={{display: 'flex', alignItems: 'center'}}>

                {props.children}

                <PPIcon isPPSyncField={props.field.isPPSyncField} isPatron={props.params.forPatron}/>
                <Instructions instructions={props.field.instructions} forTemplate={false} isTemplate={props.params.forTemplate} forPatron={false} isPatron={props.params.forPatron}/>
            </div>

        </div>
    );
}



/**
 * Component that supports an arbitrary text field that supports browser autofill.  Autofill will set the value and may fire the onChange event but
 * would not have triggered the blur event.  Once a change occurs (either key or autofill) set a timer for 2 seconds.  If another change happens
 * reset the timer, but if a timeout occurs commit the change.  When focus is lost immediately commit the change.  The component value must be
 * a "truthy" value (not null or empty) in order to trigger the commmit on idle timeout.  The caller should pass the following props:
 * 
 * label: text field label
 * initialValue: the initial text field value
 * isReadOnly: true if the value is read only
 * onFieldChange: callback when field has changed (either blurred or change timeout), with an argument of the new text value
 * style: style object applied to the text field
 */
export class GenericTextField extends React.Component {

    _idleTimeoutMs = 4000;

    _ref = React.createRef(); //handle to the TextField
    _text;  //current text value known after timeout
    _idleTimeoutHandle;  //returned from setTimeout

    constructor(props) {
        super(props);
        this._text = props.initialValue; 
    }

    componentDidMount() {
        this._idleTimeoutHandle = setTimeout(this._idleTimeout, this._idleTimeoutMs);  //do a timeout just in-case the onChange did not get executed by autofill on page load - should never happen?
    }
   
    componentWillUnmount() {
        this._clearIdleTimeout();
    }

    _clearIdleTimeout = () => {
        if (this._idleTimeoutHandle)
            clearTimeout(this._idleTimeoutHandle);     
    }


    _lines = () => {
        if (this.props.numLines > 1)  //may be null for some fields, so use default of 1
            return this.props.numLines;
        else
            return 1;
    }

    //Monitor the input value for changes (like autofill activity), periodically trigger onFieldChange() when input value changes
    //Also called when focus is lost or enter key is pressed
    _idleTimeout = () => {
        if (!this._ref.current)
            return;
        const currentVal = this._ref.current.value;

        if (currentVal !== null && currentVal !== this._text) {  //value has been modified
            this._text = currentVal;
            this.props.onFieldChange(currentVal ? currentVal.trim() : currentVal);  //remove extra whitespace
        }
    }

    render() {
        const keyDown = (event) => {
            if (event.key === 'Enter' && this._lines() === 1) {  //enter key - lose focus only for single-line entry
                event.target.blur();        
            }
        }
        
        const changed = () => { 
            this._clearIdleTimeout();
            this._idleTimeoutHandle = setTimeout(this._idleTimeout, this._idleTimeoutMs);  //timeout if no more typing or autofill changed
        }

        const focusLost = () => {
            this._clearIdleTimeout();
            this._idleTimeout();  //immediately timeout
        }

        const style = this.props.style ? this.props.style : {};
      
        return ( 
            <span style={{width: '100%', display: 'flex'}}>
                <TextField label={this.props.label} 
                            variant="outlined" 
                            size="small"
                            inputRef={this._ref} 
                            defaultValue={this.props.initialValue}
                            multiline={this._lines() > 1}
                            rows={this._lines()}
                            fullWidth={true}
                            inputProps={{readOnly: this.props.isReadOnly}}
                            onKeyDown={keyDown}
                            onChange={changed}
                            onBlur={this.props.isReadOnly ? null : focusLost}
                            InputLabelProps={{ shrink: true }} 
                            style={style}
                />
            </span>
        ); 
    }
      
  
}                    
  




//Renders multiple text-field subcomponents for a single TextField type (subclass), where the patron data is split into multiple fields by the "|" character for display and joined again
//when changed. The vertical bar and newline characters are not allowed in any of the text fields as this would corrupt the splitting.  The combination of data from all subfields 
//must not exceed the maximum number of characters, and if the field is required, the on-change is only called when all of the multiple fields have user-entered text.
//
// The caller should pass the same props object as passed to the specific Field in the FieldRenderer (spreading the props) as well as two additional props:
//
//  isReadOnly = true if the data is read only
//  components = an array of objects, each object representing the subfield information as follows:
//
//  label: label for the subfield
//  value: initialValue for the subfield
//  xs, sm, md, etc: items that are grid width out of 12, for relative sizing based on the screen width.
//  isAlwaysOptional: if the overall Field is required, this marks this subfield as still being optional, default is false    
//
export function GenericMultiTextFields(props) {

    const [invalid, setInvalid] = useState(false);  //initially, not invalid

    return (
        <GenericFieldContainer style={props.style} field={props.field} submitTried={props.submitTried} params={props.params} invalid={invalid}>

            <Grid container spacing={1}>
                {props.components.map((component, index) => 
                    <Grid item xs={component.xs} sm={component.sm} md={component.md} key={index}>
                        <GenericTextField label={component.label}                              
                                          initialValue={component.value}
                                          numLines={props.field.numLines ? props.field.numLines : 1}
                                          isReadOnly={props.isReadOnly}
                                          onFieldChange={(userValue) => {

                                            props.components[index].value = userValue;

                                            let patronData = "";
                                            let fieldEmptyCount = 0;
                                            for (component of props.components) {

                                                if (component.value.includes("|") || (component.value.includes("\n") && !(props.field.numLines > 1))) {
                                                    setInvalid(true);
                                                    props.params.onError(props.field.label, "Field cannot contain a | or newline");
                                                    props.field.hasError = true;
                                                    return;
                                                } 
                                                //Sum the empty fields for required check below, but exclude from sum if component is always optional
                                                if (component.value.length === 0 && !component.isAlwaysOptional)
                                                    fieldEmptyCount++;
                                                patronData += component.value + "|";  //add the component value with a separator
                                            }

                                            patronData = patronData.slice(0, -1); //remove trailing bar

                                            if (props.field.maxChars && patronData.length > props.field.maxChars) {
                                                setInvalid(true);
                                                props.params.onError(props.field.label, "Too many characters, field limit is " + props.field.maxChars);  
                                                props.field.hasError = true;  
                                                return;
                                            }

                                            setInvalid(false);
                                            props.field.hasError = false;

                                            props.field.patronData = patronData;
                                            props.field.hasMissingData = fieldEmptyCount > 0;  //note missing data for check in Application
                                            if (props.params.onChange)
                                                props.params.onChange(props.field, patronData);
                                            

                                     }}/>
                    </Grid>
                )}
            </Grid>
        </GenericFieldContainer>
    );
}

