
import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { RestComponent, TextEntryPopover } from 'react-frontend-utils' 

import { AppModification } from '../models/AppModification'

/**
 * Popup for posting comments and flags. Once the user enters text, the comment is either posted to the backend, or cancelled.  The following props must be passed:
 * 
 * applicationID: String, the application id for the comment
 * commentType: String, one of "comment", "flagCommunity", or "flagAGS"
 * commentPopupOpen: Boolean, true to show the popup, false to hide
 * 
 * onCancel: called with no arguments when user cancels (caller should just set commentPopupOpen to false)
 * onCommentPostInitiated: called with no arguments when the post is initiated
 * onCommentPostedSuccess: called with no arguments when the post succeeds (generally close the popup, remove any busy indicators)
 * onCommentPostError: called with the error argument if the post fails
 * 
 */

class CommentPopup extends RestComponent {


    _commentOkCallback = (text) => {
        
        const am = AppModification.comment(text);
        
        let where;
        switch (this.props.commentType) {
            
            case "comment":
                where = "/comment";
                break;
                
            case "revertToCommunity":
            case "flagCommunity":
                where = "/flag?to=community"; 
                break;
            
            case "escalateAGS": 
                where = "/escalate"; 
                break;
                
            default:
                console.error("Unknown comment destination");
                return;
        }
        
        this.secureJSONFetch("/ap/applications/" + this.props.applicationID + where, {method: "POST", body: JSON.stringify(am)},
                             this.props.onCommentPostedSuccess, 
                             this.props.onCommentPostError
                            ); 

        this.props.onCommentPostInitiated();
          
    }


    render() {
        let commentTitle;
        if (this.props.commentType) {
            switch (this.props.commentType) {
            
                case "comment":
                    commentTitle = "Add a Internal Comment";
                    break;

                case "flagCommunity":
                    commentTitle = "Flag this to Community";
                    break;

                case "revertToCommunity":
                    commentTitle = "Revert this back to Community";
                    break;
                
                case "escalateAGS": 
                    commentTitle = "Escalate this to Access Granted Support";
                    break;

                default:
                    commentTitle = "Unknown comment destination";
                    break;
            }
        }
        
    
        return (                        
            <Fragment>
                <TextEntryPopover isOpen={this.props.commentPopupOpen} showSkip={false} multiline={true} title={commentTitle} 
                                 okCallback={this._commentOkCallback} cancelCallback={this.props.onCancel} />
             
            </Fragment>
        );
    }

}


export default withCookies(withRouter(CommentPopup));
