


export class AppModification {
    
    newState;
    fieldToModify;
    newValue;
    comment;
    patronMessage;
    sendEmail;
    refundAmount;
    
    static release(newState, patronMessage = null, internalComment = null, sendEmail = false) {
        const am = new AppModification();
         
        am.newState = newState.enumName;
        if (patronMessage)
            am.patronMessage = patronMessage;
        if (internalComment)
            am.comment = internalComment;
        
        am.sendEmail = sendEmail;
        
        return am;
    }
    
    static modifyField(fieldName, newValue) {
        const am = new AppModification();
        am.fieldToModify = fieldName;
        am.newValue = newValue;
        return am;
    }
    
    static comment(text) {
        const am = new AppModification();
        am.comment = text;
        
        return am;
    }

    static refund(amount, comment) {
        const am = new AppModification();
        am.refundAmount = amount;
        am.comment = comment;
        
        return am;
    }
    
}

