import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { Paper, Tooltip, Typography, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';

import { RestComponent, DateUtils } from 'react-frontend-utils' 
import { ThemeColors } from '../Theme'


// SYNC PAGE 1 - Ask the backend for matching Memberships to the Application address, which returns the backend PPSyncInit object:
// 
//  {   searchAddress - String, the address searched
//      matchingMemberships - array of Memberships (id and address)         
//      bestMatchRank - integer rank of the top match (-1 if no Memberships)
//      allowsNewMemberships: true if this community allows new Memebrship
//   }
//
//
//   When finished: passes the selected Membership ID to Page 2 (or null when a new Membership is selected)
//
//

export class SyncPage1 extends RestComponent {
  
    styles = {
        table: {
            borderCollapse: 'collapse',
            marginBottom: 10,
            marginTop: 10,
            alignContent: 'center'
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            paddingBottom: 5,
            marginBottom: 8,
            fontSize: 14,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 15,
            verticalAlign: 'center'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 15,
            borderBottom: '1px solid lightGray',
            paddingRight: 12,
        }
    }
  
  
    _id;   //Application id 
    _nextPageCallback;
    
    constructor(props) {
        super(props);
    
        this._id = props.id;        //application id
        this._nextPageCallback = props.nextPageCallback;  //callback that takes the Membership ID selected (or null for new), and moves to Page 2
        
        this.state.searchResult = null;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the Memberships for the Application
     */
    componentDidMount() {
        super.componentDidMount(); 
       this._fetchMemberships();
    }
 
    
    _fetchMemberships = () => {

        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this._id + "/ppsync/memberships", {},
                            this._fetchMembershipsCallback, this._fetchErrorCallback);   
    }
   
    
    // Callback for fetching Memberships - response is a backend PPSyncInit object
    _fetchMembershipsCallback = (response) => {
        if (response)
            this.setState({searchResult: response, fetchInProgress: false});  //set to retrieved object
        
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
        this.setState({fetchInProgress: false}); //fetching failed
    }
  
  
    //User selects one of the Memberships from the list, or the "new" button which passes null
    _selectMembership = (membershipID) => {
        this._nextPageCallback(membershipID);
    }
   

    _membershipStatus = (membership) => {

        if (membership.suspended)
            return "Suspended";

        else if (membership.expirationDate) {
            const expirationDate = DateUtils.parseJsonDate(membership.expirationDate);
            const today = DateUtils.startOfToday();
            if (expirationDate.valueOf() < today.valueOf())
                return "Expired";
        }
             
        else
            return "";
    }

  
    render() {
        
        let message = "";
        let allowNew = false;
        if (this.state.searchResult) {
            allowNew = this.state.searchResult.allowsNewMemberships;

            if (!this.state.searchResult.matchingMemberships)
                message = "The PoolPass database for this community does not have any Memberships. " + allowNew ? "You may add this Application as a new Membership." : "This community does not allow new Memberships.";
            else if (this.state.searchResult.bestMatchRank > 6)
                message = "We did not find any good Membership matches to this Application. " + (allowNew ? "This may be a new Membership Application that you can add." : "Check the list below to see if it exists.");
            else
                message = "Below are a list of possible Membership matches. Select the best match" + (allowNew ? " or you may add this Application as a new Membership." : ".");

        }
            
        return (
            <Fragment>
                {this.getConfirmAlertComponent()}
                                        
                {this.getBusyComponent('center', {marginTop: 20})}

                {!this.state.searchResult ? null :

                    <Fragment>
                        {allowNew ?
                            <Tooltip title="Create a new Membership from this Application">
                                <Button variant='outlined' style={{float: 'right', marginRight: 20, borderColor: ThemeColors.addColor, color: ThemeColors.addColor}}
                                        onClick={() => this._selectMembership(null)} startIcon={<AddIcon/>}>
                                    Create New Membership
                                </Button>
                            </Tooltip>
                            :
                            null
                        }

                        <Paper style={{position: 'fixed', backgroundColor: 'white', zIndex: 10000, padding: 10}}>
                            <Typography variant="h5" style={{marginBottom: 10}}>{"Address on Application: " + this.state.searchResult.searchAddress}</Typography>             
                            <Typography variant="body1">{message}</Typography>  
                            <Typography variant="body1">Having trouble finding the Membership ID? Use your browser's find command (Ctrl-F).</Typography>  
                        </Paper>

                        <div style={{height: 120}}/>

                        {this.state.searchResult.matchingMemberships ? 
                            <table style={this.styles.table}>
                                <tbody>
                                    <tr style={this.styles.tableStyle}>
                                        <td style={{...this.styles.tableHeader, textAlign: 'center'}}>Membership ID</td>
                                        <td style={this.styles.tableHeader}>Status</td>        
                                        <td style={this.styles.tableHeader}>Address</td>        
                                        <td style={this.styles.tableHeader}>Type</td>        
                                        <td style={this.styles.tableHeader}>Email</td>        
                                        <td style={this.styles.tableHeader}>Phone</td>        
                                    </tr>

                                    {this.state.searchResult.matchingMemberships.map((membership, index) => 
                                        <tr key={index} style={this.styles.tableStyle}>
                                            <td style={this.styles.tableDataWithBorder}>
                                                <Button style={{width: 400}} onClick={() => this._selectMembership(membership.id)}> 
                                                    {membership.id}
                                                </Button>
                                            </td>
                                            <td style={{...this.styles.tableDataWithBorder, color: 'red'}}>{this._membershipStatus(membership)}</td>
                                            <td style={this.styles.tableDataWithBorder}>{membership.address}</td>
                                            <td style={this.styles.tableDataWithBorder}>{membership.type}</td>
                                            <td style={this.styles.tableDataWithBorder}>{membership.email}</td>
                                            <td style={this.styles.tableDataWithBorder}>{membership.primaryPhone}</td>
                                        </tr>)}

                                </tbody>
                            </table> : null}
                    </Fragment>
                }
            </Fragment>
        );
    }
  
}

export default withCookies(withRouter(SyncPage1));


