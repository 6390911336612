import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { Typography, Button, Container, LinearProgress, CircularProgress } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import AGTheme from '../Theme'
import { RestComponent, HomepageContext } from 'react-frontend-utils'
import { DateUtils } from 'react-frontend-utils'




class Export extends RestComponent {
  
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    }
  
    _id;
    _poller;

    constructor(props) {
        super(props);
        
        this._id = props.id ? encodeURIComponent(props.id) : null;  //user asks for a specific application, encode for URL safe
       
        if (!this._id)
            this.state.serverError = "No Export Job ID";

        this.state.progressVal = 0;
        this.state.jobError = null;
        this.state.jobFormat = null;
    }
    
    componentDidMount() {
        super.componentDidMount(); 
        this._poller = setInterval(this._pollCallback, 2000);  //poll every 2 seconds for status      
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this._stopPolling();
    }

    _stopPolling = () => {
        if (this._poller)
            clearInterval(this._poller);
    }
 
    sessionExpired = () => {
        this.setState({serverError: "Session Expired. Please close this tab", applicationID: null});
    }


    //Called periodically to fetch the job status
    _pollCallback = () => {
        this.secureJSONFetch("/ap/exports/" + this._id, {}, this._jobUpdateCallback, this._jobUpdateError); 
    }

    _jobUpdateCallback = (response) => {  //response is a ExportJob

        if (response) {
            if (response.jobID !== this._id)
                this.setState({serverError: "Job ID mismatch"});
            else {
                this.setState({progressVal: response.percentComplete, jobError: response.error, jobFormat: response.format});  //progress val, 0-100, and error (null for no error)

                if (response.percentComplete >= 100)
                    this._stopPolling();

            }
        } 
    }

    _jobUpdateError = (error) => {
        this.setState({jobError: error}); 
        this._stopPolling();
    }

    _download = () => {

        if (!this.state.jobFormat) {
            console.log("Unknown job format");
            return;
        }
        
        const filename = "Application Export on " + DateUtils.downloadTimeString() + "." + this.state.jobFormat.toLowerCase();
        this.secureFileDownload("/ap/exports/" + this._id + "/download", filename, null, (error) => this.showConfirmAlert("Error", "Error retrieving file (may have expired). Please close window and retry export.", 'red')); 
    }


    render() {

            
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const progress = this.state.progressVal;
        
        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={AGTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  

                        {serverErrorMessage}
                        
                        <Container maxWidth={false} disableGutters={true} style={{paddingLeft: 20, paddingRight: 20}}>
                            
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20}}>
                                <Typography variant='h6'>Exporting Applications...</Typography>
                                {progress < 100 ? <CircularProgress size={20}/> : null}
                            </div>

                            <LinearProgress variant="determinate" value={progress} />
                            <Typography variant='button'>{progress + "% complete"}</Typography>

                            {this.state.jobError ?
                                <Typography variant='body2' style={{color: 'red'}}>{this.state.jobError}</Typography> 
                                : null
                            }

                            <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                                {progress >= 100 ?
                                    <Button variant='contained' color='primary' onClick={this._download}>Download File</Button>
                                    : null
                                }
                            </div>
                            

                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(withRouter(Export));
