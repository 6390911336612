import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { IconButton, Popover, Box, Tooltip, LinearProgress, Grid, Checkbox, Typography, Paper, TextField, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GetAppIcon from '@material-ui/icons/GetApp';

import { ThemeColors } from '../Theme'
import { Application } from '../models/Application'
import { PagedApplicationList } from '../models/PagedApplicationList'
import { ApplicationCounts } from '../models/ApplicationCounts'
import ApplicationPage from './ApplicationPage'
import { Global } from '../models/Global'
import { OpenInNewTab } from '../App'

import { RestComponent, Permissions } from 'react-frontend-utils' 
import { multilineJSX, StyledTooltip } from 'react-frontend-utils'

import CommentPopup from '../components/CommentPopup'
import { DateUtils, SummaryWidget, ManageTextField, ManageBitwiseCheckboxes, PopupMenu, DateRangeSelector } from 'react-frontend-utils'


/**
 * Page Displays either a List of all Applications, or hosts the ApplicationPage. The caller passes a prop called id, which if not null,
 * selects the Application ID to display on the ApplicationPage.
 * 
 * On load, the initial Page of Applications are fetched from the backend.  A filtered list can be queried later on the page. 
 * 
 */


export class ApplicationsListPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        },
        id: {
            marginLeft: 'auto',
            maxWidth: 300,
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            paddingRight: 10,
            verticalAlign: 'center'
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 90,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
       
    };
    
    
    static currentYear = (new Date()).getFullYear();

    
     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "Today", startTime: DateUtils.startOfToday().valueOf()},
        {label: "Last 2 days", startTime: DateUtils.startOfToday().valueOf() - (2 * DateUtils.MS_PER_DAY)},
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
        {label: "Previous Year", startTime: DateUtils.startOfYear(ApplicationsListPage.currentYear-1).valueOf(), endTime: DateUtils.lastDayOfYear(ApplicationsListPage.currentYear-1).valueOf()},
        {label: "Any", startTime: null}
    ];
    

    _searchString = "";
    _selectApplication;
    _simpleStatesToSearch = null;  //initially null, to search all states
    _fetchNewestFirst = true;  //initially, fetch newest first

    _doAdvancedSearch = false;
    _availableGroups;

    constructor(props) {
        super(props);
    
        this._selectApplication = props.onSelectApplication;  //callback for when an application is selected
        
        this.state.claimedApplication = null;
        
        this.state.applicationsPage = null;         //last fetched page - a PagedApplicationList
        this.state.applicationsCount = null;        //last fetched Applications count
               
        this.state.idToFind = "";

        this.state.searchFromDate = DateUtils.jsonDateString(DateUtils.startOfYear(), false);           //JsonDateString or null
        this.state.searchToDate = null;                                                                 //JsonDateString or null
        
        this.state.groupToSearch = null;            //Specific group to search for
        this.state.groupToSearchValue = null;
        this.state.lastGroupSearched = null;
        
        this.state.stateCheckboxes = 0;             //Bitwise selected states to search for, from checkboxes only!
        this.state.patronDataToSearch = "";         //Data in an application field to search
        this.state.journalDataToSearch = "";            //Data in a journal field
        this.state.searchEscalated = false;
        this.state.searchFullService = false;
        this.state.searchSupportRequest = false;
        this.state.searchApplicationsOnly = false;
        this.state.representativeForOnly = false;
        
        this.state.showAdvancedSearch = false;
        this.state.commentPopupOpen = false;
        this.state.commentType = null;              //One of "comment", "flagCommunity", "escalateAGS"
        this.state.commentedApplication = null;

        this.state.showExportTypeQuestion = false;
        
        this.state.revealSet = new Set();         //Set of applications to reveal history
        
        this._availableGroups = Global.user.databases;

        this.state.showUploadProgress = false;
        this.state.applicationUploadCount = 0;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the first page of applications
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        //fetch applications, first page
        this._fetchAll(); 
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isSmall: window.innerWidth < 1200 });  
    }
    


    //Callback for fetching counts - response is json of ApplicationCounts
    _fetchApplicationsCountCallback = (response) => {
        if (response) {            
            const counts = new ApplicationCounts(response);
            this.setState({applicationsCount: counts});
        }            
    }

    
    //Fetch a specific page, using search fields if set. If the _doAdvancedSearch variable is true, do an advanced search, otherwise simple
    //If forDownload is set, download as csv, otherwise normal fetch to display
    //asJson is only for superadmin
    _fetchApplications = (page = 0, forDownload = false, asJson = false, includeAllFields = false) => {
        

        let queryString = "?";
        let lastGroupSearched;
        
        if (this._doAdvancedSearch) {
            if (this.state.groupToSearch)
                queryString += "group=" + this.state.groupToSearch + "&";                
            if (this.state.stateCheckboxes)  //state checkboxes are set
                queryString += "states=" + this.state.stateCheckboxes + "&";
            if (this.state.searchFromDate)
                queryString += "fromDate=" + this.state.searchFromDate + "&";
            if (this.state.searchToDate) {
                
                const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
                queryString += "toDate=" + endOfDayDate + "&";
                
            }
            if (this.state.patronDataToSearch)
                queryString += "patronData=" + encodeURIComponent(this.state.patronDataToSearch) + "&";
            
            if (this.state.journalDataToSearch)
                queryString += "journalData=" + encodeURIComponent(this.state.journalDataToSearch) + "&";
                
            if (this.state.searchEscalated)
                queryString += "isEscalated=true&";

            if (this.state.searchFullService)
                queryString += "isFullService=true&"

            if (this.state.searchSupportRequest)
                queryString += "isSupportRequest=true&"
            if (this.state.searchApplicationsOnly)
                queryString += "isApplication=true&"

            if (this.state.representativeForOnly)
                queryString += "isRepresentativeFor=true&"

            lastGroupSearched = this.state.groupToSearch;
        }
        else {  //simple, no escalations
            if (this._simpleStatesToSearch) { //has a numeric value, search for specific states
                queryString += "states=" + this._simpleStatesToSearch + "&";  
                queryString += "isEscalated=false&";
            }      

            lastGroupSearched = null;
        }
        
        queryString += "page=" + page + "&sortNewestFirst=" + this._fetchNewestFirst;
        
        this.incrementBusy();

        if (forDownload) {
            queryString += "&includeAllFields=" + includeAllFields;
            if (asJson) {
                queryString += "&format=json";
            }
            this.secureJSONFetch("/ap/applications/export", {}, this._downloadCallback, this._fetchErrorCallback, queryString); 
        }
        else {
            this.secureJSONFetch("/ap/applications", {},
                                this._fetchApplicationsCallback, this._fetchErrorCallback, queryString);
                                

            //also fetch counts, reusing the query string (some fields are ignored)
            this.secureJSONFetch("/ap/applications/counts", {}, this._fetchApplicationsCountCallback, (error) => this.showConfirmAlert("Error", error, 'red'), queryString); 
        }

        this.setState({lastGroupSearched: lastGroupSearched});
        
    }

    _downloadCallback = (response) => {
        this.decrementBusy();

        if (response) {  //response is a ExportJob object
            console.log("Download job submitted, jobID: " + response.jobID);

            const url = "//" + window.location.hostname + ":" + window.location.port + "/agportal/export?id=" + response.jobID;

            const windowRight = window.screenLeft + window.outerWidth/2;    
                
            //Open another window with status
            window.open(url, "Export Status " + response.jobID, 'location=on,toolbar=no,scrollbars=yes,resizable=yes,' +
                        'top=' + window.screenTop + ',left=' + windowRight +',width=400,height=250');

        }

    }
   
    
    //Callback for fetching Applications - response is json of PagedApplicationList
    _fetchApplicationsCallback = (response) => {
        if (response) {            
            const ap = new PagedApplicationList(response);
            this.setState({applicationsPage: ap});
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  

    _findByIDkeyDown = (event) => {
        if (event.key === 'Enter') { 
            event.target.blur(); 
            this._selectApplication(this.state.idToFind);       
        }
        else if (event.key === 'Escape') {  //set to initial text
            this.setState({idToFind: ""});
            event.target.blur(); 
        }
    }
 
    //If the application is in the reveal list, remove it, if it is not, add it (toggle the application)
    _revealApplicationJournal = (application) => {
        const toReveal = this.state.revealSet;
        
        if (toReveal.has(application))
            toReveal.delete(application);
        else
            toReveal.add(application);
        
        this.setState({revealSet: toReveal});
    }
    

    //Attempt to claim the application, if successful, select it
    _claim = (application) => {
        
        if (application.claimedByUser()) {  //if we've already claimed it, select it instead
            this._selectApplication(application.id);
            return;
        }
        
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + application.id + "/claim", {method: "POST"},
                             this._claimApplicationCallback, this._fetchErrorCallback); 
        
    }


    //If claimed, we have the full application, so set it to the claimedApplication to pass to the ApplicationPage
    _claimApplicationCallback = (response) => {
        if (response) {            
            const app = new Application(response);
            this.setState({claimedApplication: app, fetchInProgress: false});  //set to retrieved application
            this._selectApplication(app.id);

        }            
        this.decrementBusy();
    }


 
 
    //fetch the previous page
    _prevPage = () => {  
        
        if (!this.state.applicationsPage || this.state.applicationsPage.isFirst())
            return;
        
        this._fetchApplications(this.state.applicationsPage.pageNo-1); 
        window.scrollTo(0, 0);  //jump to the top

    }
    
    //fetch the next page
    _nextPage = () => {  
        
        if (!this.state.applicationsPage || this.state.applicationsPage.isLast())
            return;
        
        this._fetchApplications(this.state.applicationsPage.pageNo+1); 
        window.scrollTo(0, 0);  //jump to the top
    }
    
  
    //When seleted application is finished (closed), call the callback function in the Home component to clear the application ID
    _applicationFinished = () => {
        this.setState({claimedApplication: null});
        this._selectApplication(null);
        this._refresh();  //refresh the page 
    }


    _comment = (application, type) => {
        this.setState({commentPopupOpen: true, commentedApplication: application, commentType: type});
    }
    
    
    // All AGS employees + community admins can force release
    static canForceRelease() {
        return Global.user.isSiteEmployee() || Global.user.isAdministrator();
    }

    _forceRelease = (application) => {
        if (!ApplicationsListPage.canForceRelease())
            return;

        this.showConfirmAlert("Confirm", 'Really Force Release Application "' + application.id + '"? The user working on this Application will not be able to finish. The Application will be returned to its previous state.',
            'black', "Cancel", () => this._finishForceRelease(application), "Release", 'red');   
        
    }

    _finishForceRelease = (application) => {
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + application.id + "/forceRelease", {method: "POST"},
                             this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        
    }

    _viewInPoolPass = (application) => {
        const url = "https://portal.pool-pass.com/membership?database=" + application.group + "&id=" + application.syncedMembership;
            
        const windowRight = window.screenLeft + window.outerWidth;    
                
        window.open(url, '_blank', 'toolbar=yes, scrollbars=yes, resizable=yes, ' +
                    'top=' + window.screenTop + ',left=' + windowRight);
    }
    
    
    _delete = (application) => {

        const copyText = application.isCopy ? " This Application is a copy and archived images and bound documents will not be deleted." : "";

        this.showConfirmAlert("Confirm", 'Really Delete Application "' + application.id + '"? Application will be permanently deleted and this operation cannot be undone. If the application triggered a sale or fee, you will not be able to trace or audit the transaction.' + copyText,
                                  'black', "Cancel", () => this._finishDelete(application), "Delete", 'red');      
       
    }
    
    _finishDelete = (application) => {
        
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + application.id, {method: "DELETE"},
                             this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        
    }
    
    
    _successIgnoreResponseCallback = (response) => {
        this._refresh();  //refresh the page  
        this.decrementBusy();
    }
        
    _internalSupportRequest = (application) => {
        OpenInNewTab("//" + window.location.hostname + ":" + window.location.port + "/community?group=" + 
                     application.group + "&origID=" + application.id + "&email=" + Global.user.email);
    }

    //Action items are items for the right popup menu
    _getActionItems = (application) => {
        
        const actionItems = [];

        // client only operations - restricted from employees unless they have developer permissions for testing
        const isClient = !Global.user.isSiteEmployee() || Global.user.hasPermissionTo(Permissions.DEVELOP);

        const canDelete = Global.isAGSAdmin();
       
        if (application.stateEnum === Application.State.CLAIMED && ApplicationsListPage.canForceRelease()) {
            actionItems.push({label: "Force Release", selectCallback: () => {this._forceRelease(application);}, icon: null});
        }

        if (application.syncedMembership)
            actionItems.push({label: "View in PoolPass", selectCallback: () => {this._viewInPoolPass(application);}, icon: null});

        actionItems.push({label: "Comment", selectCallback: () => {this._comment(application, "comment");}, icon: null});
        actionItems.push({label: "Flag to Community", selectCallback: () => {this._comment(application, "flagCommunity");}, icon: null});
        
        if (application.escalateable() && isClient)
            actionItems.push({label: "Escalate to AGS", selectCallback: () => {this._comment(application, "escalateAGS");}, icon: null});

        if (application.stateEnum === Application.State.PROCESSED && isClient)
            actionItems.push({label: "Request Support from AGS", selectCallback: () => this._internalSupportRequest(application), icon: null});

        if (canDelete || application.testing) //we can delete if wwe have permission or we're in testing
            actionItems.push({label: "Delete", selectCallback: () => {this._delete(application);}, icon: null});
        
        return actionItems;
    }
    
    
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    
    
    _checkboxStateChanged = (json, newValue) => {
        this.setState({stateCheckboxes: newValue});
    }
    
    _fetchAll = () => {
        this._simpleStatesToSearch = null;  //fetch all states
        this._doAdvancedSearch = false;  //switch to simple search
        this._fetchNewestFirst = true;
        this._fetchApplications();  
    }
    
    _fetchSubmitted = () => {
        
        this._simpleStatesToSearch = 1 << Application.State.SUBMITTED.bit;
        this._fetchNewestFirst = false;  //switch to oldest first - user wants to process the oldest first
        this._doAdvancedSearch = false;  //switch to simple search
        this._fetchApplications();
    }
    
    _fetchHeldDeclined = () => {
        let states = 1 << Application.State.ON_HOLD.bit;  //set the on hold bit
        states |= 1 << Application.State.DECLINED.bit;    //set the declined bit
        
        this._simpleStatesToSearch = states;
        this._doAdvancedSearch = false;  //switch to simple search
        this._fetchNewestFirst = true;
        this._fetchApplications();
    }

    _import = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected file " + file.name);

            //Form data has the file as a multipart 
            const formData = new FormData();
            formData.append('file', file);

            this.setState({applicationUploadCount: 0, showUploadProgress: true}); //init for new upload

            this.secureFileUpload("/ap/applications/import",
                file,
                (isDone, status) => {  //always done, only a single response

                    if (isDone) {  //when done, hide the progress and refresh the list, on success or error
                        this.setState({showUploadProgress: false});
                        setTimeout(() => this._refresh());
                        return;
                    }


                    const split = status.split(";");
                    const count = split[0];  //count of applications processed (-1 = error, 0 = done, >0 = number processed)
                    const errorMessage = split[1]; //error only on failure
            
                    if (count < 0) //error
                        this.showConfirmAlert("Error", errorMessage, 'red');
                    else if (count === "0") {  //done
                        this.showConfirmAlert("Success", "Applications Uploaded: " + this.state.applicationUploadCount , 'green'); 
                    }
                    else
                        this.setState({applicationUploadCount: count});  //updated count

                },
                (error) => {
                    this.showConfirmAlert("Error", error, 'red');
                    this.setState({showUploadProgress: false});
                    setTimeout(() => this._refresh());
                }
            );
        }
        event.target.value = null;  //allow picking of the same value again
    }
    
    _advancedSearch = () => {
        this._doAdvancedSearch = true;            //switch to advanced search
        this._fetchNewestFirst = true;
        this._fetchApplications();        
    }

    _download = (includeAllFields) => {
        this.setState({showExportTypeQuestion: false});
        this._doAdvancedSearch = true;            //switch to advanced search
        this._fetchNewestFirst = true;
        this._fetchApplications(0, true, false, includeAllFields);  //fetch for download        
    }

    _export = () => {
        this.setState({showExportTypeQuestion: false});
        this._doAdvancedSearch = true;            //switch to advanced search
        this._fetchNewestFirst = true;
        this._fetchApplications(0, true, true, true);  //fetch for download        
    }
    
    
    _refresh = () => {
        this._fetchApplications(this.state.applicationsPage ? this.state.applicationsPage.pageNo : 0);   //just fetch the same page again
    }
    

    _groupToSearchChanged = (event, value) => {
        this.setState({groupToSearch: value});
        if (value)
            this.setState({representativeForOnly: false});
    }

    _matchOnlyApplications = (event) => { 
        const checked = event.target.checked;
        this.setState({searchApplicationsOnly: checked});
        if (checked)
            this.setState({searchSupportRequest: false});   // mututally exclusive
    }
    _matchOnlySupportRequests = (event) => { 
        const checked = event.target.checked;
        this.setState({searchSupportRequest: checked});
        if (checked)
            this.setState({searchApplicationsOnly: false});   // mututally exclusive
    }

    _matchOnlyRepresentativeFor = (event) => {
        const checked = event.target.checked;
        this.setState({representativeForOnly: checked});
    }


    _getUploadProgressComponent = () => {
        
        let maxWidth = window.innerWidth * 0.66;  //two thirds the window, up to 650
        if (maxWidth > 650)
            maxWidth = 650;
        
        return (    
                
            <Popover open={this.state.showUploadProgress} 
                     anchorOrigin={{vertical: 'center', horizontal: 'center'}}
                     transformOrigin={{vertical: 'center', horizontal: 'center'}} >
                
                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', color: 'blue'}}>Importing Applications</Typography>                   
                    <LinearProgress variant="indeterminate"/>
                    <div>
                        <div style={{marginTop: 10}}> 
                            {"Applications Uploaded: " + this.state.applicationUploadCount}
                        </div>
                    </div>
                </Box>
            </Popover>
        );
    }





    _getExportTypePopup = () => {
            
        return (           
            <Dialog open={this.state.showExportTypeQuestion} onClose={()=> this.setState({showExportTypeQuestion: false})}>

                <DialogTitle>Export Applications</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color: 'black'}}>Select the export type: Basic for common Application fields or Full to include all fields. Note: a full export can be slow when selecting many Applications.</DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={() => this._download(false)}>Basic</Button>
                    <Button variant="outlined" onClick={() => this._download(true)}>Full</Button>
                    {Global.user.isSuperAdmin() ? 
                        <Button variant="outlined" onClick={() => this._export()}>JSON Export</Button> 
                        : null
                    }
                    <Button variant="outlined" onClick={() => this.setState({showExportTypeQuestion: false})}>Cancel</Button>
                </DialogActions>

            </Dialog>
        );
    }


    
    //Render a row of the table with the specified Application. In the Application is null, render the header row
    _renderRow = (application, key) => {
        
        //Render the header
        if (!application) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                    <th style={{...this.styles.tableHeader, width: 180}}>Submit Date</th>
                    <th style={{...this.styles.tableHeader, width: 180, textAlign: 'center'}}>Status</th>
                    {this.state.isSmall ? null :
                        <Fragment>
                            <th style={this.styles.tableHeader}></th>
                            <th style={this.styles.tableHeader}>Claimed By</th>
                        </Fragment>
                    }
                    <th style={this.styles.tableHeader}>Patron</th>
                    <th style={this.styles.tableHeader}>Community</th>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                </tr>
            );
        }
      
      
        const submitDateAndTime = DateUtils.timeFormat(application.submitDate);
        
        let submitter = application.submitterEmail;
        if (submitter.length > 30) {
            submitter = (<StyledTooltip title={multilineJSX(submitter, true)}>
                            <div>{submitter.substring(0, 27) + "..."}</div>
                        </StyledTooltip>);
        }
        
        
         
        let group = application.group;
        if (group.length > 30) {
            group = <StyledTooltip title={multilineJSX(group, true)}>
                        <div>{group.substring(0, 27) + "..."}</div>
                    </StyledTooltip>;
        };
        
        const isRevealed = this.state.revealSet.has(application);
        
        const leftIcon = isRevealed ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
                                
        const columns = this.state.isSmall ? 6 : 8;      
        
        let claimColor = 'lightGray'; //default - not claimable
        let claimTooltip = "Not Claimable";
        if (application.claimable()) {
            claimColor = 'green';
            claimTooltip = "Claimable (click to claim)";
        }
        else if (application.claimedByUser()) {
            claimColor = ThemeColors.claimedPurple;
            claimTooltip = "Claimed by you (click to continue with claim)";
        }
                                
        return (
            <Fragment key={key}>
                <tr style={this.styles.tableStyle}>


                    <td style={this.styles.tableData}>

                        <IconButton edge="start" onClick={() => this._revealApplicationJournal(application)}>
                            {leftIcon}
                        </IconButton>
                    </td>

                    <td style={this.styles.tableData}>{submitDateAndTime}</td>

                    <td style={this.styles.tableData}>
                        <div style={{...this.styles.status, backgroundColor: application.stateEnum.backgroundColor}}>
                            {application.stateEnum.label}
                        </div>
                    </td>
                    
                    {this.state.isSmall ? null :
                        <Fragment>
                            <td style={this.styles.tableData}>
                                {application.renderBadges()}
                            </td>
                            <td style={this.styles.tableData}>{application.claimerName}</td>
                        </Fragment>
                    }                          

                    <td style={this.styles.tableData}>{submitter}</td>
                    <td style={this.styles.tableData}>{group}</td>

                    <td style={{...this.styles.tableData, paddingRight: 0}}>
                        <div style={{display: 'flex', justifyContent: 'right'}}>
                            <Tooltip title={claimTooltip}>
                                <IconButton disabled={!application.claimable() && !application.claimedByUser()} onClick={() => this._claim(application)}>
                                    <AssignmentIcon style={{color: claimColor}}/>
                                </IconButton>
                            </Tooltip>                     
                            <Tooltip title="View">
                                <IconButton onClick={() => this._selectApplication(application.id)}>
                                    <VisibilityIcon style={{color: ThemeColors.viewBlue}}/>
                                </IconButton>
                            </Tooltip>
                            <div style={{marginRight: 10}}/>
                            <PopupMenu menuIcon={(<SettingsIcon style={{fontSize: '20', color: ThemeColors.settingsOlive}}/>)}  
                                                menuItems={this._getActionItems(application)} 
                                                menuTooltipText={"Other Actions"}/>
                        </div>
                    </td>
                </tr>
                <tr style={this.styles.tableStyle}>
                    <td colSpan={columns} align='center' style={this.styles.tableDataWithBorder}>
                        <Collapse in={isRevealed}>
                            {application.renderJournal()}
                        </Collapse>
                    </td>
                </tr>
            </Fragment>
        );
    }
    
    
    _checkboxLabels = (function(){
        const checkboxLabels = [];
        for (let index in Application.State) {
            const state = Application.State[index];

            checkboxLabels.push({name: state.label, tooltip: null});
        } 
        return checkboxLabels;
    })();  //iffe
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
        //If we have a selected Application ID or a claimed Application, render the ApplicationPage instead 
        if (this.props.id || this.state.claimedApplication) {
            return <ApplicationPage id={this.props.id} claimedApplication={this.state.claimedApplication} exitCallback={this._applicationFinished}/>;
        }
       
                                
        const page = this.state.applicationsPage;
       
        const showing = (page && page.applications.length > 0) ? "Displaying " + (page.index + 1) + "-" + (page.index + page.applications.length) : null;            
        
        const advancedIcon = this.state.showAdvancedSearch ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
        
        const applicationToComment = this.state.commentedApplication ? this.state.commentedApplication.id : null;

        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                {this._getExportTypePopup()}
                {this._getUploadProgressComponent()}
                
                <CommentPopup applicationID={applicationToComment} commentType={this.state.commentType} commentPopupOpen={this.state.commentPopupOpen}
                              onCancel={() => {this.setState({commentPopupOpen: false});}} 
                              onCommentPostInitiated={() => {this.incrementBusy(); this.setState({commentPopupOpen: false});}} 
                              onCommentPostedSuccess={() => {this.setState({commentPopupOpen: false});  this.decrementBusy();  this._refresh();}}
                              onCommentPostError={(error) => {this.setState({commentPopupOpen: false}); this._fetchErrorCallback(error);}}/>
                                                       
                
                {this.state.applicationsCount ?
                    <Grid container direction="row" spacing={2} style={{padding: 10}}>

                            {this.state.applicationsCount.stateCounts ? 
                                this.state.applicationsCount.stateCounts.map((stateCount, index) => 
                                    <Grid item md={2} xs={4} key={index}>
                                        <SummaryWidget label={stateCount.state.label}
                                                    value={stateCount.count}
                                                    tooltip={"Approximate count of " + stateCount.state.label.toUpperCase() + " applications for " + (this.state.lastGroupSearched ? this.state.lastGroupSearched : " all your communities")}
                                                    borderColor={stateCount.state.backgroundColor}/>
                                    </Grid>)
                                :
                                <Fragment>
                                    <Grid item xs={4}>
                                        <SummaryWidget label="Total Application Count"
                                            value={this.state.applicationsCount.total}
                                            tooltip="Approximate count of all applications for all communities"
                                            borderColor="black"/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SummaryWidget label="AGS Process Backlog"
                                            value={this.state.applicationsCount.agsBacklog}
                                            tooltip="Count of full service Applications and Support Requests to be processed"
                                            borderColor="black"/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SummaryWidget label="Escalated to AGS"
                                            value={this.state.applicationsCount.escalated}
                                            tooltip="Count of Applications and Support Requests escalated to AGS for support"
                                            borderColor="black"/>
                                    </Grid>
                                </Fragment>
                            }

                    </Grid>
                    : null
                }
        
               
                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'left', alignItems: 'center'}}>
                
                    <Tooltip title="All Applications and Support Requests, ordered from newest to oldest">
                        <Button fullWidth size='small' style={{backgroundColor: ThemeColors.veryLightBlue, color: 'black', maxWidth: 200}} onClick={this._fetchAll} variant="contained" component="label">
                            All
                        </Button>
                    </Tooltip>                    
                    <Tooltip title="Applications and Support Requests in the Submitted State, ready for processing, ordered from oldest to newest (ignoring those that are escalated)">
                        <Button fullWidth size='small' style={{backgroundColor: Application.State.SUBMITTED.backgroundColor, color: 'white', maxWidth: 200}} onClick={this._fetchSubmitted} variant="contained" component="label">
                            Ready for Processing
                        </Button>
                    </Tooltip>
                    <Tooltip title="Applications and Support Requests that are On Hold or Declined, ordered from newest to oldest (ignoring those that are escalated)">
                        <Button fullWidth size='small' style={{backgroundColor: Application.State.DECLINED.backgroundColor, color: 'white', maxWidth: 200}} onClick={this._fetchHeldDeclined} variant="contained" component="label">
                            Held/Declined
                        </Button>
                    </Tooltip>

                    {this.state.isBusy ? this.getBusyComponent('left', {marginLeft: 20, padding: 15}, 30) : 
                        <Tooltip title="Refresh">
                            <div style={{display: 'flex'}}>
                                <IconButton edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1, marginBottom: 1}}>
                                    <RefreshIcon fontSize="large"/>
                                </IconButton>
                            </div>
                        </Tooltip>}

                    <Tooltip title="Find an Application by ID">
                        <TextField label="Find by ID" 
                                variant="outlined" 
                                size="small"
                                fullWidth='true'
                                value={this.state.idToFind}
                                onChange={(event) => {this.setState({idToFind: event.target.value})}}
                                onKeyDown={this._findByIDkeyDown}
                                inputProps={{style: {fontSize: 12, fontFamily: 'monospace'}}}
                                InputLabelProps={{ shrink: true }} 
                                style={this.styles.id}/>
                    </Tooltip>

                    {Global.user.isSuperAdmin() ? 
                        <Tooltip title="Upload Application(s) from JSON file">
                            <Button fullWidth size='small' style={{color: 'black', maxWidth: 200}} variant="outlined" component="label">
                                Import
                                <input accept="text/json" style={{display: 'none'}} type="file" onChange={this._import}/>
                            </Button>
                        </Tooltip>
                        : null
                    }               
                </div>
                
                <div>
                    <IconButton edge="start" onClick={() => this.setState({showAdvancedSearch: !this.state.showAdvancedSearch})}>
                        {advancedIcon}
                    </IconButton> 
                    <Typography variant="button">Advanced Search</Typography>                  
                </div>
                <Collapse in={this.state.showAdvancedSearch}> 
                    <Paper style={this.styles.paper}>
                        
                        <Typography variant="body2" style={this.styles.paperLabel}>Search Applications</Typography>  

                        <Grid container direction="row" spacing={3} style={{padding: 20}}>

                            <Grid item md={4} sm={6} xs={12}>

                                <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                   dateFormat={null}
                                                   timeOptions={this._searchTimeRangeOptions}
                                                   minYear={2020}
                                                   ref={this._dateRangeRef}
                                                   initialTimeRange="Year to Date"
                                                   initialStartDate={this.state.searchFromDate}
                                                   initialEndDate={this.state.searchToDate}
                                                   onDateChange={this._dateChangeCallback}
                                                   onParseError={this._dateParseError}/>

                            </Grid>


                            <Grid item md={4} sm={6} xs={12}>

                                <div style={{width: '100%'}}>  

                                    <Autocomplete
                                        size='small'
                                        value={this.state.groupToSearch}
                                        onChange={this._groupToSearchChanged}
                                        inputValue={this.state.groupToSearchValue}
                                        onInputChange={(event, newValue) => { this.setState({groupToSearchValue: newValue}); }}
                                        options={this._availableGroups}
                                        blurOnSelect
                                        renderInput={(params) => <TextField {...params} label="Community" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                    /> 

                                    <ManageTextField style={{marginTop: 20}} autoAccept={true} fullWidth={true} justify='left' label="Patron Data"                           
                                                    tooltip="Search on Patron-entered text on any Application field or the submitter's email address"
                                                    json="patronData"
                                                    onFieldChange={(json, val) => {console.log("text: " + val); this.setState({patronDataToSearch: val})}} changedBackgroundColor='white'/>

                                    <ManageTextField style={{marginTop: 20}} autoAccept={true} fullWidth={true} justify='left' label="Journal Data"                           
                                                    tooltip="Search for matching text in a Journal Entry, including author, activity, and notes"
                                                    json="journalData"
                                                    onFieldChange={(json, val) => this.setState({journalDataToSearch: val})} changedBackgroundColor='white'/>

                                </div>                                    
                            </Grid>

                            <Grid item md={4} sm={12} xs={12}>
                                <ManageBitwiseCheckboxes style={{marginTop: -17}} json="stateCheckboxes" label="States" labels={this._checkboxLabels} onChange={this._checkboxStateChanged} initialValue={this.state.stateCheckboxes} />
                                
                                <Tooltip title="Match only those that have been escalated to AGS support">
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 10, marginLeft: 0}}>
                                            <Checkbox checked={this.state.searchEscalated} color='primary'
                                                        onChange={(event) => { this.setState({searchEscalated: event.target.checked})}}/>   
                                            <Typography variant='body2' align='left' style={{color: 'gray'}}>Only Escalated</Typography>   
                                    </div>
                                </Tooltip>
                                <Tooltip title="Match only those from full service communities">
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: 0, marginTop: -5}}>
                                            <Checkbox checked={this.state.searchFullService} color='primary'
                                                        onChange={(event) => { this.setState({searchFullService: event.target.checked})}}/>   
                                            <Typography variant='body2' align='left' style={{color: 'gray'}}>Only Full Service Communities</Typography>   
                                    </div>
                                </Tooltip>
                                <Tooltip title="Match only applications">
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: 0, marginTop: -5}}>
                                            <Checkbox checked={this.state.searchApplicationsOnly} color='primary'
                                                        onChange={this._matchOnlyApplications}/>   
                                            <Typography variant='body2' align='left' style={{color: 'gray'}}>Only Applications</Typography>   
                                    </div>
                                </Tooltip>
                                <Tooltip title="Match only support requests">
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: 0, marginTop: -5}}>
                                            <Checkbox checked={this.state.searchSupportRequest} color='primary'
                                                        onChange={this._matchOnlySupportRequests}/>   
                                            <Typography variant='body2' align='left' style={{color: 'gray'}}>Only Support Requests</Typography>   
                                    </div>
                                </Tooltip>
                                {Global.user.isSiteEmployee() ?
                                    <Tooltip title="Only show Communities that I am an Account Representative for (available when no specific Community selected)">
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: 0, marginTop: -5}}>
                                                <Checkbox checked={this.state.representativeForOnly} color='primary'
                                                          disabled={this.state.groupToSearch ? true : false}
                                                          onChange={this._matchOnlyRepresentativeFor}/>   
                                                <Typography variant='body2' align='left' style={{color: 'gray'}}>Only My Communities (Representative)</Typography>   
                                        </div>
                                    </Tooltip>
                                    : null
                                }
                           </Grid>

                        </Grid>
                        <div style={{display: 'flex', justifyContent: 'center'}}>

                            <Tooltip title="Search and Display Applications below">
                               <Button fullWidth onClick={this._advancedSearch} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                   Search
                               </Button>
                            </Tooltip>

                            <Tooltip title="Search and Download Applications">
                               <Button fullWidth onClick={() => this.setState({showExportTypeQuestion: true})} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<GetAppIcon/>}>
                                   Download
                               </Button>
                            </Tooltip>
                        </div>

                    </Paper>
                </Collapse>
                
                <div style={{marginTop: 15}}/>
                
                {page ?
                     <div>   
                     
                        <table style={this.styles.table}>
                            <thead>
                               {this._renderRow(null, 0) /*render header*/ }
                            </thead>
                            <tbody>
                                {page.applications.map((application, index) => this._renderRow(application, index+1))}
                            </tbody>
                        </table>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" style={{color: ThemeColors.darkGray}}>{showing}</Typography> 
                            <div style={{marginLeft: 'auto'}}>
                                {!page.isFirst() ? <Button onClick={this._prevPage} variant="outlined" color='primary' component="label" >
                                               Prev
                                           </Button>
                                           : null}

                                {!page.isLast() ? <Button onClick={this._nextPage} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                                               Next
                                           </Button>
                                           : null}
                            </div>            
                        </div>                        

                    </div>
                    
                    
                : null}
                
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(ApplicationsListPage));

