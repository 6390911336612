import { Tooltip } from '@material-ui/core'

import { DateUtils } from 'react-frontend-utils'
import { JournalEntry } from './JournalEntry'

import { ThemeColors } from '../Theme'
import { Global } from './Global'
import { interpretArgument } from '../calculations/FieldData';
import { fieldCalculator } from '../calculations/FieldCalculator';


const DATE_WHEN_RESYNC_AVAILABLE = new Date("2024-06-21T00:00:00Z");

export class Application {

    static State = {

        CREATED:        {enumName: "CREATED", bit: 0, label: "Created", backgroundColor: ThemeColors.cancelGray},  
        SUBMITTED:      {enumName: "SUBMITTED", bit: 1, label: "Submitted", backgroundColor: ThemeColors.submittedBlue},
        CLAIMED:        {enumName: "CLAIMED", bit: 2, label: "Claimed", backgroundColor: ThemeColors.claimedPurple},
        ON_HOLD:        {enumName: "ON_HOLD", bit: 3, label: "On Hold", backgroundColor: ThemeColors.onHoldOrange},
        PROCESSED:      {enumName: "PROCESSED", bit: 4, label: "Processed", backgroundColor: ThemeColors.processedGreen},
        DECLINED:       {enumName: "DECLINED", bit: 5, label: "Declined", backgroundColor: ThemeColors.declinedRed}          
    };
    
    
      
    id;
    type;               //Class type, for Jackson
    isCopy;

    submitDate;         //Converted to local
    patronTzOffset;     //Patron timezone, in min west
    patronIPAddress;
    patronIPLocation;
    submitterEmail;       
    group;      
    currency;
    calculatedPaymentAmount;
    paymentIntent;
    paidOn;
    refundedAmount;
    originallyReviewedBy;
    syncedMembership;

    testing;
    
    stateEnum;
    previousStateEnum;
    agsEscalation;
    isFullService;

    isSupportRequest;
    originalApplicationID;
    isPaper;
    paperPaymentAmount;
    checkInfo;

    claimedBy;
    claimerName;
    claimedAt;          //Converted to local
    fetchDate;

    fieldTree;
    fieldCalculations;

    hmac;
    journal;
  
    instructions;
    hasMerge;
    canSyncWithPP;
    communityURL;
    extendedCommunityName;

    hasFlag;  //internal

    constructor(json, forPatron = false) {
        if (json) {
            
            this.id = json.id;
            this.type = json.type;
            this.isCopy = json.isCopy;
            if (json.submitDate) {
                this.submitDate = DateUtils.parseJsonDate(json.submitDate, true); //convert from UTC
            }
            if (json.patronTzOffset)
                this.patronTzOffset = json.patronTzOffset;
            else
                this.patronTzOffset = 0;
            
            this.patronIPAddress = json.patronIPAddress;
            this.patronIPLocation = json.patronIPLocation;
            this.submitterEmail = json.submitterEmail;
            this.group = json.group;
            this.testing = json.testing;
            this.currency = json.currency;
            this.calculatedPaymentAmount = json.calculatedPaymentAmount;
            this.paymentIntent = json.paymentIntent;

            if (json.paidOn)
                this.paidOn = DateUtils.parseJsonDate(json.paidOn, true); //convert from UTC

            this.refundedAmount = json.refundedAmount;

            this.originallyReviewedBy = json.originallyReviewedBy;
            this.syncedMembership = json.syncedMembership;

            if (json.state) {
                this.stateEnum = Application.stateFromString(json.state);    
            }
            if (json.previousState)
                this.previousStateEnum = Application.stateFromString(json.previousState);
            
            this.agsEscalation = json.agsEscalation;
            this.isFullService = json.isFullService;
            this.isSupportRequest = json.isSupportRequest;
            this.originalApplicationID = json.originalApplicationID;
            this.isPaper = json.isPaper;
            this.paperPaymentAmount = json.paperPaymentAmount;
            this.checkInfo = json.checkInfo;

            this.claimedBy = json.claimedBy;
            this.claimerName = json.claimerName;
            if (json.claimedAt) {
                this.claimedAt = DateUtils.parseJsonDate(json.claimedAt, true); //convert from UTC
            }
            else
                this.claimedAt = null;

            this.fetchDate = json.fetchDate;  //don't convert to date, this should be passed back to the backend unchanged
            
            this.hmac = json.hmac;
            if (json.journal) {
                this.journal = json.journal.map(entry => new JournalEntry(entry));        
            }
            else
                this.journal = []; 

            for (const entry of this.journal) {
                if (entry.activity.startsWith("Flagged to")) {
                    this.hasFlag = true;
                    break;
                }
            }
            
            this.instructions = json.instructions;
            this.hasMerge = json.hasMerge;
            this.canSyncWithPP = json.canSyncWithPP;
            this.communityURL = json.communityURL;
            this.extendedCommunityName = json.extendedCommunityName;
            
            if (json.fieldTree) {  //only populated when individual Application is pulled
                this.fieldTree = json.fieldTree;
                this.fieldTree.isBase = true;           //mark this as the base of the tree
            }
            
            this.fieldCalculations = json.fieldCalculations;
                        
        }
    }


    claimable() {
        switch (this.stateEnum) {
            case Application.State.PROCESSED:
                if (this.submitDate > DATE_WHEN_RESYNC_AVAILABLE)
                    return true;
                else
                    return false;
            case Application.State.SUBMITTED:
            case Application.State.ON_HOLD:
            case Application.State.DECLINED:
                return true;
               
            default:
                return false;
        }
    }

    escalateable() {
        switch (this.stateEnum) {  //these states cannot be escalated
            case Application.State.PROCESSED:
            case Application.State.CREATED:
                return false;
            default:
                break;
        }

        return !this.agsEscalation;  //can escalate if we are not escalated
    }
    
    claimedByUser() {
        return this.stateEnum === Application.State.CLAIMED && this.claimedBy === Global.user.id;
    }
    
    isTooLarge() {
        const json = this.toJsonForPost();
        if (json.length > 15 * 1024 * 1024)  //15 MB
            return true;
        else
            return false;
    }
    
    isMissingData() {
        return fieldMissingData(this.fieldTree);
    }
    hasErroredField() {
        return fieldErrored(this.fieldTree);
    }

    //Conver to JSON for posting, removes items that are not allowed by backend
    toJsonForPost() {
        
        this.id = null; 
        this.submitDate = null; //will not be used by backend      
        this.stateEnum = null;  //not used by backend
        this.previousStateEnum = null;  //not used by backend
        this.journal = null; 
                
        return JSON.stringify(this);
    }
    
    //Find the field in the Application field tree. The tree is assumed to be for the Application, so that the CloningFieldGroup will have children, just like the
    //FieldGroup. The template of a CloningFieldGroup is ignored.
    //If the field is found, returns an object with the found fields parent and the found field: {parent, field}. If not found, return null.
    static findFieldByName(parent, fieldNameToFind) {
                
        switch (parent.type) {

            case "FieldGroup":
            case "PagedFieldGroup":
            case "CloningFieldGroup":
               for (let child of parent.children) {   //search the direct children
                    if (Application.uniqueFieldName(child) === fieldNameToFind)
                        return {parent: parent, field: child};
                    
               }
               for (let child of parent.children) {   //recurse into the children
                    const result = Application.findFieldByName(child, fieldNameToFind);
                    if (result)
                        return result;
               }
               return null;  //not found in children
                          
            default: //do nothing for other types
                return null;
        }
        
    }
    
    /**
     * Finds the parent of the supplied field. If the supplied field is the fieldTree base, then it returns itself.  Returns null if not found.
     * @param {type} fieldTree the base of the field tree
     * @param {type} field the field whose parent should be found
     * @returns {Object} the parent, or null if not found
     */
    static parentOf(field, fieldTree) {
        if (field === fieldTree) //already at root of tree
            return fieldTree;
        
        const fieldSet = Application.findFieldByName(fieldTree, Application.uniqueFieldName(field));
        if (!fieldSet)
            return null;
        return fieldSet.parent;
    }
    
    
    
    //get a unique name - only unique with suffix (because of cloning)
    static uniqueFieldName(field) {
        if (field.name)
            return field.name + (field.cloneSuffix ? (field.cloneSuffix) : "");
        else
            return "Base";
    }
    
    
    //Return an array of all fields that start with the supplied prefix
    static findAllFieldsNamed(fieldPrefixToFind, fieldBase) {
                
        const findFieldsMatching = (parent, fieldPrefixToFind, list) => {
            
            if (parent.name === fieldPrefixToFind)
                list.push(parent);
         
            switch (parent.type) {

                case "FieldGroup":
                case "PagedFieldGroup":
                case "CloningFieldGroup":
                   for (let child of parent.children)   //search the direct children
                        findFieldsMatching(child, fieldPrefixToFind, list);
                    break;
                        
                default: //do nothing for other types
                    return;
            }
        };
        
        const list = [];
        findFieldsMatching(fieldBase, fieldPrefixToFind, list);
        return list;
    }
    
    
    
    
    /**
     * Clone the template from a CloningGroupField, adding it to its children and updating the clone's names to be unique
     * @param {Object} cloningGroupField the field to clone
     */
    static cloneTemplate(cloningGroupField) {
        
        if (cloningGroupField.type !== "CloningFieldGroup") {
            console.error("Invalid type to clone");
            return;
        }
        
        //Clone the template
        const clone = JSON.parse(JSON.stringify(cloningGroupField.template));
                
        setCloneSuffix(clone, Application.uniqueFieldName(cloningGroupField), cloningGroupField.children.length);  //give each clone a unique suffix (add one to the number of clones)
        
        cloningGroupField.children.push(clone);
    }
    
    static stateFromString(str) {
        for (let index in Application.State) {
            const state = Application.State[index];

            if (state.enumName === str) {
                return state;
            }
        }  
        return null;
    }
    
    
    //Render the Journal Entries
    renderJournal() {
        
        const stateChangeActivity = (entry) => {
            if (entry.activity.startsWith("Released to ")) {
                const stateReleased = Application.stateFromString(entry.activity.substring("Released to ".length));
            
                return <div style={{display: 'flex'}}>
                            Released to
                            <div style={{marginLeft: 5, paddingLeft: 3, paddingRight: 5, backgroundColor: stateReleased.backgroundColor, color: 'white', textTransform: 'uppercase'}}>
                                {stateReleased.label}
                            </div>
                        </div>;
            }
            else
                return <div>{entry.activity}</div>;
        };
        
        const flag = (entry) => {
            if (entry.activity.startsWith("Flagged to")) 
                return <div style={{fontSize: 24, color: 'blue', marginTop: -6, marginLeft: 10, padding: -4}}>⚑</div>         
            else
                return null;
        }

        const escalated = (entry) => {
            if (entry.activity.startsWith("Escalated to Access Granted Support"))
                return <div style={{fontSize: 14, fontWeight: 'bold', marginLeft: 7}}>!</div>         
            else
                return null;
        }
        
        return (<div style={{marginLeft: 40, marginRight: 40}}>
                    {this.journal.map((entry, index) => {
                        return (
                            <div key={index} style={{backgroundColor: ThemeColors.notifyBackground, marginBottom: 2, padding: 5, borderRadius: 3}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 200}}>{DateUtils.timeFormat(entry.date)}</div>
                                    <div style={{width: 200}}>{entry.author}</div>
                                    {stateChangeActivity(entry)}
                                    {flag(entry)}
                                    {escalated(entry)}
                                </div>
                                <div style={{fontStyle: 'italic', fontSize: 12, marginLeft: 400}}>{entry.note ? "Notes: " + entry.note : null}</div>
                            </div>
                        );
                    })}
                </div>);
    }

    static badgeStyle = {
        testing: {
            alignContent: 'center',
            width: 14,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: '50%', 
            color: 'white', 
            textAlign: 'center',
            fontSize: 12,
            height: 14
        },
        copy: {
            alignContent: 'center',
            width: 14,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: '50%', 
            color: 'black', 
            textAlign: 'center',
            fontSize: 12,
            height: 14
        },
        flag: {
            alignContent: 'center',
            width: 14,
            borderRadius: '50%', 
            paddingLeft: 2,
            paddingRight: 2, 
            textAlign: 'center',
            fontSize: 14, 
            color: 'white',
            height: 18
        },
        escalate: {
            alignContent: 'center',
            width: 14,
            borderRadius: '50%', 
            paddingLeft: 2,
            paddingRight: 2, 
            textAlign: 'center',
            fontSize: 14, 
            fontWeight: 'bold',
            color: 'white',
            height: 18
        },
        supportRequest: {
            alignContent: 'center',
            borderRadius: '30%', 
            paddingLeft: 2,
            paddingRight: 3, 
            textAlign: 'center',
            fontSize: 14, 
            color: 'white',
            height: 18
        },
        fullService: {
            alignContent: 'center',
            borderRadius: '10%', 
            paddingLeft: 3,
            paddingRight: 3, 
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 'bold', 
            color: 'white',
            height: 18
        },
        paper: {
            alignContent: 'center',
            paddingLeft: 0,
            paddingRight: 0, 
            textAlign: 'center',
            transform: 'rotate(100deg)',
            fontSize: 20,
            fontWeight: 'bold', 
            color: 'brown'
        }
    };


    renderBadges(justify = 'left') {
        return <div style={{display: 'flex', justifyContent: justify, gap: 3}}>
                    <Tooltip title={this.isFullService ? "This belongs to a full service Community" : ""}>
                        <div style={{...Application.badgeStyle.fullService, backgroundColor: this.isFullService ? 'gray': 'white'}}>
                            {this.isFullService ? "F" : ""}
                        </div>
                    </Tooltip>
                    <Tooltip title={this.isSupportRequest ? "This is a support request" : ""}>
                        <div style={{...Application.badgeStyle.supportRequest, backgroundColor: this.isSupportRequest ? ThemeColors.helpCyan : 'white'}}>
                            {this.isSupportRequest ? "⁈" : ""}
                        </div>
                    </Tooltip>
                    <Tooltip title={this.hasFlag ? "This has been flagged as needing attention from the Community" : ""}>
                        <div style={{...Application.badgeStyle.flag, backgroundColor: this.hasFlag ? 'blue' : 'white'}}>
                            {this.hasFlag ? "⚑" : ""}
                        </div>
                    </Tooltip>
                    <Tooltip title={this.agsEscalation ? "This has been escalated to Access Granted support" : ""}>
                        <div style={{...Application.badgeStyle.escalate, backgroundColor: this.agsEscalation ? 'black' : 'white'}}>
                            {this.agsEscalation ? "!" : ""}
                        </div>
                    </Tooltip>
                    <Tooltip title={this.isPaper ? "This is a paper submission" : ""}>
                        <div style={{...Application.badgeStyle.paper}}>
                            {this.isPaper ? "✎" : ""}
                        </div>
                    </Tooltip>
                    <Tooltip title={this.testing ? "This is a test submission" : ""}>
                        <div style={{...Application.badgeStyle.testing, backgroundColor: this.testing ? 'red' : 'white'}}>
                            {this.testing ? "T" : ""}
                        </div>
                    </Tooltip>
                    <Tooltip title={this.isCopy ? "This is a copy" : ""}>
                        <div style={{...Application.badgeStyle.copy, backgroundColor: this.isCopy ? 'orange' : 'white'}}>
                            {this.isCopy ? "C" : ""}
                        </div>
                    </Tooltip>
                </div>;
    }
    
    
    
    /**
     * Recursively walk the tree and perform any calculations for determining whether or not to hide/show the field
     * @param {Object} field to work on recursively, if not provided, start at the base
     * @param {Function} getOtherResults is an function that returns an Array of objects containing other expression results, in case we reference them.  {name, result}
     */
    showHideFields(field, getOtherResults) {
        
        if (!field)  //initially, start at root
            field = this.fieldTree; 
    
        if (field.postfixHiddenLogic) { //has a calculation - the postfixHiddenLogic is computed for the Application by the backend  

            try {

                const result = fieldCalculator(field.postfixHiddenLogic, this.fieldTree, getOtherResults, field);
                const conditionalApplies = interpretArgument(result, "NUMBER", this.fieldTree);
                
                const wasHidden = field.hidden;

                if (conditionalApplies)
                    field.hidden = !field.initiallyHidden;
                else
                    field.hidden = field.initiallyHidden;

                //if the field is going hidden, then reset the patron data to the initial default data
                if (!wasHidden && field.hidden) {
                    field.patronData = field.initialPatronData;

                    if (field.type ===  "CloningFieldGroup")    // remove clones if field hidden
                        field.children.length = 0;
                }


            } catch (error) {
                throw new Error("Field \"" + field.name + "\": " + error.message);
            }
        }
        
        if (field.type === "FieldGroup" || field.type === "PagedFieldGroup" || field.type ===  "CloningFieldGroup") {
            for (let child of field.children)
                this.showHideFields(child, getOtherResults);
            
        }
        
    }
    
    /**
     * Recursively walk the tree and set the calculations to display (any fields that are CalcResultFields)
     * @param {Object} field to work on recursively, if not provided, start at the base
     */
    setCalculationResults(field) {
        
        if (!field)  //initially, start at root
            field = this.fieldTree; 
    
        if (field.type === "CalcResultField") { 
         
            const resultName = field.calculationName;
            field.patronData = null;
            
            for (const calc of this.fieldCalculations) {
                if (calc.resultName === resultName) {
                    field.patronData = calc.result === null ? "ERROR: " + calc.error : calc.result;
                    break;
                }
            }
            
            if (field.patronData === null) //not found
                field.patronData = "ERROR: Calculation result undefined";
            
        }
        
        if (field.type === "FieldGroup" || field.type === "PagedFieldGroup" || field.type ===  "CloningFieldGroup") {
            for (let child of field.children)
                this.setCalculationResults(child);
            
        }
        
    }

}




/**
 * Recursively set the cloneSuffix based on the template name and clone number, to make each clone uniquely identifiable
 * @param {Object} clone the field that was cloned, to update
 * @param {String} parentSuffix the suffix on the parent
 * @param {Integer} cloneNumber which clone this is (0, 1, 2...)
 */
function setCloneSuffix(clone, parentSuffix, cloneNumber) {
    
   
    
    clone.cloneSuffix = "." + cloneNumber + "." + parentSuffix;
        
    clone.label += " (" + (cloneNumber+1) + ")";
    
     switch (clone.type) {

        case "FieldGroup":
            for (let child of clone.children) {
                setCloneSuffix(child, parentSuffix, cloneNumber);
            }
            break;
        case "CloningFieldGroup":

            setCloneSuffix(clone.template, parentSuffix, cloneNumber);
            for (let child of clone.children) {
                setCloneSuffix(child, Application.uniqueFieldName(clone), cloneNumber);  //send the child template's name
            }
            break;
        default: //do nothing for other types
    }
 
}





/**
 * Recursively check to see if data is missing.  Data is missing if patronData is missing and field is required and the field is not hidden
 * @param {Object} field the field to check
 */
function fieldMissingData(field) {
    
     switch (field.type) {
        case "CloningFieldGroup":
            if (!field.hidden && field.children < field.minChildren)
                return true;
            
            //fallthrough
        case "FieldGroup":

            if (field.hidden)  //if this group is hidden, then don't descend into or check any of its children, because they are hidden too
                return false;

            for (let child of field.children) {
                if (fieldMissingData(child))
                    return true;
            }
            break;

        default: 
            if (field.required && !field.hidden && (!field.patronData || field.patronData.trim() === "" || field.hasMissingData)) {
                console.log("Field " + field.name + " missing required data");
                return true;
            }
            return false;
    }
 
}



/**
 * Recursively check to see if data is errored in a field.  Data is errored if the field's hasError property is true
 * @param {Object} field the field to check
 */
 function fieldErrored(field) {
    

    switch (field.type) {
       case "CloningFieldGroup":
       case "FieldGroup":
           for (let child of field.children) {
               if (fieldErrored(child))
                   return true;
           }
           break;

       default: 
           return field.hasError;
   }

}