import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { Tooltip, Typography, Button, Checkbox } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { RestComponent, multilineJSX } from 'react-frontend-utils' 
import { ThemeColors } from '../Theme'


// 
// SYNC PAGE 3 - Receives the Sync Data from Page 2, and passes a Server Data object to Page 4
// 
//   Sync Data object from Page 2:  
//   {
//      membership:          //Poolpass Membership data
//      replace:             //true if we're replacing the entire Membership
//      household:           //extracted Household data from Application
//      changingMembers:     //array of pruned Member objects
//      newMembers:          //array of pruned Applicant objects, that will be added to the Membership
//      abandonedApplicants: //number of applicants left abandoned
//      unchangedMembers:    //count of Members left unchanged
//      finalActions:        //PPSyncFinalActions object (passed through to Page 4)
//   }
//   
//   
//   Server Data object to Page 4:
//   
// {
//      membership  - Poolpass Membership data (null for new)
//      householdAddress - address from the household
//      email - email from the household
//      replace  - true to replace this Membership
//      membershipFieldsToSync - array of fields names that are to be synced
//      changingMembers:   array of pruned Member objects
//      newMembers - array of pruned Applicant objects, that will be added to the Membership
//      deleteMembersCount - number of members to delete
//      abandonedApplicants - passed through from Page 2
//      unchangedMembers - passed through from Page 2
//      finalActions - PPSyncFinalActions object (passed through from Page 2)
//  }
//
//

export class SyncPage3 extends RestComponent {

    styles = {
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 16,
            paddingTop: 10,
            paddingBottom: 10,
            borderBottom: '1px solid lightGray'
        }
    };


    _id;             //Application id 
    
    _nextPageCallback;
    _prevPageCallback;
    
    
    _fieldsToSync = [ {fieldName: "address",            label: "Address",           sync: true}, 
                      {fieldName: "primaryPhone",       label: "Primary Phone",     sync: true},
                      {fieldName: "secondaryPhone",     label: "Alternate Phone",   sync: true},
                      {fieldName: "email",              label: "Email",             sync: true},
                      {fieldName: "type",               label: "Membership Type",   sync: true},
                      {fieldName: "emergencyContact",   label: "Emergency Contact", sync: true},
                      {fieldName: "notes",              label: "Notes",             sync: false}
                    ];
    
    constructor(props) {
        super(props);
    
        this._id = props.id;        //application id
        this.isSupport = props.isSupport;

        if (this.isSupport) {   
            const hasExisting = props.syncData.membership && !props.syncData.replace;
            if (hasExisting) {  //default unchecked for support if existing membership
                for (let field of this._fieldsToSync)
                    field.sync = false; 
            }
        }
        
        // When a field is undefined (not in the household data) then this means it was not a field in the Application. So we won't sync it.
        this._fieldsToSync = this._fieldsToSync.filter(element =>  (props.syncData.household[element.fieldName] !== undefined));

        this._nextPageCallback = props.nextPageCallback;  //callback that moves to Page 4
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to Page 2
        
        this.state.syncData = props.syncData;           
        
    }
    
    componentDidMount() {
        super.componentDidMount(); 
        
        // Browser back button - go back
        window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }
    
    
    _continue = () => {
        
        //Filter out any fields that won't be synced
        let membershipFieldsToSync = this._fieldsToSync.filter(field => field.sync);
        
        //Just keep the names
        membershipFieldsToSync = membershipFieldsToSync.map(field => field.fieldName); 
        
        const serverData = {membership: this.state.syncData.membership,
                            householdAddress: this.state.syncData.household.address,
                            email: this.state.syncData.household.email,
                            replace: this.state.syncData.replace,
                            membershipFieldsToSync: membershipFieldsToSync, 
                            changingMembers: this.state.syncData.changingMembers, 
                            newMembers: this.state.syncData.newMembers,
                            abandonedApplicants: this.state.syncData.abandonedApplicants,
                            unchangedMembers:  this.state.syncData.unchangedMembers,
                            finalActions: this.state.syncData.finalActions
                           };
        
        this._nextPageCallback(serverData);
    }
    
    
    
   _selectFieldForSync = (field) => {
        field.sync = !field.sync;
        this.forceUpdate();
   }
  
   _renderRow = (field, index) => {

        // if we have Membership data and we aren't replacing, then we have existing data
        const hasExisting = this.state.syncData.membership && !this.state.syncData.replace;
           
        const existingData = hasExisting ? this.state.syncData.membership[field.fieldName] : null;
        const newData = this.state.syncData.household[field.fieldName];

       let resultAfterSync;
       if (field.sync)  //if syncing - always use new household data
            resultAfterSync = newData;
       else if (hasExisting)  //not syncing, use existing membership data if we aren't replacing
           resultAfterSync = existingData;
       else //no membership
           resultAfterSync = "";
           
       const width = hasExisting ? '27%' : '40%';

       return   <tr key={index} style={this.styles.tableStyle}>

                    <td style={this.styles.tableDataWithBorder}>
                        <Tooltip title="Select to transfer field data from Application to Membership">
                            <Checkbox checked={field.sync} color='primary' size='small' onChange={() => this._selectFieldForSync(field)}/>                                  
                        </Tooltip>
                    </td>

                    <td style={this.styles.tableDataWithBorder}>{field.label}</td>
                    {hasExisting ?
                        <td style={{...this.styles.tableDataWithBorder, width: width}}>{multilineJSX(existingData)}</td>
                        : 
                        null
                    }
                    <td style={{...this.styles.tableDataWithBorder, width: width}}>{multilineJSX(newData)}</td>
                    <td style={{...this.styles.tableDataWithBorder, width: width, color: 'green',}}>{multilineJSX(resultAfterSync)}</td>
                </tr>; 
   }
  
    
    render() {
       

        let message;
        if (!this.state.syncData.membership)  //new Membership from Application
            message = "You are creating a new Membership from this Application. Select the fields you want transfered from the Application to the Membership.";
        else 
            message = "Select the fields you want transfered from the Application to the Membership. Unselecting the field preserves the Membership data. If you select an Application field whose data is blank, " +
                      "the corresponding Membership field will be cleared.";
        
        return (
            <Fragment>
                {this.getConfirmAlertComponent()}
                
                <div style={{display: 'flex', float: 'right', marginRight: 10, marginBottom: 20, marginLeft: 20}}>
                   <Tooltip title={"Go back to select Members again"}>
                        <Button variant='outlined' style={{borderColor: ThemeColors.darkGray, color: ThemeColors.darkGray, marginRight: 10, width: 200}}
                                onClick={this._prevPageCallback} startIcon={<ArrowBackIosIcon/>}>
                            Go Back
                        </Button>
                   </Tooltip> 
                   <Tooltip title="Continue to Sync">
                        <Button variant='outlined' style={{borderColor: 'green', color: 'green', marginRight: 10, width: 200}}
                                onClick={this._continue} endIcon={<ArrowForwardIosIcon/>}>
                            Continue
                        </Button>
                   </Tooltip> 
                   
                </div>
                                    
                <Typography variant="body1" style={{marginBottom: 20, color: ThemeColors.mediumGray}}>{message}</Typography>  


                <table style={this.styles.table}>
                    <thead>
                        <tr style={this.styles.tableStyle}>
                            <th style={{...this.styles.tableHeader, width: '30px'}}/>
                            <th style={this.styles.tableHeader}>Field</th>
                            {this.state.syncData.membership && !this.state.syncData.replace ? <th style={this.styles.tableHeader}>Existing Membership Data</th> : null}
                            <th style={this.styles.tableHeader}>Application Data</th>
                            <th style={this.styles.tableHeader}>Membership Data after Sync</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this._fieldsToSync.map((field, index) => this._renderRow(field, index))}
                    </tbody>
                </table>
                
            </Fragment>    
        );
    }
    
    

}


export default withCookies(withRouter(SyncPage3));
