
import { Application } from './Application'

export class PagedApplicationList {
    
    
    pageNo;
    isLastPage;
    
    index;
    
    applications;
    
    //Construct from the backend Json Response
    constructor(json) {
        if (json) {
            this.isLastPage = json.last; 
            this.pageNo = json.pageNo; 
            this.index = json.index;
            
            if (json.items)
                this.applications = json.items.map(item => new Application(item));
            else
                this.applications = [];
            
        }  
    } 
    
    isFirst = () => {
        return this.pageNo === 0;
    }
    
    isLast = () => {
        return this.isLastPage;
    }
    
}