import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { Tooltip, Grid, Typography, Paper, TextField, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeColors } from '../Theme'
import { Global } from '../models/Global'

import { RestComponent } from 'react-frontend-utils' 

import { DateUtils, DateRangeSelector } from 'react-frontend-utils'

import Chart from "react-apexcharts";


const MAX_BACKLOG_DAYS = 60;

export class StatisticsPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 30
        }
    };
    
    
    static currentYear = (new Date()).getFullYear();

    
     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "Today", startTime: DateUtils.startOfToday().valueOf()},
        {label: "Last 2 days", startTime: DateUtils.startOfToday().valueOf() - (2 * DateUtils.MS_PER_DAY)},
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
        {label: "Previous Year", startTime: DateUtils.startOfYear(StatisticsPage.currentYear-1).valueOf(), endTime: DateUtils.lastDayOfYear(StatisticsPage.currentYear-1).valueOf()},
        {label: "Any", startTime: null}
    ];
    

    _datetimeFormatter = () => {
        return {
            year: 'yyyy',
            month: 'MMM yyyy',
            day: "MMM dd"
        };
    }
    
    _infoWidget =  <Typography variant="body2" style={{fontSize: 12, fontWeight: 'bold', color: 'blue', marginLeft: 8}}>ⓘ</Typography>;
  
    _standardChartOptions = {
        stacked: false,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 600,
            animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
    };
    
    _dailySubmissionsChartOptions = {
      
        chart: {...this._standardChartOptions, stacked: true},
        colors: [ThemeColors.membersBlue, ThemeColors.helpCyan], 
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "90%"
            }
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                datetimeFormatter: this._datetimeFormatter()
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        },
        tooltip: {
            x: {
                format: "yyyy MMM dd"
            },
            y: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        }
    };

    _backlogChartOptions = {
      
        chart: this._standardChartOptions,
        colors: [ThemeColors.memberRed], //red
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "90%"
            }
        },
        markers: {
            size: 2,
            strokeWidth: 0
        },
        dataLabels: {
            enabled: false         
        },
        xaxis: {
            type: 'numeric',
            labels: {
                formatter: (value) => {
                    value = value-1;  //bar chart is 1-based
                    return value >= MAX_BACKLOG_DAYS ? (">=" + MAX_BACKLOG_DAYS) : value
                }
            },
            title: {text: "Days since submission"}
        },
        yaxis: {
            labels: {
                formatter: (value) => { return value.toFixed(0); }  //Format as integer
            }
        },
        tooltip: {
            x: {
                formatter: (day) => {
                    day = day-1;  //bar chart is 1-based
                    return (day >= MAX_BACKLOG_DAYS ? ">= " : "") + day + " day" + (day === 1 ? "" : "s") + " since submission"
                }
            },
            y: {
                formatter: (count) => count + " Application" + (count === 1 ? "" : "s")
            }
        }
    }

    _processedByPieChartOptions = {
      
        chart: this._standardChartOptions,
        labels: [],
        colors: ThemeColors.pieChartColors,
        legend : {
            show: true,
            formatter: function (seriesName, opts) {
                return opts.w.config.labels[opts.seriesIndex] + " (" + opts.w.config.series[opts.seriesIndex] + ")";  //use the count value and the percent
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.labels[opts.seriesIndex] + " (" + val.toFixed(0) + "%)";  //use the count value and the percent
            }
        }
    }
   

    _availableGroups;

    constructor(props) {
        super(props);
               
        this.state.searchFromDate = DateUtils.jsonDateString(DateUtils.startOfYear(), false);           //JsonDateString or null
        this.state.searchToDate = null;                                                                 //JsonDateString or null
        
        this.state.groupToSearch = null;            //Specific group to search for
        this.state.groupToSearchValue = null;

        this._availableGroups = Global.user.databases;

        this.state.dailySubmissionsChartData = null;  
        this.state.backlogChartData = null;
        this.state.processedByPieData = null;
        this.state.totalApplicationCount = 0;
        this.state.totalSupportRequestCount = 0;
        this.state.totalBacklogged = 0;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the first page of applications
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isSmall: window.innerWidth < 1200 });  
    }
    
    
    _fetchStats = () => {
        
        let queryString = "?";
        
        if (this.state.groupToSearch)
            queryString += "group=" + this.state.groupToSearch + "&";                

        if (this.state.searchFromDate)
            queryString += "fromDate=" + this.state.searchFromDate + "&";
        if (this.state.searchToDate) {
            
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "toDate=" + endOfDayDate + "&";
            
        }
                 
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/stats", {},
                             this._fetchStatsCallback, this._fetchErrorCallback, queryString); 
        
    }
   
    
    //Callback for fetching Application Stats - response is json of ApplicationStats
    _fetchStatsCallback = (response) => {
        if (response) {            

            //Process Daily submissions
            let totalApplicationCount = 0;
            let totalSupportRequestCount = 0;
            const dailyApplicationSubmissionSeries = [];
            const dailySupportRequestSubmissionSeries = [];
            for (let entry of response.dailySubmissions) {
                dailyApplicationSubmissionSeries.push([entry.day, entry.applicationCount]);
                dailySupportRequestSubmissionSeries.push([entry.day, entry.supportRequestCount]);
                totalApplicationCount += entry.applicationCount;
                totalSupportRequestCount += entry.supportRequestCount;
            }            
            const dailySubmissionsChartData = [
                {
                  name: "Daily Application Submissions",
                  data: dailyApplicationSubmissionSeries
                },
                {
                    name: "Daily Support Request Submissions",
                    data: dailySupportRequestSubmissionSeries
                  }
            ];

            //Process backlog
            const backlogSeries = new Array(MAX_BACKLOG_DAYS+1).fill(0);  //pre-allocate array with zero entries
            let maxCount = 0;  // MAX_BACKLOG_DAYS days or over, accumulate to last one
            let totalBacklogged = 0;
            for (let entry of response.backlog) {
                if (entry.daysOld >= MAX_BACKLOG_DAYS)
                    maxCount += entry.count;
                else
                    backlogSeries[entry.daysOld] = entry.count;
                totalBacklogged += entry.count;
            }

            backlogSeries[MAX_BACKLOG_DAYS] = maxCount;  //Add the last

            const backlogChartData = [
                {
                  name: "Processing Backlog",
                  data: backlogSeries
                }
            ];

            //Process processedBy
            const labels = response.processedBy.map(pb => pb.name);  
        
            //Note in order to re-render properly the outermost items must be spread, apex charts needs to see the object changed
            this._processedByPieChartOptions = {...this._processedByPieChartOptions, labels: labels};
            
            //Specify the pie data series
            const processedByPieData = response.processedBy.map(pb => pb.count);
                


            this.setState({dailySubmissionsChartData: dailySubmissionsChartData, 
                            backlogChartData: backlogChartData,
                            processedByPieData: processedByPieData,
                            totalApplicationCount: totalApplicationCount, 
                            totalSupportRequestCount: totalSupportRequestCount,
                            totalBacklogged: totalBacklogged});


        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
 
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    
    
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
                  
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                
                <Paper style={this.styles.paper}>
                    
                    <Typography variant="body2" style={this.styles.paperLabel}>Calculate Statistics</Typography>  

                    <Grid container direction="row" spacing={3} style={{padding: 20}}>

                        <Grid item sm={6} xs={12}>

                            <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                dateFormat={null}
                                                timeOptions={this._searchTimeRangeOptions}
                                                minYear={2020}
                                                ref={this._dateRangeRef}
                                                initialTimeRange="Year to Date"
                                                initialStartDate={this.state.searchFromDate}
                                                initialEndDate={this.state.searchToDate}
                                                onDateChange={this._dateChangeCallback}
                                                onParseError={this._dateParseError}/>

                        </Grid>


                        <Grid item sm={6} xs={12}>

                            <div style={{width: '100%'}}>  

                                <Autocomplete
                                    size='small'
                                    value={this.state.groupToSearch}
                                    onChange={(event, newValue) => { this.setState({groupToSearch: newValue}); }}
                                    inputValue={this.state.groupToSearchValue}
                                    onInputChange={(event, newValue) => { this.setState({groupToSearchValue: newValue}); }}
                                    options={this._availableGroups}
                                    blurOnSelect
                                    renderInput={(params) => <TextField {...params} label="Community" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                /> 

                    

                            </div>                                    
                        </Grid>
                    </Grid>
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                        <Tooltip title="Calculate and Display Statistics below">
                            <Button fullWidth onClick={this._fetchStats} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label">
                                Calculate
                            </Button>
                        </Tooltip>

                    </div>

                </Paper>
                                
                {this.state.isBusy ? this.getBusyComponent('center', {padding: 15}, 30) : <div style={{height: 60}}/>}

                <div style={{marginTop: 15}}/>

                {this.state.dailySubmissionsChartData ? 
                    <div style={{display: this.state.dailySubmissionsChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="button" align='center' style={{fontSize: 16}}>{"Daily Submissions (Totals: " + this.state.totalApplicationCount + " Applications, " +  this.state.totalSupportRequestCount + " Support Requests)"}</Typography>  
                            <Tooltip title="Daily counts of Application/Support Request submissions">
                                {this._infoWidget}
                            </Tooltip>
                        </div>
                        <Chart
                            options={this._dailySubmissionsChartOptions}
                            series={this.state.dailySubmissionsChartData}
                            type="bar"
                            height={window.innerHeight*0.40}
                        />  
                    </div>
                    : null
                }

                <div style={{marginTop: 40}}/>

                {this.state.backlogChartData ? 
                    <div style={{display: this.state.backlogChartData.length > 0 ? 'block' : 'none', cursor: "crosshair"}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="button" align='center' style={{fontSize: 16}}>{"Processing Backlog (" + this.state.totalBacklogged + " Total)"}</Typography>  
                            <Tooltip title="Counts of Applications that are still to be processed, by days since submission">
                                {this._infoWidget}
                            </Tooltip>
                        </div>
                        <Chart
                            options={this._backlogChartOptions}
                            series={this.state.backlogChartData}
                            type="bar"
                            height={window.innerHeight*0.40}
                        />  
                    </div>
                    : null
                }       
                        
                <div style={{marginTop: 40}}/>
                
                {this.state.processedByPieData ?
                    <div style={{marginTop: 40, marginBottom: 40, marginLeft: '10%', marginRight: '10%'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="button" align='center' style={{fontSize: 16}}>Processed By</Typography>  
                        </div>
                        <Chart
                            options={this._processedByPieChartOptions}
                            series={this.state.processedByPieData}
                            type="pie"
                            height={window.innerHeight*0.50}
                        />  
                    </div> : null
                }          
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(StatisticsPage));

