import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { Container, Typography, Paper, Button } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import  PatronCheckout from '../components/PatronCheckout'
import { DocumentUploaderDownloaderComponent } from "../components/DocumentUploaderDownloaderComponent";
import { renderField  } from '../components/FieldRenderer'
import { GenericTextField } from '../components/FieldSubcomponents'
import { refreshApplicationCalculations, computePaymentSum } from '../calculations/FieldCalculator';

import AGTheme, { ThemeColors } from '../Theme'

import { Application } from '../models/Application'
import { Community } from '../models/Community'
import { Email, Currency, ManageDecimalField, RichTextDisplay, draftContentHasText } from 'react-frontend-utils'

export class Patron extends DocumentUploaderDownloaderComponent {
  
   
    
    _group;   //Group the patron requested
    _test;    //Testing requested
    _paper;   //paper submission requested
    _alternate; //Alternate application (support) requested
    _submitDate; //Date when Submit was last pressed
    _origApplicationID; //ID of the Application related to the support request
    _email;     //Passed email for the form
    _internal;  //Using community support template
    _submitType;

    constructor(props) {
        super(props);
    
        this._group = props.group ? encodeURIComponent(props.group) : null;  //Arrives with the query string set to the desired group
        this._test = props.test;                                             //Arrives with the test key
        this._paper = props.paper;                                           //Arrives with the paper key
        this._origApplicationID = props.origApplicationID ? encodeURIComponent(props.origApplicationID) : null;    //Arrives with the original Application ID
        this._alternate = props.alternate;                    //Arrives with the alternate (support) flag set
        this._email = props.email ? decodeURIComponent(props.email) : null;    //Arrives with an email address
        this._internal = props.internal;   
        if (this._internal)  //make this a support request
            this._alternate = true;         
        this._submitType = this._alternate ? "Request" : "Application";

        this.state.application = null;                                       //The Application object.  Null if not found or Application Object when fetched.
        this.state.fetchComplete = false;
        
        this.state.community = null; 
        this.state.submitting = false;              //true once submitted has been pressed
        this.state.submitted = false;               //true when submitted
        
        this.state.submitTried = false;
        this.state.paymentSum = 0.0;                //The calculated payment sum
        this.state.paymentError = null;             //Error on payment calculation 

        this.state.clientSecret = null;             //When payment required, the secret that is passed to stripe
        this.state.paymentRequired = false;

        this.state.emailInvalid = false;             //initially invalid until completed
        this.state.paperPaymentAmount = null;       // must be entered
        this.state.checkInfo = null;       // must be entered
    }
    
    
    
    /**
     * When the page loads, immediately fetch the full application
     */
    componentDidMount() {
        super.componentDidMount();       
        window.addEventListener("resize", this._updateSize);
        
        if (!this._group) {
            this.showConfirmAlert("No Community", "You did not arrive with a link from your Community", 'red');
        }
        else
            this._fetchCommunityInfo(); 
        
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.forceUpdate();
    }

    
    _fetchCommunityInfo = () => {
        
        this.incrementBusy();
        
        const queryString = "?group=" + this._group;
        
        this.secureJSONFetch("/patron/groupinfo", {},
                             this._fetchStatusCallback, this._fetchErrorCallback, queryString); 
    }
    
    _fetchStatusCallback = (response) => {

        if (response) {
            const community = new Community(response);
            this.setState({community: community});
            
            //If we are accepting (or this is a test or internal), go fetch the Application, leave busy and fetching not complete
            if (community.status() === Community.Status.ACCEPTING || this._test || this._internal || (this._alternate && community.supportReqAcceptedAlways)) {
                this._fetchApplication(); 
                return;
            }
            else {
                this.setState({application: null, fetchComplete: true});  //fetch done, no application
            }
            
        }
        this.decrementBusy();

    }
   
    
    _fetchApplication = () => {
                
        let queryString = "?group=" + this._group;
        if (this._test)
            queryString += "&test=" + this._test;

        if (this._alternate) {
            queryString += "&support=true";
            if (this._origApplicationID)
                queryString += "&origApplicationID=" + this._origApplicationID;
        }

        if (this._paper)
            queryString += "&paper=true";

        const url = this._internal ? "/community/application" : "/patron/application";

        this.secureJSONFetch(url, {}, this._fetchApplicationCallback, this._fetchErrorCallback, queryString); 
        
    }
   
    
    //Callback for fetching Application - response is an Application
    _fetchApplicationCallback = (response) => {
        if (response) {            
            const app = new Application(response, true);
            this.setState({application: app, fetchComplete: true});  //set to retrieved application
            this._fieldChanged();  //refresh

            if (this._email)  //arrived with url email, pre-populate
                this._submitEmailChanged(this._email);
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.decrementBusy();
        this.setState({submitting: false, fetchComplete: true});
    }
  
    
    _fieldInputError = (field, error) => {
        this.showConfirmAlert("Error in Field \"" + field + "\"", error, 'red');
        this.forceUpdate();
    }
    
    //Called when a field is updated or on first load of Application. Refresh all calculations
    _fieldChanged = (field, newValue) => {
        
        refreshApplicationCalculations(this.state.application, (error) => console.error(error));

        try {
            const paymentSum = computePaymentSum(this.state.application);
            if (Number.isNaN(paymentSum) || paymentSum < 0.0)
                throw new Error("Calculated payment amount invalid");

            this.setState({paymentSum: paymentSum, paymentError: null});
        } catch (error) {
            this.setState({paymentSum: null, paymentError: error.toString()});
        }

        this.forceUpdate();
    }
   
    _submitApplication = () => {
        
        if (this.state.application.isMissingData()) {
            this.showConfirmAlert("Missing Data", "One or more fields are required. If your browser auto-filled the form, you may get this message. Try clicking in the auto-filled fields.", 'red');
            this.setState({submitTried: true});
            return;
        }

        if (this.state.application.hasErroredField()) {
            this.showConfirmAlert("Invalid Data", "One or more fields have an error", 'red');
            this.setState({submitTried: true});
            return;
        }
        

        if (!this.state.application.submitterEmail || this.state.emailInvalid) {
            this.showConfirmAlert("Invalid Email", "Receipt email is missing or invalid.", 'red');
            this.setState({submitTried: true});
            return;
        }

        if (this.state.application.isTooLarge()) {
            this.showConfirmAlert(this._submitType + " too Large", "You may need to remove some large attachments", 'red', 'Cancel', this._doSubmit, 'Try Anyway');
            return;
        }
        
        if (this.paymentError) {
            this.showConfirmAlert("Payment Error", "There is a problem calculating the correct payment", 'red');
            return;
        }

        if (this._paper && this.state.paperPaymentAmount == null) {
            this.showConfirmAlert("Missing Payment Amount", "Enter the amount actually paid with the paper application", 'red');
            return;
        }

        this.state.application.calculatedPaymentAmount = this.state.paymentSum;
        this.state.application.paperPaymentAmount = this.state.paperPaymentAmount;
        this.state.application.checkInfo = this.state.checkInfo;
        console.log("Calculated Payment: ", this.state.application.calculatedPaymentAmount);
        this._doSubmit();
    }
    
    _doSubmit = () => {

        const url = this._internal ? "/community/application" : "/patron/application";

        this.incrementBusy();
        this.setState({submitting: true, submitTried: true});
        this.secureJSONFetch(url, 
                             {method: "POST", body: this.state.application.toJsonForPost()}, 
                             this._submitComplete,
                             this._submitErrorCallback);    
        
    }

    _submitErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
        this.setState({submitting: false});
    }
    
    _submitComplete = (response) => {
        if (response)  //response is a client secret if payment required, or null if not
            this.setState({clientSecret: response, paymentRequired: true});

        this._submitDate = new Date();
        this.setState({submitted: true, submitting: false});
        this.decrementBusy();
    }
  
  
    _submitEmailChanged = (userValue) => {

        if (userValue && !Email.validateEmail(userValue)) {
            this.showConfirmAlert("Error", "Receipt email \"" + userValue + "\" address not valid", 'red');
            this.setState({emailInvalid: true});
            return;
        }

        console.log("Submit email changing to " + userValue);
        this.state.application.submitterEmail = userValue;    
        this.setState({emailInvalid: false});
    }
  
    //Patron cancels the payment, returns to the Application
    _returnFromPayment = () => {
        this.setState({submitted: false, paymentRequired: false, clientSecret: null});
    }


    _patronReasonNotAccepting() {

        const status = this.state.community.status();
        switch (status) {
            case Community.Status.WAITING:      // for these cases, if the custom text is set, we use that
            case Community.Status.FINISHED:
                if (draftContentHasText(this.state.community.notAcceptingCustomText))       // if there is content 'text' then use it
                    return <RichTextDisplay draftContent={this.state.community.notAcceptingCustomText}/>;
                //fallthrough
            default:
                return <Typography variant="h5" align='center'>{status.patronReason}</Typography>;   // otherwise, use the status reason
        }
    }

    render() {

        const fetchText = this._alternate ? "Support Form" : "Application";
        let errMessage = "We're sorry, something went wrong retrieving your " + fetchText + ". Please try again later."

        if (this._internal)  //alternate error message
            errMessage = "Unable to retrieve internal support form. Try logging in first.";

        if (this._paper)
            errMessage = "Unable to retrieve paper Application form. Try logging in first.";
        
        //Fetching not finished or no community retrieved
        if (!this.state.fetchComplete || this.state.community === null) {
            
            return <ThemeProvider theme={AGTheme}>
                        <Fragment>
                            {this.getConfirmAlertComponent()}
                            {this.getBusyComponent('center', {marginTop: 60})}
                            {this.state.fetchComplete ?                      
                                <Typography variant="h4" align='center' style={{color: 'red', marginTop: 60}}>We're sorry, something went wrong. Please try again later.</Typography>
                                :
                                <Typography variant="h4" align='center' style={{color: ThemeColors.appBarBackground, fontWeight: 'bold', marginTop: 60}}>{"Getting your " + fetchText + "..."}</Typography>                            
                            }
                        </Fragment>
                    </ThemeProvider>;
        }
        
        //Submit complete, and no payment required - show success message
        if (this.state.submitted && !this.state.paymentRequired) {
            return <ThemeProvider theme={AGTheme}>
                        <div style={{textAlign: 'center', marginTop: 40}}>
                            <Typography variant="h4" style={{color: 'green', fontWeight: 'bold', marginBottom: 10}}>
                                <span style={{marginRight: 20}}>✓</span>{this._submitType + " Submitted"}
                            </Typography>  
                        </div>
                    </ThemeProvider>;
        }
           
        
    
        const ap = this.state.application;  //may or may not have an application, only if accepting applications
        const status = this.state.community.status();  //if fetch done, we have a community status

        const patronReasonNotAccepting = this._patronReasonNotAccepting();
 
        const renderParams = {forPatron: true, 
                             documentUploader: this.documentUploader,
                             documentDownloader: this.documentDownloader,
                             onChange: this._fieldChanged, 
                             onError: this._fieldInputError};
 
        const themeColor = this.state.community.primaryColor ? this.state.community.primaryColor : 'black';
        const backgroundColor = this.state.community.backgroundColor ? this.state.community.backgroundColor : 'white';

        const submitButtonText = (this.state.paymentSum > 0 && !this._paper) ? "Continue to Payment ➔" : ("✓ Submit " + this._submitType);

        let emailBorder = ((this.state.submitTried && !this.state.application.submitterEmail) || this.state.emailInvalid) ? {border: '1px solid red', padding: 3, paddingTop: 8} : {border: 'none', padding: 0};

        //If we are accepting applications (or this is test) and we have an Application, show it.  Otherwise show the reason why the Community is not accepting applications

        const title = this.state.community.extendedName + (this._internal ? " Community Support Request" : (this._alternate ? " Patron Support Request" : " Application"));
        const responseType = this._alternate ? "responses" : "status on your Application";
        return (
    
            <ThemeProvider theme={AGTheme}>
                <div style={{backgroundColor: backgroundColor, paddingBottom: 20}}>                    
                    <Container disableGutters={true} style={{padding: 10}}>
                                    
                        <Typography variant="h4" align='center' style={{color: themeColor, marginBottom: 20}}>{title}</Typography> 

                        {(status === Community.Status.ACCEPTING || this._test || (this._alternate && this.state.community.supportReqAcceptedAlways)) ? 
                            
                            (ap ?

                                <div>
                                
                                    {this.getConfirmAlertComponent()}                    
                                    {ap.testing ? <Typography variant="h5" align='right' style={{marginBottom: 20, textAlign: 'center', fontWeight: 'bold', color: 'white', backgroundColor: 'red'}}>TEST MODE</Typography> : null}       

                                    <div style={{display: this.state.submitted ? 'none' : 'block'}}>
                                        <Paper elevation={3} style={{padding: 5, paddingTop: 10, paddingRight: 10}}> 

                                            <Typography align='right' variant="body2" style={{fontStyle: 'italic'}} >* Required Field</Typography>  

                                            <Typography variant="body1" style={{fontStyle: 'italic', marginLeft: 10}} >{"We will send " + responseType + " to the email address below"}</Typography>  
                                            <div style={{...emailBorder, marginLeft: 10, marginTop: 10}}>
                                                <GenericTextField label="Your Email Address *"
                                                                  initialValue={this._email}
                                                                  onFieldChange={this._submitEmailChanged}/>
                                            </div>

                                            {renderField(ap.fieldTree, 0, renderParams, this.state.submitTried)}

                                        </Paper>

                                        {this._paper ? 
                                            <Fragment>
                                                <Typography align='center' variant="body1" style={{marginTop: 40}} >{"Required Payment: " + Currency.round(ap.calculatedPaymentAmount) + " " + ap.currency}</Typography>  

                                                <ManageDecimalField style={{margin: 'auto', marginTop: 30, maxWidth: 300}}
                                                                    json="ActualPayment" 
                                                                    label={"Actual Payment Amount (USD only)"}
                                                                    autoAccept={true}
                                                                    hasNever={false}
                                                                    fontSize={18}
                                                                    hasNegative={false}
                                                                    hideButtons={true}
                                                                    decimalPlaces={2}
                                                                    onFieldChange={(fieldName, userValue) => {
                                                                        this.setState({paperPaymentAmount: userValue});
                                                                    }}/>
                                                <GenericTextField style={{margin: 'auto', marginTop: 30, maxWidth: 800}}
                                                                  label="Check Information"
                                                                  onFieldChange={(value) => this.setState({checkInfo: value})}/>
                                            </Fragment>
                                            : null
                                        }

                                        <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 30}}>
                                            <Button disabled={this.state.submitting} variant='contained' color='primary' onClick={this._submitApplication}>{submitButtonText}</Button>
                                        </div>
                                    </div>

                                    {this.state.paymentRequired ? 

                                        <PatronCheckout returnToApplication={this._returnFromPayment} 
                                                        paymentDescription={"Amount to Charge: " + Currency.round(ap.calculatedPaymentAmount) + " " + ap.currency}
                                                        purchaseComplete={() => this.setState({paymentRequired: false}) /* Payment complete, will trigger success message*/}
                                                        clientSecret={this.state.clientSecret}
                                                        submitDate={this._submitDate}
                                                        email={ap.submitterEmail}/>
                                        : null
                                    }


                                    {this.state.isBusy ? this.getBusyComponent('center', {marginTop: 20}) : <div style={{height: 50}}/>}
                                    
                                </div>
                                :
                                <Typography variant="h5" align='center' style={{color: 'red'}}>{errMessage}</Typography> 

                            )               
                            : 
                            <div>
                                {this._alternate ?   
                                    <Typography variant="h5" align='center'>We're sorry, but we are not currently accepting support requests. Please contact your community directly.</Typography> 
                                    : 
                                    <Fragment>{patronReasonNotAccepting}</Fragment>
                                }
                            </div>
                        }
                            
                    </Container>
                    
                </div>
            </ThemeProvider>
        );
    }
  
}

export default withCookies(withRouter(Patron));



